@import "../../styles/helpers";

.pageTitle {
    margin-bottom: 24px; }

.outer {
    max-width: 845px;
    height: 100%;
    overflow-y: scroll; }

.actionCard {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
    .addNew {
        @include s {
            width: 100%; } } }

.carouselWrap {
    margin-bottom: 16px;
    padding: 8px;
    background-color: #F4F4F4;

    padding-right: 30px;
    @include dark {
        background-color: $n6; } }

.info {
    opacity: 1;
    @include d {
        opacity: 0; } }

.mblNav {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    @include t {
        display: flex; } }

.nav {
    display: flex;
    gap: 12px;
    padding: 8px;
    background: $n2;

    margin-bottom: 24px;
    &.chartShow {
        margin-bottom: 32px; }
    @include t {
        display: none; }
    @include dark {
        background: $shades4; } }

.item {
    margin-bottom: 4px;
    border: 2px solid #6F767E;
    border-radius: 0 !important;
    padding: 12px !important;
    &.active {
        // background: $pOffWhite
        // box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        //+dark
        //    background: $n7
 }        //  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
    &.activeItem0 {
        background: $pOrange;
        color: #FFFDF9 !important; }
    &.activeItem1 {
        background: $pLightGrey; }
    &.activeItem2 {
        background: $pApricot; }
    &.activeItem3 {
        background: $pPink; }
    &.activeItem4 {
        background: $pPink; }
    &.activeItem5 {
        background: $pPink; }
    &.activeItem6 {
        background: $pPink; }
    &.activeItem7 {
        background: $pPink; }
    &.activeItem8 {
        background: $pPink; } }

.spinner {
    position: absolute;
    top: 50%;
    left: calc(50% + 140px);
    @include d {
        left: calc(50% + 40px); }
    @include t {
        left: calc(50% - 15px); } }


