@import "../../../../styles/helpers";

.spinner {
   display: flex;
   width: 100%;
   justify-content: center; }

.infoWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #F4F4F4;
    width: max-content;
    @include dark {
        background: $n6; }
    .pic {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-right: 16px; }

    .infoWrap {
        .info {
            display: table-row;
            align-items: center;
            @include base1-s;
            .label {
                color: $pGrey;
                padding-right: 12px;
                display: table-cell;
                @include dark {
                    color: $shades1; } }
            .txt {
                display: table-cell;
                color: $n7;
                @include dark {
                    color: $pDarkGrey; } } } } }

.table {
    display: table;
    width: 100%;
    margin-top: 24px;
    @include t {
        display: block; }
    .head,
    .row {
        position: relative;
        display: table-row;
        height: 48px;
        @include t {
            display: block;
            margin-bottom: 16px; }
        .col {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            padding: 8px 12px {
                width: 48px; }
            @include t {
                display: table-row;
                padding: 4px 8px; }
            .mblLabel {
                display: none;
                font-size: 14px;
                @include t {
                    display: table-cell;
                    color: $pDarkGrey;
                    padding: 0 8px; } }
            span {
                font-size: 14px;
                @include t {
                    display: table-cell;
                    padding: 0 8px; } } } }



    .head {
        color: $pDarkGrey;
        font-size: 14px;
        @include t {
            display: none; } }
    .row {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; }
        &:hover {
            background: $n3;
            cursor: pointer;
            @include dark {
                background: $n6; } } } }

.linkWrap {
    display: flex;
    justify-content: space-between;
    fill: $n6;
    @include dark {
        fill: $pDarkGrey; } }

.deleteBtn {
    fill: $pOrange; }

.active {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }

.paid {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }

.void {
    padding: 0 8px;
    @include base1-s;
    background: rgba(235,61,52,.28);
    color: $pOrange; }


.open {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }


.draft {
    background: $n3;
    padding: 0 8px;
    @include base1-s;
    color: $pDarkGrey; }


.success {
    background: #49000036;

    padding: 4px 8px;
    color: $pBurgundy; }

.danger {
    background: #49000036;
    padding: 4px 8px;
    color: $pBurgundy !important; }
