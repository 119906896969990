@import "../../../../../../styles/helpers";

.boxContainer {
    width: max-content;
    min-width: 147px;
    height: 48px;
    background: #FFFFFF;
    border: 2px solid #EFEFEF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.label {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-left: 16px; }
