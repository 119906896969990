@import "../../../../styles/helpers";

.actionCard {
    margin-top: 24px;
    display: flex;
    align-items: center;
    padding: 0;
    gap: 10px;
    padding-top: 20px;
    border-top: .1px solid rgba(17, 19, 21, 0.08) !important;
    @include s {
        .button {
            padding: 12px;
            font-size: 14px; } }
    @include xs {
        gap: 4px;
        .button {
            padding: 10px; } } }
