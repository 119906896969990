@import "../../../../styles/helpers";

.card {
    padding: 0; }

.singleEmbeddedFormWrap {
    padding: 12px;
    &:not(:first-child) {
        border-top: .5px solid $shades3; } }

.singleEmbeddedForm {
    display: table-row;
    color: $pDarkGrey;
    p {
        display: table-cell;
        padding: 12px;
        height: 51px; }
    button {
        display: table-cell;
        padding: 12px;
        height: 51px; }
    &:hover {
        p,
        button {
            background: $n2;
            @include dark {
                background: $n6; } } } }

.right {
    background: $n;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    fill: $pDarkGrey;
    @include dark {
        background: $n6; } }

.dotsBtn {
    position: relative;
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; } }

    @include dark {
        fill: $n; } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 96px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 20px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }


.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &.deleteBtn {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        &:hover,
        &.active {
            color: $pOrange;
            svg {
                fill: $pOrange; }
            @include dark {
                color: $pOrange;
                svg {
                    fill: $pOrange; } } } }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }
.deleteItem {
    color: $pOrange !important;
    svg {
        fill: $pOrange !important; } }

.title {
    @include base1-s;
    color: $n6;
    border-bottom: 1px solid $shades3;
    margin-bottom: 16px;
    padding-bottom: 4px;
    @include dark {
        color: $n3;
        border-bottom: 1px solid $pDarkGrey; } }

.dragWrap {
    position: relative;
    background: $pOffWhite;
    height: max-content;
    cursor: move;
    @include dark {
        background: $n7; } }

.viewWrap {
    background: $n3;
    padding: 12px;
    @include caption1-m;

    color: $pDarkGrey; }
