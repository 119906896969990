@import "../../../../../styles/helpers";

.card {
    margin-top: 24px; }

.item {
    margin-bottom: 4px;
    border: 2px solid #6F767E;
    border-radius: 0 !important;
    padding: 12px !important;
    &.active {
        // background: $pOffWhite
        // box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        //+dark
        //    background: $n7
 }        //    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
    &.activeItem0 {
        background: $pOrange;
        color: #FFFDF9 !important; }
    &.activeItem1 {
        background: $pLightGrey; }
    &.activeItem2 {
        background: $pApricot; }
    &.activeItem3 {
        background: $pPink; }
    &.activeItem4 {
        background: $pPink; }
    &.activeItem5 {
        background: $pPink; }
    &.activeItem6 {
        background: $pPink; }
    &.activeItem7 {
        background: $pPink; }
    &.activeItem8 {
        background: $pPink; } }

