@import "../../../../styles/helpers";

.pipelineCard {
    padding: 0px;
    min-height: 260px; }

.spinnerOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7; }

.modalOuter {
    min-width: 800px; }

.cardHeader {
    padding-top: 0px; }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 12px;
    @include dark {
        color: $pDarkGrey; } }

.cardContainer {
    height: 120px;
    width: 300px;
    background-color: $pLightGrey;
    padding: 24px;
    padding-top: 12px;
    margin-bottom: 20px; }

.cardColumnContainer {
    display: flex;
    flex-direction: column; }

.dropdown {
    background-color: #F4F4F4;
    border: 2px solid #C7C5BF;
    height: 48px; }

.dropdownHead {
    box-shadow: unset;
    &:hover {
        box-shadow: unset; } }

.cardRow {
    display: flex;
    flex-direction: row; }

.title {
    color: #C7C5BF;
    width: 80px; }

.field {
        width: 212px;
        @include m {
            width: 100%;
            margin: 0; } }

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 260px; }

.value {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include dark {
        color: $pDarkGrey; } }

.valueHyperlink {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
    @include dark {
        color: $pDarkGrey; } }


.headerRow {
    display: flex;
    justify-content: space-between; }

.headerTitle {
    font-size: 20px;
    font-weight: 400; }

.headerValue {
    font-size: 20px;
    font-weight: 400;
    color: #FF4900; }
