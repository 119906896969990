@import "../../../../../styles/helpers";

.card {
    margin-top: 24px; }

.coverImage {
    width: 100%;
    height: 200px; }

.profileImage {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    position: absolute;
    left: 20px;
    top: 50px; }

.imageWrapper {
    position: relative;
    height: 200px; }

.description {
    margin-top: 24px;
    color: #464542; }

.flex {
    display: flex;
    flex-direction: column; }
