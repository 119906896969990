@import '../../../../styles/helpers.sass';

.item {
    display: flex;
    padding: 20px;
    width: 200px;
    height: 100px;
    //word-break: break-all
    //flex: 0 0 calc(20% - 10.8px)
    //justify-content: center

    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: all .2s;
    @include w {
        padding: 20px 16px; }
    @include m {
        padding: 16px 10px; }
    img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        object-position: center;
        margin-right: 12px; } }

.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
    @include m {
        display: none; } }

.subtitle {
    font-size: 20px !important;
    font-family: 'TomatoGrotesk-Light', sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    letter-spacing: -0.96px !important;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include title1-s;
    @include dark {
        color: $n3; } }

.activeCard {
    .details {
        .subtitle,
        .subtitleX,
        .counter {
            @include dark {
                color: $pDarkGrey; } } } }

.tooltip {
    svg {
        fill: $shades1; } }

.counter {
    font-family: 'TomatoGrotesk-Light', sans-serif !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 48px !important;
    letter-spacing: -1.44px !important;
    line-height: 1;
    letter-spacing: -.03em;
    @include w {
        font-size: 28px;
        line-height: 48px; }
    @include dark {
        color: $n3; } }

.balance {
    margin: -12px -12px 0 auto;
    @include m {
        margin: 4px 0 0; }
    @include dark {
        background: $n6 !important; } }
