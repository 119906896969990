@import "../../../../styles/helpers";

.field {
    margin-top: 20px; }

.dropdownCOA {
    margin-bottom: 16px; }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 7px;
    @include dark {
        color: $n3; } }

.search {
    max-width: 100%;
    margin-bottom: 17px; }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 12px; }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include dark {
        fill: $pDarkGrey; }
    @include m {
        height: 80px;
        width: 80px; } }

.dateRangeInput {
    color: $n7; }

