@import "../../../styles/helpers";

.typeNav {
    display: flex;
    align-items: center;
    width: max-content;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

