@import "../../../../styles/helpers";

.input {
    width: 100%;
    padding: 0 10px;
    border: 2px solid #C7C5BF;
    background: #F4F4F4;
    font-family: 'tomatoGrotesk', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #464542;
    transition: all .2s; }

.contentContainer {
    display: flex;
    flex-direction: column;
    margin-top: -24px; }

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 12px;
    margin-bottom: 24px;
    gap: 16px; }


.fieldName {
    font-family: 'TomatoGrotesk-Light', sans-serif;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    color: #C7C5BF; }

.titleContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 8px; }

.userImge {
    width: 48px;
    height: 48px;
    border: 2px solid $pGrey;
    border-radius: 50%;
    object-fit: cover; }

.userAvatar {
    width: 48px;
    height: 48px;
    border: 2px solid $pGrey;
    background: $pLightGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 50%;
    object-fit: cover; }

.wholeCommentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }

.nameContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 140px); }

.nameTimeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px; }

.editButton {
    cursor: pointer; }

.actionButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
    width: 72px; }

.name {
    font-weight: 600;
    font-size: 16px; }

.time {
    color: $pGrey; }

.dropzonewrapCommnetWrapper {
    span {
        text-align: center; } }
