@import "../../../../styles/helpers";

.card {
    padding: 0; }

.newChecklistBtn {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px; }
