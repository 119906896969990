@import "../../../styles/helpers";

.filteredDropdownContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 24px; }

.dropdown {
    width: 156px;
    background-color: #E9E7E0;
    border: 2px solid #C7C5BF;
    .head {
        box-shadow: unset; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.spinner {
    margin-left: -5%;
    position: absolute;
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center; }

.row {
    display: table-row;
    @include t {
        display: none; } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px 8px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey; }

.spinner {
    display: flex;
    width: 100%;
    justify-content: center; }

.noData {
    margin-top: 48px;
    margin-left: -5%;
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #C7C5BF;
    display: flex;
    width: 100%;
    justify-content: center; }

.foot {
    margin-top: 48px;
    text-align: end;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
