[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 20px;
  background: $pOrange;
  border-radius: 3px;
  font-size: 15px;
  line-height: (24/15);
  text-align: center;
  color: $pOffWhite;
  transition: all .2s;
  cursor: pointer;
  &:hover {
    background: #ff4900db; }
  &.disabled {
    opacity: .5;
    pointer-events: none; }
  svg {
    fill: $pOffWhite;
    transition: all .2s; }
  &:not([class^="button-square"]) {
    svg {
      &:first-child {
        margin-right: 8px; }
      &:last-child {
        margin-left: 8px; } } } }

.button-stroke,
.button-stroke-red {
  background: none;
  border: 2px solid $pOrange;
  @include dark-common {
    border: 2px solid $pOrange; } }

.button-stroke {
  color: $pDarkGrey;
  border: 2px solid $pDarkGrey;
  svg {
    fill: $pDarkGrey; }
  @include dark-common {
    color: $pOffWhite; }
  &:hover {
    background: none;
    border: 2px solid #464542db;
    color: #464542db;
    svg {
      fill: #464542db; }
    @include dark-common {
      box-shadow: 0 0 0 2px $n3 inset;
      svg {
        fill: $pOffWhite; } } }
  &.active {
    background: $n6;
    box-shadow: 0 0 0 2px $n6 inset;
    svg {
      fill: $n7; } } }

.button-stroke-red {
  color: $pOrange;
  svg {
    fill: $pOrange; }
  &:hover,
  &.active {
    background: $pOrange;
    box-shadow: 0 0 0 2px $pOrange inset;
    color: $pOffWhite;
    svg {
      fill: $pOffWhite; } } }

.button-stroke-lightBlue {
  color: $pDarkGrey;
  background: $pLightGrey;
  box-shadow: 0 0 0 2px $pLightGrey inset;
  svg {
    fill: $pDarkGrey; }
  &:hover,
  &.active {
    background: $pDarkGrey;
    box-shadow: 0 0 0 2px $pDarkGrey inset;
    color: $pOffWhite;
    svg {
      fill: $pOffWhite; } } }

.button-icon-rotate {
  svg {
    transform: rotate(90deg); } }

.button-white {
  box-shadow: 0 0 0 2px $n3 inset;
  background: $pOffWhite;
  color: $n7;
  svg {
    fill: $n7; }
  &:hover,
  &.active {
    box-shadow: 0 0 0 2px $n7 inset;
    background: $n;
    color: $n7;
    svg {
      fill: $n7; } } }

.button-small {
  height: 40px;
  padding: 0 16px;
  font-size: 13px; }

.button-square-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  background: none;
  box-shadow: 0 0 0 2px $n3 inset;
  transition: all .2s;
  svg {
      fill: $pDarkGrey; }
  @include dark-common {
    box-shadow: 0 0 0 2px $n6 inset; }
  &:hover {
      background: $pOrange;
      box-shadow: 0 0 0 2px $pOrange inset;
      svg {
          fill: $pOffWhite; } } }

.button-square-stroke.button-small {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; }



