@import "../../../styles/helpers";

.setupWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; }

.dragBtn {
    cursor: move; }

.setupCard {
    border: 3px solid $pBurgundy;
    padding: 12px;
    max-width: 186px;
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: $pDarkGrey;
        margin-bottom: 20px;
        h3 {
            @include base1-s; } }

    p {
        @include caption2-m;
        color: #1A1D1F;
        height: 114px; }

    .deactivateBtn {
        svg {
            transform: rotate(180deg); } }
    @include dark {
        p {
            color: $pDarkGrey; } } }
