@import "../../../../styles/helpers";

.item {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer; }

.link {
    display: flex;
    align-items: center;
    flex-grow: 1;
    &:hover {
        .title {
            color: $pBurgundy;
            @include dark {
                color: $pOffWhite; } } } }
.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.preview {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;

    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.content {
    margin-bottom: 4px;
    @include caption1;
    color: $pDarkGrey;
    @include dark {
     color: $pGrey; } }

.title {
    color: $n7;
    transition: color .2s;
    @include dark {
        color: $pGrey;
        &:hover {
            color: $pGrey; } } }

.close {
    flex-shrink: 0;
    margin-left: 24px;
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $pOrange; } } }
