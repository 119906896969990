@import "../../../styles/helpers";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 64px;
    @include d {
        margin-left: 48px; }
    @include t {
        margin-left: 0px; }
    @include t {
        padding-left: 24px;
        padding-right: 24px; } }

.dropdown {
    margin-top: 24px;
    width: 280px; }

.heading {
    color: var(--primary-dark-grey, #464542);
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px; /* 100% */;
    letter-spacing: -1.44px; }

.subHeading {
    word-wrap: break-word;
    color: var(--primary-grey, #C7C5BF);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px;
    margin-top: 16px;
    width: 447px;
    @include t {
        width: auto; } }

.buttonContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column; }

.googleButton {
    width: 245px;
    height: 44px;
    background: url('~/public/images/google.svg'), lightgray 50% / cover no-repeat; }

.skip {
    margin-top: 16px;
    width: 110px; }

.buttonContainerForBank {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px; }
