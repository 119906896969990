@import "../../../../styles/helpers";

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.fullWidth {
    width: 100%; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }


.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .col {
        width: 350px;
        flex: none;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } }
    .col2 {
        flex: 0 0 calc(66.66% - 16px);
        width: calc(66.66% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } } }

.card {
    padding: 12px; }

.actionCard {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
    .cancelBtn {
        @include s {
            width: 100%; } }
    .sendBtn {
        @include s {
            width: 100%; } } }
