@import "../../../styles/helpers";

.card {
    padding: 12px; }

.linkedAccountWrap {
    .singleAccount {
        display: flex;
        gap: 16px;
        .card {
            height: 50px;
            width: 50px;
            border-radius: 50%; }
        .gray {
            background: $n6; }
        .purple {
            background: $p4; }
        .info {
            .name {
                @include base1-s;
                color: $pDarkGrey; }
            .amount {
                font-size: 24px;
                font-weight: 600;
                color: $n7;
                margin-top: 4px;
                @include dark {
                    color: $shades1; } } }
        &:not(:last-child) {
            margin-bottom: 20px; } } }
