@import "../../../../styles/helpers";

.card {
    width:  350px;
    max-width: 350px;
    @include t {
        width: 100%; } }

.deleteModal {
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.head {
    width: 100%;
    background: inherit;
    @include s {
        padding: 12px;
        font-size: 14px; }
    @include xs {
        padding: 10px; } }

.body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 600px;
    padding: 24px;
    background: $pOffWhite;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transform: translate(0, 0);
        width: auto;
        overflow: auto;
        .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; } }
    @include dark {
        background: $n7; } }

.top {
    position: absolute;
    right: 20px; }

.close {
    flex-shrink: 0;
    width: 36px !important;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $pOffWhite; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 18;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include t {
                margin-bottom: 24px; } } } }

.row {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }


.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #33383F; }

.name {
    width: 75px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;

    /* Neutral/04 */

    color: #6F767E; }

.value {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin-left: 30px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;

    /* Neutral/05 */

    color: #33383F;
    @include m {
        margin-left: 0px; } }

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    @include m {
        flex-direction: column; } }

.link {
    width: 196px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin-left: 30px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;

    /* Neutral/05 */

    color: #33383F;
    @include m {
        margin-left: 0px; } }

.scanPayText {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin-left: 30px;
    left: 45px;
    letter-spacing: -0.02em;
    color: #33383F;
    @include m {
        margin-left: 0px;
        left: 70px; } }

.titleContainer {
    width: 400px;
    @include s {
        width: 250px; } }
