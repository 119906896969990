@import "../../../../styles/helpers";

.card {
    padding: 0; }

.modalOuter {
    max-width: 520px;
    height: 70vh;
    overflow-y: hidden;
    padding-bottom: 48px; }


.top {
    button {
        position: absolute;
        left: 0; } }

.bottom {
    margin-top: 72px;
    height: 30vh;
    overflow: auto;
    @include hx {
        height: 40vh; } }

.input {
    margin-bottom: 12px; }

.codeWrap {
    margin-top: 76px; }

.embeddedForm {
    li[type = "button"] {
        display: block;
        text-align: left;
        button {
            background: $pBurgundy;
            color: $pOffWhite; } } }
