@import "../../../../styles/helpers";

.card {
    padding: 0;
    margin-top: 10px;
    @include t {
        margin-top: 40px; }
    @include m {
        margin-top: 30px; } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        max-width: 333px;
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } }
        @include t {
            margin-bottom: 8px; } } }

.priceGroup {
    display: flex;
    align-items: flex-end;
    gap: 8px; }

.userChooseDrop {
    margin-top: 12px; }

.includeTax {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    .txt {
        @include base1-s;
        color: $pDarkGrey; } }



.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    span {
        @include dark {
            color: $n3; } }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px; } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 12px; }

.field {
    width: 70%; }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m {
        height: 80px;
        width: 80px; } }

.inputLabelImg {
    display: none; }



