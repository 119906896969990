@import "../../../../styles/helpers";

.card {
    min-height: 300px; }
.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.pageTitle {
    margin-right: 24px;
    @include m {
        margin: 0; } }

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px; }
    .form {
        margin-right: auto;
        @include t {
            width: 250px; }
        @include m {
            width: 100%;
            margin: 0 0 16px; } }
    &.hidden {
        @include t {
            display: none; } } }

.filterBtn,
.downloadBtn {
    margin-right: 8px;
    fill: $pDarkGrey;
    svg {
        fill: $pDarkGrey; }

    @include t {
        margin-top: 36px; } }

.addCustomerBtn {
    margin-left: auto;
    @include s {
        padding: 8px; }
    @include xs {
        padding: 4px;
        font-size: 14px; } }

.deleteMarkedBtn {
    margin-left: auto; }



.nav {
    display: flex;
    @include m {
        width: 100%; } }

.link {
    padding: 8px 16px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    @include m {
        flex: 1; }
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $pOffWhite; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px;
        @include m {
            margin-right: 0; } } }

.filters {
    @include t {
        position: absolute !important;
        margin-top: 92px;
        margin-left: 60px; }
    @include m {
        margin-top: 75px; } }

.downloadBtn {
    @include t {
        position: absolute !important;
        margin-top: 127px;
        margin-left: 104px; }
    @include m {
        margin-top: 113px; } }

.details {
    display: none; }

.row {
    &.flex {
        display: flex;
        .table {
            flex-shrink: 0;
            width: 384px;
            @include w {
                width: 350px; }
            @include d {
                width: 320px; }
            @include t {
                display: none; } }
        .details {
            display: block; } } }


.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }
