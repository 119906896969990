@import "../../../../../styles/helpers";

.card {
    padding: 0;
    margin-top: 24px; }

.tooltip {
    position: relative;
    top: -1px;
    margin-left: 16px;
    svg {
        fill: $shades1; } }

.row {
    display: flex;
    .col {
        &:first-child {
            flex: 0 0 calc(20% - 16px);
            width: calc(20% - 16px);
            margin: 0 8px 8px;
            @include base1-b; }
        &:nth-child(2) {
            flex: 0 0 calc(80% - 16px);
            width: calc(80% - 16px);
            margin: 0 8px 8px;
            @include base1-s; } }
    &.rowHead {
        border-bottom: .1px solid rgba(17, 19, 21, 0.08) !important;
        margin-bottom: 16px;
        .col {
            @include base1-s;
            color: $pDarkGrey; }
        @include dark {
            border-color: $n6; } } }
