@import "../../../../../styles/helpers";

.card {
    width: 50%;
    padding: 12px 16px;
    @include t {
        width: 100%; } }

.wrap {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .invName {
        text-transform: uppercase;
        @include caption1-m; }
    .invAmount {
        @include caption1-m;
        font-weight: 700; }
    .invStatus {
        @include caption1-m; } }
