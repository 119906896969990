@import "../../../../../../styles/helpers";

.card {
    border: none !important;
    box-shadow: none !important;
    padding: 24px 0 0 0; }

.outer {
    height: 100%;
    overflow-y: scroll;
    max-width: 900px; }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.includeTax {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    .txt {
        @include base1-s;
        color: $pDarkGrey; } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px; } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.userChooseDrop {
    margin-top: 12px; }

.priceWrap {
    display: flex;
    gap: 12px;
    grid-gap: 12px; }

.totalPrice {
    width: 7ch; }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 12px;
    height: 100px;
    @include m {
        height: 80px; } }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m {
        height: 80px;
        width: 80px; } }

.inputLabelImg {
    display: none; }


.infoCard {
    padding: 0; }

.billingCard {
    padding: 0;
    padding-top: 24px; }
