@import "../../../../../../styles/helpers";

.outer {
    max-width: 343px; }

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px; }

.imgWrap {
    width: 128px;
    height: 128px;
    background: $pBurgundy;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px; } }

.title {
    @include title1-s;
    color: $n5;
    @include dark {
        color: $pDarkGrey; } }

.invName {
    font-size: 34px;
    font-weight: 600;
    color: $n7;
    line-height: 48px;
    @include dark {
        color: $n3; } }

.email {
    @include base1-s;
    color: $pDarkGrey;
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }

.emailText {
    @include base1-s;
    color: $pGrey;
    text-align: center;
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }
