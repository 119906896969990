@import "../../../styles/helpers";

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        width: 100%;
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.title {
    display: flex;
    align-items: center;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $shades1;
        fill: $shades1; } }

.actionCard {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
    .saveBtn {
        @include s {
            width: 100%; } } }
