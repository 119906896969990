@import "../../../../styles/helpers";

.dropdown {
    width: 150px; }
.card {
    padding: 0; }

.cardHead {
    margin-bottom: 24px; }

.input {
    width: calc(100% - 10px) !important;
    cursor: pointer;
    color: $pDarkGrey !important; }

.customerBtn {
    @include base1-s;
    color: $n6;
    width: 100%;
    height: 48px;
    padding: 0 8px;
    text-align: start;
    &:hover {
        background: $n2; }
    @include dark {
        color: $pDarkGrey;
        &:hover {
            background: $n6; } } }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        max-width: 333px;
        margin: 0 8px;
        margin-top: 24px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 8px; } }
        @include t {
            margin-bottom: 8px; } } }

.includeTax {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    .txt {
        @include base1-s;
        color: $pDarkGrey; } }


.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px;
        @include dark {
            background: $n6; } } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 12px; }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }


.successMessage {
    color:#008000 {} }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 108px;
        height: 108px;
        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.btnContainer {
    height: 80px; }

.inputLabel {
    border: .5px dashed $pDarkGrey;
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include dark {
        fill: $shades1; } }

.inputLabelImg {
    display: none; }

.description {
    width: 100%;
    padding: 0 10px;
    border: 2px solid transparent;
    background: #F4F4F4;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #1A1D1F;
    transition: all .2s; }


