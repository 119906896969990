@import "../../../styles/helpers";

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; }
    span {
        color: red; } }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.actionCard {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-top: 1px solid $shades3;
    padding-top: 20px;
    @include s {
        .button {
            padding: 12px;
            font-size: 14px; } }
    @include xs {
        gap: 4px;
        .button {
            padding: 10px; } } }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }
