@import "../../styles/helpers";


.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    @include t {
        display: block;
        margin-left: 0;
        margin-right: 0; }
    .col {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }

.mapContainer {
    position: relative; }

.installationWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-right: 28px;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 7;
    background-color: #FFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 300px;
    height: 180px; }

.installTitle {
    color: $pDarkGrey;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px; }

.line {
    background-color: #C7C5BF;
    height: 1px;
    width: 100%; }

.installDetailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 12px; }

.innerDetailWrapper {
    display: flex;
    gap: 8px; }

.installSubTitle {
    font-size: 14px;
    color: $pDarkGrey; }

.progressWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 212px;
    left: 16px;
    z-index: 7;
    background-color: #FFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 300px;
    height: 180px; }

.singleItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -4px; }

.verticleLine {
    background-color: #C7C5BF;
    width: 1px;
    height: 140px;
    transform: rotate(180deg);
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 24px; }

.blue {
    color: #3788d8; }

.green {
    color: #17B169; }

.circularBar {
    width: 60px;
    height: 60px;
    transform: rotate(235deg);
    position: relative; }

.progressIcon {
    position: absolute;
    left: 14px;
    top: 11px;
    transform: rotate(-235deg); }

.panelWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    background-color: #FFF;
    padding-left: 16px;
    padding-right: 16px;
    height: 36px;
    border-radius: 24px;
    width: 384px;
    position: absolute;
    bottom: 36px;
    left: calc(50% - 180px);
    z-index: 10; }

.panel {
    margin-top: -11px;
    width: 204px; }
