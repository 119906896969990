@import "../../../../styles/helpers";

.row {
    display: flex; }

.col {
    position: relative;
    flex: 0 0 33.33%;
    max-width: 340px;
    @include t {
        flex: 0 0 50%; }
    @include m {
        left: 50%;
        transform: translateX(-50%); }
    @include a {
        flex: 0 0 100%; } }

.noChecklist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

.pic {
    width: 228px;
    height: 228px; }

.txtWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 12px;
    gap: 12px;
    h3 {
        @include title1-s;
        color: $n5;
        @include dark {
            color: $pDarkGrey; } }
    p {
        @include caption2-m;
        color: $pDarkGrey; }
    .button {
        color: $n; } }
