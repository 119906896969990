@import "../../../../styles/helpers";

.detailsWrap {
    position: relative; }

.button > svg {
    fill: $pOrange !important; }

.title {
    display: flex;
    align-items: center;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.actionCard {
    width: 100%;
    display: flex;
    justifyContent: space-around;
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
    .sendBtn {
        @include s {
            width: 100%; } } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.card {
    padding: 12px;
    margin-bottom: 20px; }

.paymentTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;

    letter-spacing: -0.01em;

    color: #6F767E;
    flex: none;
    order: 0;
    flex-grow: 0; }

.paymentSubtitle {
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #6F767E;
    margin-left: 5px; }


.name {
    @include title1-s;
    color: $n7;
    @include dark {
        color: $n2; } }

.email {
    @include base1-s;
    color: $n7;
    margin-bottom: 16px;
    @include dark {
        color: $n2; } }

.customerHead {
    position: relative; }

.dotsBtnWrap {
    position: absolute;
    top: 6px;
    right: 0px;
    fill: $pDarkGrey; }

.deleteBtn {
    fill: $n5;
    margin-left: 8px;
    padding: 8px;
    height: 36px;
    width: 36px;
    svg {
        transition: transform .2s; }
    @include dark {
        background: $n6;
        fill: $n3; } }

.optionsText {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1A1D1F; }

.search {
    z-index: 8; }

.wrap {
    margin-top: 16px; }

.label {
    @include base1-s;
    color: $pGrey;
    margin-bottom: 8px; }

.paymentTypeWrap {
    display: flex;
    align-items: center;
    gap: 12px; }

.achPayment,
.cardPayment {
    display: flex;
    gap: 12px;
    width: max-content;
    padding: 8px 12px 8px 8px;

    border: 2px solid $pDarkGrey;
    color: $pDarkGrey;
    &.active {
        background: $pBurgundy;
        color: $pOffWhite;
        border-color: transparent; } }

.settings {
    @include caption2-m;
    color: $n7;
    margin-top: 12px;
    @include dark {
        color: $pDarkGrey; }
    .link {
        @include caption2;
        color: $n7 !important;
        @include dark {
            color: $pDarkGrey !important;
            font-weight: 900; } } }

.productWrap {
    padding-bottom: 12px;
    .head,
    .row {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        .name {
            @include base1-s;
            color: $n7;
            @include dark {
                color: $n2; } } }
    .row {
        .left {
            display: flex;
            align-items: center;
            gap: 12px; }
        .title {
            @include base1-s;
            color: $pDarkGrey;
            margin-right: 8px; } }
    @include dark {
        border-color: $pDarkGrey; } }

.detailsTxt {
    @include base1-s;
    color: $pDarkGrey;
    margin-bottom: 12px; }

.addProductBtn {
    @include base1-s;
    color: $pBurgundy;
    margin-top: 8px; }


.dotsBtn {
    position: relative;
    @include t {
        button {
            background: transparent;
            padding: 0;
            &:focus {
                background: transparent; } } }
    @include m {
        position: static; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; }
        @include t {
            button {
                background: transparent; } } }

    @include dark {
        fill: $n; } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 99;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 48px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }

.qtyWrap,
.salesWrap {
    display: flex;
    align-items: center; }
