@import "../../styles/helpers";

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }
.transactions {
    width: 100%;
    margin: 0 8px 16px; }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        display: block; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .colWeb {
        width: calc(53.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.wrapper {
    margin-top: 20px; }

.downloadButton {
    @include s {
        position: unset !important; } }

.bottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #6F767E; }

.bookedDate {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #272B30; }

.status {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em; }


.singleInfo {
    margin-top: 16px;
    border-bottom: 1px solid #11131535;
    padding-bottom: 8px;
    &.noBorder {
        border: none; }
    @include dark {
        border-bottom: 1px solid $n6; }
    .overviewTitle {
        @include base1-s;
        color: $pDarkGrey; }
    .companyName {
        @include title1-s;
        word-break: break-all;
        color: $n7;
        @include dark {
            color: $shades1; } }
    .text {
        @include base1-s;
        color: $n6;
        @include dark {
            color: $shades1; } } }

.field {
    margin-top: 20px; }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; } }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 12px; }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        .__react_modal_image__modal_content {
            background: red; }
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include dark {
        fill: $pDarkGrey; }
    @include m {
        height: 80px;
        width: 80px; } }

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba($n2,.8);
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 16px; }
  @include dark {
    background: rgba($n6, .9); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: 100%;
  max-width: 408px;
  margin: auto;
  background: $pOffWhite;

  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @include dark {
    background: $n7; }
  @include m {
    box-shadow: none; }
  & > div {
    padding: 26px 24px 24px; } }

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $n3;
  font-size: 0;
  svg {
    fill: $n5;
    transition: transform .2s; }
  @include dark {
    background: $n6;
    svg {
      fill: $pOffWhite; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }

