@import "../../../../../styles/helpers";

.backBtn {
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 12px;
    svg {
        margin-right: 4px; }
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.wrap {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.row {
    display: flex;
    width: 100%;
    @include t {
        display: block; }
    .col {
        &:first-child {
            flex: 0 0 calc(33.33% - 8px); }
        &:last-child {
            flex: 0 0 calc(67.67% - 8px);
            margin-left: 16px;
            @include t {
                margin-left: 0;
                margin-top: 16px; } } } }

.title {
    @include base1-s;
    color: $pDarkGrey;
    margin-bottom: 12px; }

.templateDropdown {
    width: max-content; }

.templateLabel {
    margin-top: 16px;
    color: $pDarkGrey;
    @include dark {
        color: $pDarkGrey; } }

.sendContactBtn {
    background: $pBurgundy !important;
    margin-top: 16px;
    &:hover {
        background: $pBurgundy !important; } }

.sendContactAgainBtn {
    margin-top: 12px; }

.signedWrap {
    &:not(:last-child) {
        margin-bottom: 24px; } }

.signedBtn {
    background: $pBurgundy !important;
    &:hover {
        background: $pBurgundy !important; } }

.singleData {
    margin-top: 12px;
    border-bottom: 1px solid $shades3;
    padding-bottom: 4px;
    .dataTitle {
        color: $pDarkGrey;
        @include base1-s; }
    p {
        color: $n7;
        @include base1-s;
        @include dark {
            color: $n3; } } }

.linkWrap {
    display: flex;
    justify-content: space-between;
    @include dark {
        fill: $n3; } }


.success {
    background: #49000036;

    padding: 4px 8px;
    color: $pBurgundy !important;
    width: max-content; }

.danger {
    background: #49000036;
    padding: 4px 8px;
    width: max-content;
    color: $pBurgundy !important; }

.questionerWrap {
    ul {
        width: 100% !important; }
    li[type = "button"] {
        display: block;
        text-align: left;
        button {
            background: $pBurgundy;
            color: $pOffWhite; } } }
