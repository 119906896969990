@import "../../../styles/helpers";

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }


.row {
    display: table-row; }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px 8px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey; }

.spinner {
    display: flex;
    width: 100%;
    justify-content: center; }

.noData {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #C7C5BF;
    display: flex;
    width: 100%;
    justify-content: center; }
