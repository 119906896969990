@import "../../../../styles/helpers";

.card {
    min-height: 220px;
    padding: 0;
    padding-bottom: 24px; }

.wrapper {
    margin: 36px 0 0; }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.nodata {
    position: absolute;
    padding-top: 20px;
    text-align: center;
    width: 100%; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.loadMore {
    float: right;
    margin-top: 30px;
    width: 150px;
    height: 50px;
    border: 1px solid gray; }


.row {
    display: table-row;
    &.active {
        .col {
            &:not(:first-child):not(:nth-child(2)) {
                display: none; }
            &:nth-child(2) {
                &:after {
                    right: 12px; } } } } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey;
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; }

        @include t {
            padding-left: 16px;
            height: 40px; }

        @include m {
            padding-left: 18px;
            height: 48px; } }

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(1)) {
        @include t {
            display: none; } } }

.dropdown {
    width: max-content; }

.dropdownHead {
    border: none;
    box-shadow: none;
    font-size: 15px;
    font-weight: 600;
    color: $pGrey;
    padding-left: 4px;
    &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.79289 8.54289C5.18342 8.15237 5.81658 8.15237 6.20711 8.54289L12 14.3358L17.7929 8.54289C18.1834 8.15237 18.8166 8.15237 19.2071 8.54289C19.5976 8.93341 19.5976 9.56658 19.2071 9.9571L13.4142 15.75C12.6332 16.531 11.3668 16.531 10.5858 15.75L4.79289 9.95711C4.40237 9.56658 4.40237 8.93342 4.79289 8.54289Z' fill='%23C7C5BF'/%3E%3C/svg%3E%0A"); }
    &:hover {
        box-shadow: none; } }

.checkbox {
    left: 0px;
    @include t {
        left: 0; } }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    margin-left: -24px;
    max-width: 340px;
    margin-bottom: 24px;
    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.editOverlayHead {
    background: red;
    position: absolute; }


.foot {
    margin-top: 20px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }


.btnClass {
    color: $pOrange;
    display: flex;
    justify-content:flex-start {}
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: all .2s;
    &:hover {
        background: #f4f4f4;
        @include dark {
            background: $n6; } }


    .btnIcon {
        margin-right: 16px; } }

.triHead {
    margin-left: 2px;
    position: relative; }

.tri-state-checkbox:focus::before {
    box-shadow: none !important; }

.tri-state-checkbox[aria-checked='mixed']::before,
.tri-state-checkbox[aria-checked='false']::before {
    @include dark {
        background: $pDarkGrey !important; } }

.triCheckbox[type="checkbox"],
.triCheckboxImg[type="checkbox"] {
    margin-left: -5px; }

.triCheckbox[type="checkbox"]:before,
.triCheckboxImg[type="checkbox"]:before {
    position: absolute;
    background: $n;
    margin-top: -1px;
    margin-left: -1px;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:after,
.triCheckboxImg[type="checkbox"]:after {
    position: absolute;
    content: '';
    margin-top: -1px;
    margin-left: -1px;
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }


.triCheckbox[type="checkbox"]:checked:after,
.triCheckboxImg[type="checkbox"]:checked:after {
    position: absolute;
    padding: 4px 3px;
    background: $pBurgundy !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") !important; }

.triCheckboxImg {
    margin-top: 12px; }
