@import "../../../../../styles/helpers";

.card {
    margin-top: 24px; }

.generalDetails {
    display: flex;
    flex-direction: column; }

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }

.header {
    color: $pGrey;
    width: 200px;
    word-wrap: break-word; }

.value {
    color: #464542;
    width: 200px;
    word-wrap: break-word; }

.margin {
    margin-top: 24px; }
