@import "../../../styles/helpers";

.row {
    display: flex;
    width: 100%; }

.col7 {
    flex: 0 0 58.33%;
    @include t {
        flex: 0 0 100%; } }

.exportXlsx {
    @include t {
        display: none !important; }
    width: 148px;
    position: absolute;
    right: 24px; }

.exportPdf {
    width: 148px;
    position: absolute;
    right: 186px; }

.autoOverflow {
    @include t {
        overflow-x: auto !important; } }

.dateCreated {
    @include base1-s;
    color: $pDarkGrey;
    margin-right: 8px; }

.buttonContainer2 {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    fill: $pDarkGrey;
    align-items: center;
    @include a {
        gap: 8px;
        justify-content: center; } }

.buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    @include a {
        gap: 8px;
        justify-content: center; } }

.input {
    padding-left: 48px !important;
    cursor: pointer;
    color: $pDarkGrey;
    width: 278px;
    @include dark {
        color: $shades1; }
    @include t {
        width: 48px;
        padding-left: 34px !important; } }

.card {
    padding: 24px 0 0;
    fill: $pDarkGrey;
    max-width: 835px;
    width: calc(100vw - 64px); }

.tableWrapper {
    margin-right: 12px;
    position: relative;
    max-width: calc(100vw - 64px); }

.tableWrap {
    position: relative;
    display: inline-block;
    min-width: 823px; }

.tableGroup {
    border-bottom: 1px solid #000; }

.spinner {
    display: flex;
    justify-content: center; }

.foot {
    margin-top: 20px;
    text-align: center;
    @include t {
        margin-top: 40px; }
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.table {
    display: flex;
    @include base1-b;
    color: $pDarkGrey;
    line-height: 24px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;

    .col12 {
        flex: 0 0 100%;
        padding: 1px 10px 0;
        @include base1-b;
        text-transform: uppercase;
        color: $n6;
        @include dark {
            color: $pDarkGrey; } }
    .col {
        padding: 1px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
            flex: 0 0 40%;
            border-right: 1px solid #000; }
        &:nth-child(2) {
            flex: 0 0 20%;
            border-right: 1px solid #000;
            text-align: end; }
        &:nth-child(3) {
            flex: 0 0 20%;
            border-right: 1px solid #000;
            text-align: end; }
        &:nth-child(4) {
            flex: 0 0 20%;
            text-align: end; } }




    &.yearTable {
        border: none;
        .col {
            &:first-child {
                border: none; }
            &:nth-child(2) {
                border-left: 1px solid #000;
                border-top: 1px solid #000;
                border-right: 1px solid #000; }
            &:nth-child(3) {
                border-top: 1px solid #000;
                border-right: 1px solid #000; }
            &:nth-child(4) {
                border-top: 1px solid #000;
                border-right: 1px solid #000; } } }
    &.total {
        background: $n3;
        color: $n6; }
    &.gTotal {
        background: $pDarkGrey;
        color: $pOffWhite;
        text-transform: uppercase; } }

.noDataImg {
    img {
        width: 228px;
        width: 228px; } }


.colX12 {
        padding: 1px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
            flex: 0 0 8.33%;
            border-right: 1px solid #000; }
        &:nth-child(2) {
            flex: 0 0 8.33%;
            text-align: end;
            border-top: 1px solid #000;
            border-left: 1px solid #000; }
        &:nth-child(3) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            text-align: end; }
        &:nth-child(4) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            text-align: end; }
        &:nth-child(5) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            text-align: end; }
        &:nth-child(6) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            text-align: end; }
        &:nth-child(7) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            text-align: end; }
        &:nth-child(8) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: end; }
        &:nth-child(9) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: end; }
        &:nth-child(10) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: end; }
        &:nth-child(11) {
            flex: 0 0 8.33%;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: end; }
        &:nth-child(12) {
            flex: 0 0 8.33%;
            text-align: end;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000; }
        &:nth-child(13) {
            flex: 0 0 8.33%;
            text-align: end;
            border: 1px solid #000; } }
