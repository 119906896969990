@import "../../../../styles/helpers";

.outerWrap {
    max-width: 688px; }

.card {
    padding: 0; }

.input {
    max-width: 310px;
    &:not(:first-child) {
        margin-top: 12px; } }

.dropdown {
    position: relative;
    margin-top: 12px;
    max-width: 310px; }

.newEmailBtn {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px; }

.detailsWrap {
    margin-top: 16px;
    .title {
        @include base1-s;
        color: $pDarkGrey; }
    .tokenMblWrap {
        display: none;
        margin-top: 8px;
        margin-bottom: 16px;
        @include t {
            display: block; } }
    .tokenWrap {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .token {
            background-color: $pDarkGrey;
            padding: 4px;

            width: max-content;
            color: $pOffWhite;
            cursor: pointer; }
        @include t {
            display: none; } }
    trix-editor {
        height: 200px !important;
        overflow-y: scroll !important;
        padding: 8px !important;
        ul {
            list-style: disc;
            padding-left: 40px; }
        ol {
            list-style: decimal;
            padding-left: 40px; }
        blockquote {
            border: solid #ccc;
            border-width: 0 0 0 0.3em;
            margin-left: 0.3em;
            padding-left: 0.6em; } } }
