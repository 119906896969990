@import "../../../styles/helpers";

.card {
    padding-bottom: 12px;
    margin-bottom: 200px;
    position: relative; }

.cardHead {
    margin-bottom: 0; }

.navWrap {
    @include t; }

.largeNav {
    @include t {
        display: none; } }

.mobileNav {
    display: none;
    width: 40%;
    margin-bottom: 20px;
    @include t {
        display: block;
        margin-left: auto; } }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    margin-left: 0px;
    max-width: 530px;
    margin-bottom: 20px;
    @include t {
        display: none; }
    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        text-align: center;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.deleteMainAccount {
    position: relative;
    bottom: 174px;
    width: 100%;
    left: 0;
    @media screen and (max-width: 1880px) {
        bottom: 190px; } }
