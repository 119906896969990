@import "../../styles/helpers";

.wrapper {
    height: 100vh;
    width: 100vw;
    position: fixed; }

.info {
    position: absolute;
    top: 40px;
    right: 40px;
    @include caption1;
    color: $shades1;
    @include t {
        padding-left: 80px; }
    @include m {
        right: 24px; }
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $pOffWhite; }
        &:hover {
            color: $pBurgundy;
            @include dark {
                color: $pBurgundy; } } } }

.user {
    position: relative;
    @include m {
        position: static; }
    &.active {
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding-right: 36px;
    img {
        width: 48px;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid gray;
        &.businessLogo {
            position: absolute;
            left: 36px; } }
    @include m {
        background: #F4F4F4;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.02), inset 0px -1px 1px rgba(0, 0, 0, 0.04);

        height: 72px;
        padding: 12px;
        @include dark {
            background: $n8; } } }

.body {
    position: absolute;
    top: calc(100% + 18px);
    border: 2px solid #6F767E40;
    right: -20px;
    width: 280px;
    padding: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include x {
        top: calc(100% + 15px);
        right: 0; }
    @include m {
        position: fixed;
        top: 90px;
        right: 16px;
        left: 16px;
        width: auto;
        padding: 12px 16px 20px; }

    @include dark {
        background: $n8;
        border-color: $n6; } }
.menu {
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $n3;
        @include m {
            margin-bottom: 8px;
            padding-bottom: 8px; }
        @include dark {
            border-color: $n6; } }
    &:first-child {
        .item {
            display: flex;
            justify-content: space-between; } } }

.item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }

.imageLogo {
    border-radius: 50%; }

.logo {
    width: 224px !important;
    height: 36px !important; }

.cover {
    pointer-events: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.6;
    z-index: 999; }

.loader {
    position: fixed;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 1000; }

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $n3;
  font-size: 0;
  svg {
    fill: $n5;
    transition: transform .2s; }
  @include dark {
    background: $n6;
    svg {
      fill: $pOffWhite; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }

.chooseCompanyWrap {
    position: absolute;
    height: 700px;
    top: calc(50% - 350px);
    left: 50%;
    transform: translate(-50%, 0%);
    background: #fff;
    padding: 32px;
    box-shadow: 0px 25.3px 30.36px -5.06px rgba(16, 24, 40, 0.08), 0px 10.12px 10.12px -5.06px rgba(16, 24, 40, 0.03);
    width: 510px;
    @include ht {
        height: 500px;
        top: calc(50% - 250px); }
    @include t {
        width: max-content; }
    .brandLogo {
        width: 200px;
        margin-top: 8px;
        padding-left: 10px;
        margin-bottom: 40px;
        img {
            width: 100%;
            object-fit: contain; } }
    h3 {
        font-size: 20px;
        font-weight: 500; }
    .companyWrap {
        .label {
            font-size: 18px;
            padding-left: 10px;
            font-weight: 500;
            color: $pGrey;
            margin-top: 16px; }
        .company {
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 16px;
            margin-top: 24px;
            img {
                height: 50px;
                width: 50px;
                object-fit: contain;
                object-position: center; }
            .name {
                font-size: 17px;
                font-weight: 700;
                color: $pDarkGrey;
                @include dark {
                    color: $n3; } }
            .position {
                font-size: 14px;
                font-weight: 700;
                color: #667085;
                @include dark {
                    color: $n3; } } }
        .addNewBtn {
            margin-top: 24px;
            color: $pDarkGrey;
            fill: $pDarkGrey;
            font-size: 17px;
            display: flex;
            padding-left: 10px;
            align-items: center;
            gap: 12px; } } }

.companyScrollView {
    overflow-y: scroll;
    height: 400px;
    @include ht {
        height: 200px; } }


