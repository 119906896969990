@import "../../../styles/helpers";

.pipelineCard {
    padding: 0px;
    min-height: 260px; }

.spinnerOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7; }

.modalOuter {
    min-width: 800px; }

.cardHeader {
    padding-top: 0px; }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 12px;
    @include dark {
        color: $pDarkGrey; } }

.cardTitleClass {
    word-wrap: break-word; }

.cardHead {
    gap: 12px;
    flex-wrap: wrap;
    justify-content: flex-end; }

.cardContainer {
    display: flex;
    flex-direction: column;
    height: 172px;
    width: 300px;
    background-color: $pLightGrey;
    padding: 16px;
    margin-bottom: 20px; }

.cardColumnContainer {
    display: flex;
    flex-direction: column; }

.cardRow {
    display: flex;
    flex-direction: row; }

.title {
    color: #C7C5BF;
    width: 80px; }

.field {
        width: 212px;
        @include m {
            width: 100%;
            margin: 0; } }

.action {
    position: absolute;
    top: 0;
    right: 0;
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }

.actionDownload {
    width: 72px;
    position: absolute;
    bottom: 30px;
    right: calc(50% - 30px);
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; }

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 260px; }

.classNameContainer {
    max-width: 310px;
    @include t {
        max-width: unset; } }

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.inputBoxWrap {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    flex-wrap: wrap; }

.inputBox {
    width: calc(33.33% - 8px);
    @include m {
        width: 100%; } }

.value {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include dark {
        color: $pDarkGrey; } }

.valueHyperlink {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
    @include dark {
        color: $pDarkGrey; } }


.headerRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px; }

.headerTitle {
    font-size: 20px;
    font-weight: 400; }

.headerValue {
    font-size: 20px;
    font-weight: 400;
    color: #FF4900; }

.cardTitle {
    font-size: 18px;
    color: $pOrange;
    font-weight: 300; }

.cardDescription {
    height: 36px;
    margin-top: 8px;
    font-weight: 300;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal; }

.margin {
    margin-top: 3px; }

.extraInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    gap: 8px; }

.filterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px; }

.dropdownFilter {
    height: 48px; }

.dateRangeInput {
    color: $n7;
    border: 2px solid #C7C5BF; }

.width33 {
    width: 33%; }

.addNewComment {
    margin-top: 4px;
    color: $pOrange;
    cursor: pointer;
    text-decoration: underline; }

.titleContainer {
    display: flex;
    flex-direction: row;
    gap: 8px; }

.width100 {
    width: 100%; }

.userImge {
    width: 48px;
    height: 48px;
    border: 2px solid $pGrey;
    border-radius: 50%;
    object-fit: cover; }

.number {
    height: 24px;
    width: 24px;
    text-align: center;
    color: #000;
    border-radius: 50%;
    background-color: $pLightGrey; }

.dropzoneWrap {
    width: 172px;
    height: 172px;
    padding: 8px;
    background-color: $n2;
    @include dark {
        background-color: $n5; } }

.dropzoneWrapComment {
    width: 172px;
    height: 172px;
    padding: 8px;
    background-color: $n2;
    @include dark {
        background-color: $n5; } }

.dropzoneComment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 8px;
  border: 2px dashed $pDarkGrey;
  color: #bdbdbd;
  cursor: pointer;
  fill: $pDarkGrey;
  @include dark {
    border-color: $pDarkGrey; }
  svg {
    flex: none; }
  span {
    @include base1-s;
    color: $pDarkGrey;
    text-align: center; } }

.dropzone {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border: 2px dashed $pDarkGrey;
  color: #bdbdbd;
  cursor: pointer;
  fill: $pDarkGrey;
  @include dark {
    border-color: $pDarkGrey; }
  svg {
    flex: none; }
  span {
    @include base1-s;
    color: $pDarkGrey;
    text-align: center; } }

.selectedImageWrap {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap; }

.selectedImageWrapComment {
    margin-top: 12px;
    margin-left: 56px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap; }

.selectedImage {
    width: 172px;
    height: 172px;
    object-fit: cover; }

.inputArea {
    width: 100%;
    padding: 0 10px;
    border: 2px solid $pGrey;
    background: $pLightGrey;
    font-family: 'tomatoGrotesk', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: $pDarkGrey;
    transition: all .2s; }


.fieldName {
    font-family: 'TomatoGrotesk-Light', sans-serif;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    color: #C7C5BF; }


.inputEdit {
    width: 33%; }
