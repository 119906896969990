@import "../../../../styles/helpers";

.profile {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    @include t {
        margin-bottom: 24px; }
    & > .button {
        @include m {
            display: none; } } }

.unlink {
    color: red; }
.googleButton {
    background: #3367D6 !important; }

.externalLogin {
    .button {
        position: relative;
        background: #3367D6 !important;
        @include t {
            margin-left: 0; } }
    .link {
        @include caption1;
        font-weight: 700;
        font-size: 15px;
        color: white; } }
.google2 {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 2px;
        background: white url('~/public/images/social-auth-icon-google.svg') no-repeat top left;
        background-position: -3px -3px;
        width: 44px;
        height: 44px; } }

.google {
    padding-left: 0px;
    &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 1px;
        left: -22px;
        background: white url('~/public/images/social-auth-icon-google.svg') no-repeat top left;
        background-position: -3px -3px;
        width: 43px;
        height: 43px; } }

.mainContainer {
    display: flex;
    flexDirection: row;
    @include t {
        display: block; } }

.profileContainer {
    width: calc(66.66% - 16px);
    @include d {
        width: 100%; } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    margin-top: -10px !important;
    margin-bottom: 20px !important; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.successMessage {
    color:#008000 {} }

.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 18px;
    top: 38px;
    background: transparent;
    height: 48px; }

.notification {
    width: calc(33.33% - 16px);
    margin 0 8px 0 {}
    @include t {
        width: 100%;
        margin-top: 50px;
        margin-left: 0px; } }

.avatarWrap {
    position: relative;
    width: max-content;
    button {
        height: 24px;
        width: 24px;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        fill: $pDarkGrey;
        right: 0; }
    .avatar {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center; } }

.signUpText {
    width: 767px;
    margin-bottom: 24px;
    margin-top: -12px;
    color: $pGrey;
    @include t {
        margin-top: 24px;
        width: 600px; }
    @include m {
        width: 100%; } }

.signUpButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap; }

.continueButton {
    @include t {
        margin-top: 24px; } }

.setupCallButton {
    @include t {
        margin-top: 24px; } }

.card {
    padding: 0; }

.signUpCard {
    padding: 0;
    margin-top: 20px; }


.outerClass {
    max-width: 520px; }

.typeNav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    @include s {
        padding: 10px; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.uploadImage {
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $n3;

    gap: 12px;
    fill: $pDarkGrey;
    color: $n5;
    margin-bottom: 20px; }

.orChoose {
    color: $pDarkGrey;
    margin-bottom: 20px; }

.avatarFlex {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer; } }

.selectedImageWrap {
    position: relative;
    width: 80px;
    height: 80px;

    @include m {
        width: 80px;
        height: 80px; }

    .selectedImage {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    @include dark {
        fill: $shades1; } }

.inputLabelImg {
    display: none; }

.imgInput {
    .input {
        display: none; } }
.btnContainer {
    height: 80px; }

.remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    font-size: 0;
    @include m {
        display: inline-block; } }

.file {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    overflow: hidden;
    @include m {
        margin-right: 0; }
    input {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 160px;
        opacity: 0; }
    .button {
        @include m {
            padding: 0 10px; } } }

.fieldset {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
    @include t {
        display: block; } }

.field {
    flex: 0 0 calc(50% - 6px);
    width: calc(50% - 6px);
    @include t {
        flex: 0 0 100%;
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.btnWrap {
    width: 150%;
    display: flex;
    @include d {
        width: 100%; }
    @include t {
        margin-top: 24px;
        width: 100% !important;
        flex-wrap: wrap; }
    .updatePassBtn {
        margin-right: 20px;
        @include t {
            margin-right: 0;
            margin-bottom: 24px; } }
    img {
        margin-right: 4px; } }

.btnWrapTop {
    position: absolute;
    top: -116px;
    justify-content: flex-end;
    width: calc(150% + 24px);
    @include d {
        width: 100%; }
    @include t {
        position: relative;
        left: 0;
        top: 0;
        justify-content: flex-start; } }


.verifiedBox {
    display: flex;
    margin-top: 14px;
    flex-direction: row;
    @include t {
        flex-wrap: wrap; }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.verified {
    width: 72px;
    height: 24px;
    background: #EAFAE4;
    text-align: center;
    align-items: center;
    color: $pBurgundy; }

.notVerified {
    width: 92px;
    height: 24px;
    background: #FFBC9925;
    text-align: center;
    align-items: center;
    color: red; }

.update {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    gap: 4px;
    width: 69px;
    height: 24px;
    background: #6F767E;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pOffWhite;
    margin-left: 14px;
    cursor: pointer; }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }

.popupField {
    margin-bottom: 16px; }



.cardDetails {
    color: $pDarkGrey;
    font-size: 14px; }

.singleCard,
.singleOwner {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    border: 2px solid $n3;
    padding: 12px 20px;
    margin-bottom: 30px;

    @include dark {
        border-color: $pDarkGrey; }
    span {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; } } }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.stripeTypeContainer {
    width: 33%;
    @include a {
        width: 100%; } }
.dropdown {
    min-width: 280px;
    border: 2px solid rgb(199, 197, 191);
    margin-bottom: 10px;
    div {
        color: $pDarkGrey  !important; } }

.marginLeftFields {
    margin-left: 12px;
    @include t {
        margin-left: 0px !important; } }
