@import "../../../../styles/helpers";

.card {
    padding: 0; }

.input {
    width: 55%; }

.updateFieldWrap {
    display: flex;
    align-items: center; }

.buttonClose {
    margin-left: -4px; }

.field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,
    .right {
        display: flex;
        align-items: center;
        gap: 16px;
        @include caption1-m;
        color: $pDarkGrey;
        svg {
            fill: $pDarkGrey; }
        .deleteBtn {
            svg {
                fill: $pOrange; } } } }


.newFieldBtn {
    display: flex;
    flex-direction: row;
    align-items: center; }

.deleteModal {
    display: none; }
