@import "../../../../../styles/helpers";

.cardHead {
    justify-content: center;
    margin-bottom: 20px; }

.cardTitle {
    margin-right: unset; }

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #FF4900 !important; }

.text {
    color: var(--primary-dark-grey, #464542);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px; }

.headerImageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
    @include t {
        align-items: center;
        flex-direction: column; } }

.additionalFields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px; }

.mainImage {
    width: 200px;
    height: 200px;
    object-fit: cover; }

.generalDetails {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }

.total {
    color: var(--primary-dark-grey, #464542);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 160% */;
    letter-spacing: -0.4px;
    margin-top: 24px;
    text-align: center; }

.singleDetail {
    width: 110px; }

.projectCost {
    margin-top: 8px !important;
    color: $pOrange; }

.seperator {
    margin-top: 20px;
    height: 1.5px;
    border-radius: 1px;
    opacity: 0.25;
    background: var(--primary-dark-grey, #464542); }


.header {
    color: var(--primary-grey, $pGrey);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px; }

.value {
    color: var(--primary-dark-grey, #464542);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 160% */;
    letter-spacing: -0.15px; }

.fullWidthDetail {
    width: 100%;
    margin-top: 8px; }

.fullWidthValue {
    color: #464542; }

.imageWrapper {
    margin-top: 24px; }

.imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
    align-items: center;
    @include t {
        justify-content: center; } }

.img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer; }

.title {
    color: $pGrey; }

.secondCard {
    margin-top: 24px; }

.totalMargin {
    margin-top: 8px !important; }


