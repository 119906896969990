@import "../../../../styles/helpers";

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 10px;
    @include dark {
        color: $pDarkGrey; } }

.close {
    position: absolute;
    top: 52%;
    transition: all .2s;
    svg {
        transition: fill .2s;
        background: transparent !important;

        .close {} }
    right: 12px;

    svg {
        fill: $shades1;
        background: $n2; }
    &:hover {
        svg {
            fill: $pOrange; } } }

.inputRed {
    background-color: rgba(255, 73, 0, 0.1); }

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }

    .field {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.dropdown {
    z-index: 9;
    background-color: #F4F4F4;
    border-color: #c7c5bf;
    height: 48px; }

.fieldDropdown {
    width: 100%; }

.TypeDropdown {
    z-index: 0 !important;
    box-shadow: unset !important; }

.avatarWrap {
    position: relative;
    width: max-content;
    margin: 0 8px;
    button {
        height: 24px;
        width: 24px;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        fill: $pDarkGrey;
        right: 0; }
    .avatar {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center; }
    .diffBgColor {
        height: 80px;
        width: 80px;
        border-radius: 50%; } }
.card {
    padding: 0; }

.emailWrapper {
    position: relative; }

.hasError {
    flex: 0 0 calc(33.33% - 16px);
    width: calc(33.33% - 16px);
    margin: 0 8px 16px;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {}
        margin-bottom: 24px; }
    input {
        background: rgba(255, 73, 0, 0.1) !important;
        color: $pOrange;
        @include base1-s;
        &:focus {
            border-color: #FFBC9925; }
        @include dark {
            color: $pOrange;
            &:focus {
                border-color: $pOrange; } } } }

.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 8px;
    margin-top: 35px;
    top: 0;
    background: transparent;
    height: 48px; }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }


.validIcon {
    position: absolute;
    fill: $pBurgundy;
    right: 8px;
    margin-top: 35px;
    top: 0;
    background: transparent;
    height: 48px; }



.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }

.outerClass {
    max-width: 520px; }

.typeNav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    @include s {
        padding: 10px; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.uploadImage {
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $n3;

    gap: 12px;
    fill: $pDarkGrey;
    color: $n5;
    margin-bottom: 20px; }

.orChoose {
    color: $pDarkGrey;
    margin-bottom: 20px; }

.avatarFlex {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer; } }


