@import "../../../../styles/helpers";
.modalOuter {
    max-width: 1000px;
    height: 100%;
    overflow-y: scroll;
    @include t {
        max-width: 408px;
        height: 100%;
        overflow-y: scroll; } }

.warning {
    color: $pOrange; }

.outer {
    max-width: 750px !important;
    padding-top: 48px; }

.input {
    height: 48px;
    padding: 0 24px 0 24px;
    border: 2px solid $pGrey;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        background: transparent;
        color: $pOffWhite; }
    &::placeholder {
        color: $shades1; }
    &:focus {
        background: $n;
        @include dark {
            background: $n8; } } }

.cover {
    pointer-events: none;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.6;
    z-index: 999; }

.loader {
    position: absolute;
    left: calc(50% - 24px);
    top: calc(50% - 24px); }

.label {
    margin-bottom: 5px;
    font-size: 8px;
    font-weight: 600;
    line-height: 1.71429;
    color: $pGrey; }

.fieldWrap {
    margin-bottom: 12px; }

.fieldLabel {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    color: $pGrey;
    @include dark {
        color: $pDarkGrey; } }

.search {
    margin-bottom: 0 !important; }

.row {
    display: flex;
    width: 100%;
    @include t {
        flex-wrap: wrap; }
    .col {
        flex: 0 0 33.33%;
        padding: 0 12px;
        &:not(:last-child) {
            border-right: 1px solid $shades3;
            @include t {
                padding: 0;
                border: none; } }
        @include t {
            flex: 0 0 100%; } }
    .col2 {
        flex: 0 0 50%; } }
.input {
    margin-bottom: 16px; }

.notBooked {
    color: #FF4900; }

.booked {
    color: #34A853; }

.card {
    padding: 0 8px;
    margin-top: -4px; }
.image {
    margin-bottom: 16px;
    width: 100%; }

.attachmentButtons {
    display: flex;
    flex-wrap: wrap;
    .button {
        margin-right: 8px;
        margin-bottom: 8px;
        padding-left: 0;
        padding-right: 8px;
        @include d {
            height: 40px;

            font-size: 13px; } } }
.label {
    color: $pGrey; }
.metaWrapper {
    .singleMeta {
        border-bottom: 2px solid $pGrey;
        margin-bottom: 12px;
        @include dark {
            border-bottom: 1px solid $shades3; }
        .label {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            color: $pGrey; }
        .text {
            padding: 12px;
            @include base1-r;
            color: $pDarkGrey;
            height: 46px; } } }

.moreDetailsBtn {
    width: 100%;
    margin: 12px 0; }

.dropzoneWrap {
    padding: 8px;
    background-color: $n2;
    margin-bottom: 20px;

    @include dark {
        background-color: $n5; } }
.dropzone {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 2px dashed $pDarkGrey;
  color: #bdbdbd;
  cursor: pointer;
  fill: $pDarkGrey;
  @include dark {
    border-color: $pDarkGrey; }
  span {
    @include base1-s;
    margin-left: 8px;
    color: $pDarkGrey; } }

.dropImgDelete {
    fill: $pOrange; }

.selectedImageWrap {
    width: 100%;
    position: relative;
    .selectedImage {
        width: 100%; }
    .action {
        position: absolute;
        bottom: 16px;
        padding: 8px;
        background: $n;
        left: 50%;
        transform: translateX(-50%);

        fill: $pDarkGrey;
        @include dark {
            background: $n5; }
        button {
            &:not(:last-child) {
                margin-right: 8px; } } } }

.infoWrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
    background: #F4F4F4;
    @include dark {
        background: $n6; }
    .left {
        position: relative;
        margin-right: 16px;
        .pic {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center; }
        .deleteBtn {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: $shades2;
            display: none;
            border-radius: 50%; } }
    &:hover {
        .left {
            .deleteBtn {
                display: block; } } }
    .infoWrap {
        .info {
            display: table-row;
            align-items: center;
            @include caption1-m;
            .label {
                color: $pGrey;
                @include caption1-m;
                padding-right: 12px;
                display: table-cell;
                @include dark {
                    color: $shades1; } }
            .txt {
                display: table-cell;
                color: $n7;
                word-break: break-all;
                @include dark {
                    color: $pDarkGrey; } } } } }

