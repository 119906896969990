@import "../../../../styles/helpers";

.item {
    display: flex;
    align-items: center;
    padding: 12px;

    cursor: pointer;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
        padding: 8px; }
    &:hover,
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &.new {
        .head:after {
            background: $pBurgundy; } }
    &:not(:last-child) {
        margin-bottom: 8px;
        @include m {
            margin-bottom: 0; } } }

.details {
    flex: 0 0 100%;
    max-width: 100%; }

.head {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    &:after {
        content: "";
        flex-shrink: 0;
        display: block;
        width: 12px;
        height: 12px;
        margin-left: 12px;
        border-radius: 50%;
        background: $shades1; } }

.title {
    margin-right: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700; }

.time {
    flex-shrink: 0;
    margin-left: 24px;
    @include caption1;
    color: $shades1; }

.message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    color: $pDarkGrey; }

.hideclass {
    display: none; }
