@import "../../../../../styles/helpers";

.card {
    width: 100%; }

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px; }

.txt {
    @include title1-s;
    color: $n7;
    @include dark {
        color: $n2; } }

.pic {
    max-width: 100px; }

.wrap {
    margin-top: 8px; }

.title {
    @include caption1-m;
    color: $pDarkGrey;
    @include dark; }

.text {
    @include caption1-m;
    margin-left: 12px;
    color: #000;
    @include dark {
        color: $pDarkGrey; } }

.addresses {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    .title {
        @include caption2;
        color: $n6;
        @include dark {
            color: $pDarkGrey; } }
    .name {
        @include caption1-m;
        color: $n6;
        margin-top: 8px;
        @include dark {
            color: $pDarkGrey; } }
    .addressLine {
        @include caption1-m;
        color: $pDarkGrey;
        margin-top: 8px; }
    .wrapAddress {
        display: flex;
        align-items: center;
        gap: 8px; } }

.due {
    margin-top: 24px;
    .dueHead {
        @include title1-s;
        color: $n7; }
    .link {
        @include caption1-m;
        color: $pBurgundy; } }

.paid {
    color: $pBurgundy;
    @include dark {
        color: $pBurgundy; } }

.thankStump {
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    .stump {
        width: 161px; } }

.descWrap {
    margin-top: 24px;
    .descHead {
        border-bottom: 1px solid $n6;
        padding-bottom: 8px;
        margin-bottom: 16px; }
    .descHead,
    .descBody {
        display: flex;
        .singleDesc {
            @include base1-b;
            color: $pDarkGrey;
            &:first-child {
                flex: 0 0 calc(25% - 8px);
                width: calc(25% - 8px);
                margin: 0 8px;
                margin-left: 0;
                @include m {
                    width: 100%;
                    margin: 0; } }
            &:nth-child(2) {
                flex: 0 0 calc(15% - 16px);
                width: calc(15% - 16px);
                margin: 0 8px;
                @include m {
                    width: 100%;
                    margin: 0; } }
            &:nth-child(3) {
                flex: 0 0 calc(15% - 16px);
                width: calc(25% - 16px);
                margin: 0 8px;
                @include m {
                    width: 100%;
                    margin: 0; } }
            &:nth-child(4) {
                flex: 0 0 calc(25% - 8px);
                width: calc(20% - 8px);
                margin: 0 8px;
                margin-right: 0;
                @include m {
                    width: 100%;
                    margin: 0; } }
            &:nth-child(5) {
                flex: 0 0 calc(20% - 8px);
                width: calc(20% - 8px);
                margin: 0 8px;
                margin-right: 0;
                text-align: end;
                @include m {
                    width: 100%;
                    margin: 0; } } } } }

.inclusive {
    color: $pBurgundy;
    background: #B5E4CA;
    padding: 0 8px;
    margin-left: 8px; }

.exclusive {
    background: rgba(235,61,52,.28);
    color: $pOrange;
    padding: 0 8px;
    margin-left: 8px; }

.totalWrap {
    display: flex;
    justify-content: space-between;
    width: 45%;
    position: relative;
    left: 55%;
    margin-top: 20px;
    &:nth-child(2) {
        text-align: right; } }

.amount {
    @include caption1-m;
    color: #000;
    margin-bottom: 8px;
    @include dark {
        color: $pDarkGrey; } }

.dueAmount {
    @include caption2;
    color: #000;
    @include dark {
        color: $pDarkGrey; } }

.payWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .payInfoWrap {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 8px;
        margin-left: 0; }

    .head {
        margin-bottom: 8px; }
    .text {
        text-transform: uppercase; } }
.payInfo {
    margin-top: 12px;
    div {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .title {
            flex: 0 0 110px;
            width: 110px; } } }
