@import "../../../../styles/helpers";

.chart {
    width: auto;
    height: 238px;
    margin-left: -20px;
    margin-bottom: -10px;
    @include m {
        height: 206px; } }


.tooltipContainer {
    padding: 8px;
    width: 183px;
    background-color: #464542;
    border-color: rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    box-shadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000";
    color: #fff;
    font-size: 14px;
    font-weight: 500; }

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 2px; }

.contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px; }

.box {
    height: 12px;
    width: 12px;
    border-radius: 3px; }

.line {
    height: 1px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #C7C5BF;
    width: 100%; }
