@import "../../styles/helpers";

.card {
    position: relative;
    padding: 20px;
    border-radius: 20px;
    background: $pOffWhite;
    @include m {
        padding: 16px;
        border-radius: 0; }
    @include s {
        padding: 8px; }
    @include dark {
        background: $n7; }
    &.border {
        border: 1px solid $pOrange; } }


.card11 {
    padding: 24px 0px;
    @include m {
        padding: 16px; }
    @include s {
        padding: 8px; }
    @include dark {
        background: $n7; } }

.head {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 32px;
    @include m {
        margin-bottom: 24px; } }

.title {
    @include TomatoGroteskLight;
    margin-right: auto;
    font-size: 32px;
    line-height: 40px;
    color: $pDarkGrey;
    font-weight: 300;
    @include dark {
        color: $pOffWhite; }
    @include m {
        line-height: 40px; } }
