.card {
    height: max-content; }

.cardMargin {
    min-height: 420px; }

.buttonContainer {
    display: flex;
    gap: 12px;
    flex-wrap: wrap; }
