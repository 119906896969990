@import '../../styles/helpers.sass';

$colors: ('1' $pBurgundy, '2' $p2,'3' $pOrange,'4' $p4,'5' $pOrange,'6' $pBurgundy,'7' $s1,'8' $s2,'9' $s3,'0' $s5);

@each $index, $color in $colors {
    .avatar-avt-#{$index} {
        background-color: $color;
        @include base1-b;
        color: $n;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 12px;
        border-radius: 50%;
        overflow: hidden; } }

.avatar-avt-big-icon {
    @include title1-s;
    width: 80px;
    height: 80px;
    margin-right: 0; }

.avatar-avt-invoice {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    @include t {
        display: none; } }

.avatar-avt-customer-info {
    margin-right: 0; }
