@import "../../styles/helpers";

.modalOuter {
    max-height: 100%;
    min-height: 380px;
    height: max-content;
    overflow-y: scroll;
    max-width: 560px;
    @include t {
        max-width: 100%; } }

.inputWrap {
    display: flex;
    gap: 12px;
    flex-wrap: wrap; }

.field {
    max-width: 310px;
    width: 100%;
    @include t {
        max-width: unset; } }

.input {
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none; }

.title {
    font-size: 28px;
    font-weight: 300; }

.fieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px; }

.buttonContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap; }

.spinner {
    position: fixed;
    top: calc(50% - 24px);
    left: calc(50% - 24px); }
