@import "../../styles/helpers";

.row {
    position: relative;
    background: $pOffWhite;
    @include dark {
        background: $n7; } }

.col {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include m {
        padding-bottom: 32px; } }


.wrap {
    max-width: 320px;
    border-radius: 20px;
    border: 1px solid $pOrange;
    padding: 24px;
    padding-bottom: 44px; }

.preview {
    max-width: 230px;
    margin: 0 auto 30px;
    img {
        width: 100%; } }

.subtitle {
    margin-bottom: 48px;
    text-align: center; }

.list {
    li {
        position: relative;
        padding-left: 36px;
        @include base2;
        color: $pDarkGrey;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 8-8 7.98 7.98 0 0 1 5.942 2.644L11 13.586l-2.793-2.793a1 1 0 0 0-1.414 1.414L9.586 15a2 2 0 0 0 2.828 0l6.687-6.687C19.676 9.416 20 10.67 20 12z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; }
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.logo {
    display: inline-block;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    width: 224px;
    img {
        width: 100%; }
    @include t {
        width: 150px; } }

.info {
    position: relative;
    top: 20px;
    @include base1-r;
    color: $pDarkGrey;
    a {
        @include base1-s;
        font-weight: 600;
        color: $pDarkGrey;
        transition: color .2s;
        margin-left: 4px;
        @include dark {
            color: $pOffWhite; }
        &:hover {
            color: $pBurgundy;
            @include dark {
                color: $pBurgundy; } } } }

.title {
    margin-bottom: 0px;
    color: $pDarkGrey; }


.themeWrap {
    position: absolute;
    bottom: 24px;
    left: 24px;
    @include t {
        display: none; }
    .link {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        width: 100%;
        height: 36px;
        font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
        color: $pDarkGrey;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        transition: background .2s, box-shadow .2s, color .2s;
        @include m {
            width: 100%;
            font-size: 13px; }
        svg {
            margin-right: 4px;
            fill: $pDarkGrey;
            transition: fill .2s; }
        &:hover,
        &.active {
            color: $pOrange;
            svg {
                fill: $pOrange; }
            @include dark {
                color: $pOffWhite;
                svg {
                    fill: $pOffWhite; } } } } }

.theme {
    span {
        @include d {
            font-size: 15px !important;
            border-radius: 0; } } }
