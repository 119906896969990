@import "../../../styles/helpers";

.container {
    display: flex;
    flex-direct: row; }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.line {
    height: 100vh;
    border: 1px solid $pGrey; }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.field {
    margin-top: 20px; }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 7px;
    @include dark {
        color: $n3; } }

.search {
    max-width: 100%;
    margin-bottom: 17px; }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        display: block; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 8px;
            fill: $pDarkGrey; }
        @include dark {
            svg {
                fill: $pDarkGrey; } }
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            svg {
                fill: $n7; }
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $shades1;
                svg {
                    fill: $shades1; } } } } }

.dropzoneWrap {
    padding: 8px;
    background-color: $n2;
    margin-bottom: 20px;

    @include dark {
        background-color: $n5; } }
.dropzone {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 2px dashed $pDarkGrey;
  color: #bdbdbd;
  cursor: pointer;
  fill: $pDarkGrey;
  @include dark {
    border-color: $pDarkGrey; }
  span {
    @include base1-s;
    margin-left: 8px;
    color: $pDarkGrey; } }

.dropImgDelete {
    fill: $pOrange; }

.selectedImageWrap {
    width: 100%;
    position: relative;
    .selectedImage {
        width: 100%; }
    .action {
        position: absolute;
        bottom: 16px;
        padding: 8px;
        background: $n;
        left: 50%;
        transform: translateX(-50%);

        fill: $pDarkGrey;
        @include dark {
            background: $n5; }
        button {
            &:not(:last-child) {
                margin-right: 8px; } } } }

.infoWrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
    background: #F4F4F4;
    @include dark {
        background: $n6; }
    .left {
        position: relative;
        margin-right: 16px;
        .pic {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center; }
        .deleteBtn {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: $shades2;
            display: none;
            border-radius: 50%; } }
    &:hover {
        .left {
            .deleteBtn {
                display: block; } } }
    .infoWrap {
        .info {
            display: table-row;
            align-items: center;
            @include caption1-m;
            .label {
                color: $pGrey;
                @include caption1-m;
                padding-right: 12px;
                display: table-cell;
                @include dark {
                    color: $shades1; } }
            .txt {
                display: table-cell;
                color: $n7;
                word-break: break-all;
                @include dark {
                    color: $pDarkGrey; } } } } }
