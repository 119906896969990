@import "../../../../styles/helpers";

.card {
    padding: 0; }

.outer {
    max-width: 800px; }

.search {
    margin-bottom: 0; }

.customContactSearch {
        padding-right: 0px !important; }

.btnWrap {
    display: flex;
    align-items: center;
    gap: 8px; }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(33.333% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px 16px;
        .fieldWrap {
            display: flex;
            align-items: center;
            margin: 0 -4px;
            input {
                width: 11ch;
                border: 1px solid $n3;
                height: 48px;
                padding: 4px 4px;

                font-weight: 600;
                margin: 0 4px;
                @include dark {
                    background: $n6;
                    border-color: $n6;
                    color: $n3; } } }
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.customerInput {
    input {
        background: $n3 !important; } }
.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px;
        @include dark {
            background: $n6; } } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.userChooseDrop {
    margin-top: 12px; }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 12px;
    height: 100px;
    @include m {
        height: 80px; } }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include m {
        height: 80px;
        width: 80px; }
    @include dark {
        fill: $pOffWhite; } }

.inputLabelImg {
    display: none; }


.dateInput {
    .input {
        cursor: pointer;
        color: $n5; } }

.inputValueFuckThisDatePicker {
    color: $n5 !important; }

.deleteModal {
    position: fixed;
    z-index: 9999; }

.customerHead {
    .customerImage {
        width: 35px;
        height: 35px;
        border-radius: 50%; }
    .name {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em;
        color: #1A1D1F;
        font-size: 15px; } }


.infoWrapper {
    display: flex;
    align-items: center;
    padding: 8px;
    background: #F4F4F4;

    width: max-content;
    @include dark {
        background: $n6; }
    .left {
        position: relative;
        margin-right: 16px;
        .pic {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center; }
        .deleteBtn {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: $shades2;
            display: none;
            border-radius: 50%; } }
    &:hover {
        .left {
            .deleteBtn {
                display: block; } } }
    .infoWrap {
        .info {
            display: table-row;
            align-items: center;
            @include caption1-m;
            .label {
                color: $pGrey;
                @include caption1-m;
                padding-right: 12px;
                display: table-cell;
                @include dark {
                    color: $shades1; } }
            .txt {
                display: table-cell;
                color: $n7;
                word-break: break-all;
                @include dark {
                    color: $pDarkGrey; } } } } }
