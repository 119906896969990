@import "../../../styles/helpers";

.card {
    padding: 12px; }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    margin-top: 10px; }

.customerImage {
    width: 35px;
    height: 35px;
    border-radius: 50%; }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include caption1;
    color: $pDarkGrey;
    @include dark {
        color: $n3; } }

.currencyContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 10px; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;

    border: 2px solid transparent;
    background: $n2;
    @include tomatoGrotesk;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $pOffWhite; }
    @include placeholder {
        color: $pDarkGrey;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px 8px 8px;
    width: 194px;
    height: 100px;
    background: $pOffWhite;
    border: 2px solid #EFEFEF;
    justify-content: center;
    padding-left: 20px;
    margin-top: 10px;
    @include dark {
        background: $n7; } }

.counter {
    font-size: 32px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.03em;
    @include w {
        font-size: 28px;
        line-height: 48px; }
    @include dark {
        color: $n3; } }

.backBtn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.head {
    margin: 0; }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        display: block; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .colDocPreview {
        flex: 0 0 calc(65% - 16px);
        width: calc(65% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.recipientHead {
    margin-top: 8px;
    .customerTitle {
        @include base1-s;
        color: $pDarkGrey;
        margin-bottom: 8px; }
    .name {
        @include title1-s;
        color: $n7;
        @include dark {
            color: $shades1; } }
    .email {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $shades1; } } }

.dotsBtnWrap {
    position: absolute;
    right: 12px;
    top: 12px;
    fill: $pDarkGrey; }

.deleteBtn {
    fill: $n5;
    margin-left: 8px;
    background: $n3;
    padding: 8px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    svg {
        transition: transform .2s; }
    @include dark {
        background: $n6;
        fill: $n3; } }

.field {
    margin-top: 20px;
    .fieldLabel {
        @include base1-s;
        color: $pGrey; } }

.calendarClearBtn {
    margin-top: 332px;
    border-top: 1px solid $n3;
    padding: 24px 0 0;
    text-align: end;
    button {
        border: 2px solid $n3;
        padding: 8px 16px;
        font-size: 13px;
        font-weight: 700; } }



.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
  @include dark {
    background: $n7; } }
