@import "../../../../../../styles/helpers";

.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px; }
    @include m {
        padding: 16px; }
    &:first-child {
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $pDarkGrey; } }

    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } } }

.editOverlay {
    position: absolute;
    height: 48px;
    cursor: pointer;
    width: calc(100% - 32px);
    right: 16px;
    margin-top: 8px;
    @include t {
        height: 232px;
        margin-top: -6px; } }


.col {
    display: table-cell;
    padding: 10px 13px;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        padding: 0; }
    &:first-child {
        padding-left: 10px;
        @include t {
            padding-left: 0; } }
    &:nth-child(3) {
        @include t {
            align-items: flex-start;
            @include dark {
                border-color: $n6; } } }
    &:nth-child(4) {}
    &:last-child {
        padding-right: 24px;
        @include t {
            padding-right: 0;
            margin-top: -6px;
            position: absolute;
            right: 48px; } }
    &:not(:last-child) {} }


.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    &:hover {
        color: $pBurgundy; }
    .email {
        display: none;
        @include t {
            display: block;
            font-size: 13px;
            line-height: 1;
            color: $shades1; } } }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.customer {
    position: relative;
    .name {
        display: flex;
        align-items: center;
        color: $n6;
        @include dark {
            color: $n2; } } }

.amountTag,
.statusTag,
.createdTag,
.dueTag,
.invoiceNoTag {
    display: none;
    width: 65px;
    font-size: 15px;
    color: $pDarkGrey;
    margin-right: 16px;
    @include t {
        display: block; } }

.dotsBtn {
    position: relative;
    @include m {
        position: static;
        margin-right: -24px; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; } }

    @include dark {
        fill: $n; } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;
    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }
    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 96px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.head {
    display: flex;
    justify-content: flex-end;
    .btn {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #EFEFEF;
        margin-left: 24px;
        @include dark {
            background: $n6; } } }


.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &.deleteBtn {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        &:hover,
        &.active {
            color: $pOrange;
            svg {
                fill: $pOrange; }
            @include dark {
                color: $pOrange;
                svg {
                    fill: $pOrange; } } } }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }

.image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 16px;
    flex: none;
    display: inline;
    @include t {
        display: none; } }

.paid {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }

.void {
    padding: 2px 8px;
    @include base1-s;
    background: rgba(235,61,52,.28);
    color: $pOrange; }

.open {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }

.draft {
    background: $n3;
    padding: 0 8px;
    @include base1-s;
    color: $pDarkGrey; }

.paidModalCard {
    padding: 0;
    .head {
        padding-bottom: 24px;
        border-bottom: .1px solid #efefef;
        margin-bottom: 24px;
        @include dark {
            border-color: $n5; } }
    p {
        @include base1-s;
        color: $pDarkGrey; }
    .cashBtn {
        width: 100%;
        justify-content: start;
        margin-top: 24px;
        &:hover {
            background: $n3;
            box-shadow: none;
            @include dark {
                background: $n6; } } }
    .chooseTranBtn {
        width: 100%;
        justify-content: start;
        margin-top: 8px;
        &:hover {
            background: $n3;
            box-shadow: none;
            @include dark {
                background: $n6; } } } }

.transactionsWrap {
    max-width: 100%;
    .transaction {
        padding: 0; } }

.dateInput {
    position: absolute;
    top: 0;
    .input {
        cursor: pointer;
        background: transparent;
        height: 0;
        width: 0; }
    .inputValue {
        opacity: 0; } }
