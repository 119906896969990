@import "../../styles/helpers";

.pageTitle {
    margin-bottom: 20px; }

.spinner {
    position: absolute;
    top: 50%;
    left: calc(50% + 140px);
    @include d {
        left: calc(50% + 40px); }
    @include t {
        left: calc(50% - 25px); } }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        display: block; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.info {
    opacity: 1;
    @include d {
        opacity: 0; } }
