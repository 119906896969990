@import "../../styles/helpers";

.cardInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .name {
        @include caption1;
        color: $pOffWhite; }
    .number {
        font-size: 24px;
        font-weight: 600;
        color: white;
        @include dark {
            color: $shades1; } } }

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    pointer-events: none; }
