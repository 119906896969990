@import "../../../../styles/helpers";

.message {
    display: flex;
    width: 70%;
    @include m {
        width: 100%; }
    &:not(:last-child) {
        margin-bottom: 32px; } }

.messageRight {
    display: flex;
    width: 70%;
    float: right;
    @include m {
        width: 100%; }
    &:not(:last-child) {
        margin-bottom: 32px; } }

.avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.details {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    padding-left: 16px; }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 4px; }

.man {
    color: $pDarkGrey;
    font-weight: 600; }

.time {
    margin-left: 12px;
    @include caption1;
    color: $shades1; }

.content {
    white-space: pre-wrap;
    color: #6F767E;
    a {
        font-weight: 600;
        color: $pBurgundy; } }

.userImage {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    &.businessLogo {
        position: absolute;
        left: 36px;
        @include m {
            left: 60px; } } }

.chatContainer {
    display: flex;
    gap: 8px; }

.contentContainer {
    display: flex;
    flex-direction: column; }
