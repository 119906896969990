@import "../../../../styles/helpers";

.card {
    padding: 0; }

.input {
    width: 70%; }

.singleChecklistWrap {
    display: table;
    width: 100%; }

.singleChecklist {
    display: table-row;
    color: $pDarkGrey;
    gap: 12px;
    .col {
        display: table-cell;
        padding: 12px;
        height: 51px !important;
        &:first-child {
            max-width: 80%; }
        &:last-child {} }
    &:hover {
        .col {
            background: $n2;
            @include dark {
                background: $n6; } } }
    button {
        text-align: end; }
    .previewBtn {
        background: $n2; }
    @include s {
        gap: 4px; } }

.right {
    background: $n;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    fill: $pDarkGrey;
    @include dark {
        background: $n6; } }

.checklistField {
    max-width: 600px;
    overflow: hidden;
    background: $pOffWhite;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &.showField {
        height: max-content !important;
        padding: 1px;
        margin-top: 12px; } }

.dotsBtn {
    position: relative;
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; } }
    @include dark {
        fill: $n; } }


.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 96px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 20px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }


.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &.deleteBtn {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        &:hover,
        &.active {
            color: $pOrange;
            svg {
                fill: $pOrange; }
            @include dark {
                color: $pOrange;
                svg {
                    fill: $pOrange; } } } }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }
.deleteItem {
    color: $pOrange !important;
    svg {
        fill: $pOrange !important; } }

.newChecklistBtn {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    bottom: 0; }

.title {
    @include base1-s;
    color: $n6;
    margin-bottom: 16px;
    padding-bottom: 4px;
    @include dark {
        color: $n3; } }

.dragWrap {
    position: relative;
    background: $pOffWhite;
    height: max-content;
    cursor: move;
    @include dark {
        background: $n7; } }

.addFieldBtnWrap {
    margin-top: 16px;
    .addFieldBtn {
        fill: $pBurgundy;
        span {
            margin-left: 8px;
            color: $pBurgundy; } } }

.checklistUpdatePopup {
    padding-bottom: 60px; }

.checklistBtnWithField {
    button {
        background: #D9D9D9;
        &:hover {
            background: #D9D9D9;
            cursor: not-allowed; } } }
