@import "../../../../../styles/helpers";

.outer {
    max-width: 830px; }

.cashPopupOuter {
    max-width: 560px; }

.card {
    padding: 0; }

.row {
    display: flex;
    margin: 0 -8px;
    width: 100%;
    @include t {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(33.33% - 8px);
    margin: 0 6px;
    @include t {
        flex: 0 0 100%;
        margin-bottom: 12px; } }

.singleAccountInfo {
    border: 3px solid $n3;

    padding: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    @include dark {
        border: 3px solid $shades3; }
    .title {
        @include base1-s;
        color: $n7;
        margin-bottom: 20px;
        @include dark {
            color: $shades1; } }
    .txt {
        @include caption2-m;
        color: $pDarkGrey;
        margin-bottom: 20px;
        @include dark {
            color: $shades1; } }
    .singleFeature {
        @include base1-s;
        color: $n5;
        fill: #B5E4CA;
        margin-bottom: 12px;
        svg {
            margin-right: 12px; }
        @include dark {
            color: $shades1; } }
    button {
        font-size: 14px;
        padding: 8px;
        width: 100%; } }

.thanksPopup {
    margin-top: 64px;
    text-align: center;
    .imgWrap {
        background: $pOrange;
        height: 128px;
        width: 128px;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 100px;
            height: 100px; } }
    h2 {
        @include title1-s;
        color: $n5;
        margin-top: 20px;
        @include dark {
            color: $shades1; } }
    p {
        @include base1-s;
        color: $pDarkGrey;
        margin-top: 20px; }
    a {
        margin-top: 20px; } }

.cashAccountWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include t {
        display: block; }
    .field {
        flex: 0 0 calc(50% - 12px);
        margin-bottom: 20px;
        &:nth-child(odd) {
            margin-right: 12px;
            @include t {
                margin-right: 0; } }
        &:nth-child(even) {
            margin-left: 12px;
            @include t {
                margin-left: 0; } } } }
