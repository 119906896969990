// common styles
@import "./helpers";

body {
    visibility: visible !important;
    min-width: 280px;
    background: $pOffWhite;
    font-weight: 300 !important;
    @include tomatoGroteskReg;
    @include base1-s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -.01em;
    color: $n7;
    @include dark-body {
        background: $n8;
        color: $pOffWhite; } }

a {
    text-decoration: none; }

svg,
img {
    vertical-align: middle; }


button {
    background: none; }

button,
input,
textarea {
    @include tomatoGrotesk; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }

.recharts-rectangle.recharts-tooltip-cursor {
    @include dark-common {
        fill: $n6; } }

.recharts-curve.recharts-tooltip-cursor {
    @include dark-common {
        stroke: $n5; } }

.recharts-sector {
    @include dark-common {
        stroke: $n7; } }

.dark-mode .recharts-responsive-container {
    .recharts-layer {
        text {
            fill: $pLightGrey !important; } } }

.recharts-responsive-container {
    .recharts-cartesian-grid {
        line {
            stroke: rgb(204, 204, 204) !important; } } }

.tri-state-checkbox::before,
.tri-state-checkbox::after {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translate(-50%, -50%);
  content: ''; }


.tri-state-checkbox::before {
  width: 24px !important;
  height: 24px !important;
  border: 1px solid $pBurgundy !important;
  outline: none;
  background-image: linear-gradient( to bottom, transparent, transparent ) !important;
  @include dark {
    background-image: linear-gradient( to bottom, $n6, $n6 ) !important;
    background: $n6 !important;
    border: 1px solid $shades3 !important; } }


.tri-state-checkbox:active::before {
  background: $pBurgundy !important; }


.tri-state-checkbox[aria-checked='mixed']::before {
    border-color: $pBurgundy !important;
    background: transparent !important;
    @include dark {
        &::before {
            border-color: $pDarkGrey !important; } } }


.tri-state-checkbox[aria-checked='true']::before {
  border-color: transparent !important;
  background-color: $pBurgundy !important;
  outline: none !important;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L12.1213 16.2929C10.9497 17.4645 9.05026 17.4645 7.87868 16.2929L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L9.29289 14.8787C9.68342 15.2692 10.3166 15.2692 10.7071 14.8787L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A") !important; }


.tri-state-checkbox[aria-checked='mixed']::after {
  background: transparent !important; }

.tri-state-checkbox[aria-checked='mixed']::after {
    display: none !important; }


.react-datepicker {
    padding: 24px;
    backdrop-filter: blur(32px);
    width: max-content;
    border-radius: 0px;
    .react-datepicker__navigation {
        top: 16px;
        &.react-datepicker__navigation--previous {
            left: 24px; }
        &.react-datepicker__navigation--next {
            right: 24px; }
        .react-datepicker__navigation-icon--next:before {
            left: -3px;
            top: 8px; }
        .react-datepicker__navigation-icon--previous:before {
            left: -6px;
            top: 8px; }
        &:hover {
            background: $n3;
            border-radius: 50%; } }

    .react-datepicker__month-container {
        margin-top: -14px;
        .react-datepicker__current-month {
            margin-bottom: 16px;
            padding-bottom: 24px;
            border-bottom: 1px solid $n3; } }

    .react-datepicker__header {
        background: transparent;
        border: none; }

    .react-datepicker__day--selected {
        background: #0063F3 !important; }
    .react-datepicker__day--today:hover {
        background: $pBurgundy; }
    .react-datepicker__triangle {
        display: none; }
    .react-datepicker-popper {
        padding-top: 0px; }
    .react-datepicker__day--keyboard-selected {
        background: transparent;
        color: #000; } }
.react-datepicker__portal {
    background: rgba(244, 244, 244, 0.8) !important;
    .react-datepicker__navigation {
        top: -3px; }
    .react-datepicker__navigation--previous {
            left: 0px !important; }
    .react-datepicker__navigation--next {
            right: 0px !important; } }

.dark-mode .react-datepicker {
    background: $n5 !important;
    .react-datepicker__navigation {
        &:hover {
            background: $n6; } }
    .react-datepicker__month-container {
        .react-datepicker__current-month {
            border-bottom: 1px solid $pDarkGrey; } }

    .react-datepicker__day {
        color: #fff; }
    .react-datepicker__day--outside-month {
        color: $pDarkGrey; }
    .react-datepicker__day--selected {
        background: $pBurgundy; }
    .react-datepicker__day--today:hover {
        background: $pBurgundy; }
    .react-datepicker__day--in-range {
        color: #fff !important; } }

.__react_modal_image__modal_content {
    background: rgba(244, 244, 244, 0.8); }
.dark-mode .__react_modal_image__modal_content {
        background: rgba(39, 43, 48, 0.9); }


.__react_modal_image__header {
    background: transparent !important;
    width: max-content !important;
    right: 16px !important;
    top:  8px !important; }

.__react_modal_image__icon_menu {
    display: flex !important; }

.__react_modal_image__icon_menu a {
    background: #272B30 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    height: 48px !important;
    width: 48px !important;
    &:last-child {
        transition: transform .2s !important;
        &:hover {
            transform: rotate(90deg); } } }

.dark-mode .__react_modal_image__icon_menu a {
    background: #1A1D1F !important; }

.react-datepicker__day--today {
    background: transparent !important;
    color: #000 !important; }

.dark-mode .react-datepicker__day--today {
    background: transparent !important; }

.react-datepicker__day--in-range {
    background: #0063F3 !important;
    color: #fff !important; }
.dark-mode .react-datepicker__day--in-range {
    background: #0063F3 !important; }

.react-datepicker__day--range-start {
    background-color: #0063F3 !important; }

.dark-mode .react-datepicker__day--range-start {
    background-color: #0063F3 !important; }

.react-datepicker__day--range-end.react-datepicker__day--in-range {
    background: #0063F3 !important; }

.dark-mode .react-datepicker__day--range-end.react-datepicker__day--in-range {
    background: #0063F3 !important; }

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover {
    background-color: #0063F3 !important; }


.dark-mode .react-datepicker .react-datepicker__day--selected {
    background: #0063F3 !important; }

.react-datepicker__month-text--keyboard-selected {
    background: $pBurgundy !important; }

.react-datepicker__month-text {
    padding: 4px !important;
    width: 5rem !important; }

.react-datepicker__month-text:hover {
    background: $n3  !important; }
.dark-mode .react-datepicker__month-text:hover {
    background: $n6  !important; }
.react-datepicker__month-text--today {
    color: #000 !important; }
.dark-mode .react-datepicker__month-text--today {
    color: #fff !important;
    background: transparent !important; }
.react-datepicker__month-text--today:hover {
    color: #000 !important; }
.dark-mode .react-datepicker__month-text--today:hover {
    color: #fff !important; }

.react-datepicker__month-text--today.react-datepicker__month-text--keyboard-selected {
    background: $pBurgundy !important;
    color: #fff !important; }

.dark-mode .react-datepicker .react-datepicker__header {
    color: $n3 !important; }

.react-datepicker.react-datepicker--time-only {
    padding: 4px 0; }

.form-wrap.form-builder .form-actions {
    display: none !important; }

.checkbox {
    .formbuilder-checkbox {
        label {
            margin-left: 8px; } } }

.dark-mode .form-wrap.form-builder .frmb-control li {
    background: transparent;
    &:hover {
        background: $n6; } }

.dark-mode .form-wrap.form-builder .frmb li.form-field {
    background: transparent; }

.dark-mode a[title="Remove Element"],
.dark-mode a[title="Edit"],
.dark-mode a[title="Copy"] {
    background: $n5 !important; }

.dark-mode .form-wrap.form-builder .stage-wrap.empty {
    background: transparent; }

.dark-mode .form-wrap.form-builder .frmb .prev-holder select,
.dark-mode .form-wrap.form-builder .frmb .prev-holder input[type=text],
.dark-mode .form-wrap.form-builder .frmb .prev-holder textarea,
.dark-mode .form-wrap.form-builder .frmb .prev-holder input[type=date],
.dark-mode .form-wrap.form-builder .frmb .prev-holder input[type=number] {
    background: $n5; }

.fc .fc-button-group {
    background: transparent !important;
    padding: 0 !important;
    border-radius: 0 !important; }

.fc .fc-button:focus,
.fc .fc-button-primary:focus {
    box-shadow: none  !important; }

.fc-direction-ltr .fc-button-group>.fc-button {
    padding: 0 !important; }

.fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
    margin-right: 8px;
    padding-right: 8px !important;
    &::after {
        position: absolute;
        height: 100%;
        width: 1px;
        background: #11131550;
        content: "";
        right: 0px;
        top: 0; } }

.fc .fc-button-group .fc-button-primary {
    background: transparent !important;
    color: $pGrey  !important;
    border: none !important;
    font-weight: 600 !important;
    &.fc-button-active {
        @include button1;
        background: $pOffWhite !important;
        border-radius: 0 !important;
        color: $pOrange !important;
        @include dark {
            background: $n5 !important;
            box-shadow: none !important;
            color: $pDarkGrey !important; } } }

.dark-mode .fc .fc-button-group .fc-button-primary {
    &.fc-button-active {
        background: none !important;
        box-shadow: none !important;
        color: $pDarkGrey !important; } }

.fc .fc-header-toolbar .fc-toolbar-chunk {
    &:nth-child(2) {
        display: flex;
        button {
            background: none;
            border: none;
            color: $pDarkGrey;
            &:focus {
                box-shadow: none !important; }
            .fc-icon.fc-icon-chevron-left {
                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.28161 14.5256L0.392401 7.63636L7.28161 0.747159L8.74467 2.19602L4.36257 6.57812H15.0089V8.6946H4.36257L8.74467 13.0696L7.28161 14.5256Z' fill='%231A1D1F'/%3E%3C/svg%3E%0A") !important; } }
            .fc-icon.fc-icon-chevron-right {
                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.72727 14.5256L6.2642 13.0767L10.6463 8.6946H0V6.57812H10.6463L6.2642 2.20312L7.72727 0.747159L14.6165 7.63636L7.72727 14.5256Z' fill='%231A1D1F'/%3E%3C/svg%3E%0A") !important; } } } }
    &:nth-child(3) {
        opacity: 0 !important; }
    .fc-toolbar-title {
        font-weight: 300; } }
.dark-mode .fc .fc-header-toolbar .fc-toolbar-chunk {
    &:nth-child(2) {
        button {
            .fc-icon.fc-icon-chevron-left {
                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.28161 14.5256L0.392401 7.63636L7.28161 0.747159L8.74467 2.19602L4.36257 6.57812H15.0089V8.6946H4.36257L8.74467 13.0696L7.28161 14.5256Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A")!important; } }
            .fc-icon.fc-icon-chevron-right {
                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.72727 14.5256L6.2642 13.0767L10.6463 8.6946H0V6.57812H10.6463L6.2642 2.20312L7.72727 0.747159L14.6165 7.63636L7.72727 14.5256Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A")!important; } } } } }

.css-1okebmr-indicatorSeparator {
    display: none; }
span.css-1okebmr-indicatorSeparator {
    background-color: transparent !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    transition: background-color 9999s ease-in-out 0s; }

.react-tel-input .form-control,
.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus,
.react-tel-input .country-list {
    border-radius: 0 !important;
    background: #E9E7E0; }

.dark-mode {
    .react-tel-input .form-control,
    .react-tel-input .flag-dropdown.open .selected-flag,
    .react-tel-input .flag-dropdown,
    .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus,
    .react-tel-input .country-list {
        background: $n6 !important;
        color: $n3;
        border-radius: 0 !important; } }

.dark-mode {
    .react-tel-input .country-list .country.highlight,
    .react-tel-input .country-list .country:hover {
        background: $n7 !important;
        color: $n3; } }
