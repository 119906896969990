[class^="title"] {
    position: relative;
    display: inline-block;
    padding-left: 32px;
    @include title1-s;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 32px;
        transform: translateY(-50%); } }


.title-red {
    &:before {
        background: $pOrange; } }

.title-blue {
    &:before {
        background: $pBurgundy; } }

.title-purple {
    &:before {
        background: $s2; } }

.title-yellow {
    &:before {
        background: $p2; } }

.title-green {
    &:before {
        background: $pBurgundy; } }
.title-secondary-03 {
    &:before {
        background: #b1e5fc; } }
.title-secondary-04 {
    &:before {
        background: #B5E4CA; } }
.title-secondary-05 {
    &:before {
        background: #FFD88D; } }
