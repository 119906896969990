@import '../../../styles/helpers';

.row {
    width: 100%;
    display: flex;
    gap: 12px;
    @include t {
        display: block; } }

.col {
    flex: 0 0 calc(33.333% - 8px); }


.input {
    margin-bottom: 12px;
    &.city {
        margin-top: 38px;
        @include t {
            margin-top: 0; } } }

.title {
    @include base1-s;
    color: $n5;
    fill: $n5;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .backBtn {
        cursor: pointer; } }

.dropdown {
    width: max-content; }

.singleCard {
    .label {
        @include base1-s;
        color: $pDarkGrey;
        margin: 16px 0; }
    .flex {
        border: 2px solid $n3;
        display: flex;
        gap: 8px;
        padding: 12px 20px;

        margin-bottom: 12px;
        h3 {
            font-size: 15px;
            font-weight: 700;
            color: $n7; }
        p {
            @include caption2-m;
            color: $pDarkGrey; } } }

.actionCard {
    margin-top: 20px;
    display: flex;
    gap: 8px; }
