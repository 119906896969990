@import "../../../../styles/helpers";

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        display: block;
        margin: 24px 0 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    span {
        @include dark {
            color: $n3; } }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px; } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    fill: $pDarkGrey; }

.left {
    display: flex;
    gap: 12px;
    .pic {
        flex: none !important;
        width: 48px;
        height: 48px;
        object-fit: contain;
        object-position: center;
        background: transparent; }
    h3 {
        font-size: 16px;
        font-weight: 600;
        color: #272B30;
        @include dark {
            color: $shades1; } }
    h2 {
        font-size: 16px;
        font-weight: 500;
        color: #272B30;
        @include dark {
            color: $shades1; } }
    p {
        @include base1-s;
        color: $pDarkGrey; }
    .date {
        color: $pBurgundy;
        margin-bottom: 12px; } }

.outer {
    max-width: 408px; }

.title {
    @include title1-s;
    color: $n5;
    margin-bottom: 20px; }

.modalWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 56px; }

.imgWrap {
    width: 128px;
    height: 128px;
    background: $pOrange;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px; } }

.dateInput {
    color: $pDarkGrey !important; }

.updateBtn {
    position: absolute;
    top: 24px;
    right: 24px;
    @include m {
        position: unset;
        top: 16px;
        right: 16px; } }
