@import "../../../styles/helpers";

.card {
    padding: 24px 12px 12px;
    width: 345px !important;
    @include t {
        width: unset !important; } }

.emptyCard {
    padding: 24px 12px 12px;
    width: 345px !important;
    min-height: 200px;
    @include t {
        width: unset !important; } }

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 24px;
    @include caption1;
    color: $pDarkGrey;
    @include dark {
        border-color: $n6; } }

.cardTitle {
    @include caption1;
    color: $pDarkGrey;
    margin-bottom: 12px; }

.defaultCard {
    background-color: $n6;
    padding: 16px;

    max-width: 360px;
    margin-bottom: 20px;
    cursor: pointer; }

.defaultCard {
    background-color: $n6;
    padding: 16px;
    border-radius: 8px;

    max-width: 360px;
    margin-bottom: 20px;
    cursor: pointer; }

.otherCardsWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    .singleCard {
        display: flex;
        align-items: center;
        gap: 12px;
        &:not(:last-child) {}
        margin-bottom: 12px;
        cursor: pointer;
        .creditCard {
            width: 94px;
            height: 64px;
            &.purple {
                background: #CABDFF; }
            &.green {
                background: #B5E4CA; } }
        .cardInfo {
            .name {
                @include caption1;
                color: $pDarkGrey; }
            .number {
                font-size: 24px;
                font-weight: 600;
                color: $n7;
                @include dark {
                    color: $shades1; } } } } }

.cardHead {
    a {
        @include dark {
            fill: $n; } } }
