@import "../../../../styles/helpers";

.container {
    margin-top: 36px; }

.title {
    color: $pOrange;
    font-weight: bold;
    font-size: 18px; }

.label {
    margin-top: 12px;
    margin-bottom: 8px;
    @include base2;
    color: $pGrey;
    @include dark {
        color: $n3; } }

.offerSelectionContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; }

.offer {
    height: 100px;
    width: 200px;
    border: 2px solid $pDarkGrey;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    cursor: pointer; }

.selectedOffer {
    height: 100px;
    width: 200px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    cursor: pointer;
    color: #FFF;
    background-color: $pOrange; }

.detailContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column; }

.disclaimer {
    font-size: 18px;
    font-weight: bold;
    color: $pDarkGrey; }

.disclaimerDetail {
    font-size: 14px;
    color: $pDarkGrey; }

.paymentPlanContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 24px; }

.paymentPlan {
    height: 320px;
    width: 115px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    border: 1px dotted $pDarkGrey; }

.flexColumn {
    display: flex;
    flex-direction: column; }

.daySale {
    margin-top: 50px;
    color: $pGrey; }

.day {
    font-weight: 300;
    margin-top: 8px; }

.totalSale {
    font-weight: 300;
    margin-top: 12px; }

.graph1 {
    width: 30px;
    background-color: $pOrange; }

.graph2 {
    width: 30px;
    background-color: $pGrey; }
