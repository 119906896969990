@import "../../../styles/helpers";

.card {
    padding: 0; }

.addNewBtn {
    position: absolute;
    right: 24px;
    top: 24px;
    @include m {
        top: 16px;
        right: 16px; } }

.checklistWrap {
    display: table;
    width: 100%;
    .checklistHead {
        display: table-row;
        @include base1-s;
        color: $pDarkGrey;
        p {
            display: table-cell;
            padding: 12px;
            &:last-child {
                width: 48px; } } } }

.foot {
    padding: 12px 0;
    text-align: center;
    float: right;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
