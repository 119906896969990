@import "../../../../styles/helpers";

.comments {
    padding: 24px;
    border-left: 1px solid $n3;
    @include m {
        padding: 16px 16px 24px; }
    @include dark {
        border-color: $n8; } }

.head {
    display: flex;
    align-items: center;
    height: 48px; }

.title {
    display: flex;
    align-items: center;
    margin-right: auto;
    @include title1-s; }

.counter {
    flex-shrink: 0;
    min-width: 38px;
    margin-right: 12px;
    padding: 0 6px;
    background: $s2;
    text-align: center;
    line-height: 36px;
    @include dark {
        color: $n7; } }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    svg {
        fill: $n5;
        transition: fill .2s;
        @include dark {
            fill: $pOffWhite; } }
    &:hover {
        svg {
            fill: $pBurgundy; } } }

.editor {
    margin-top: 24px; }

.list {
    margin-top: 40px;
    @include m {
        margin-top: 24px; } }

