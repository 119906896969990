@import "../../../../styles/helpers";

.contentContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    button {
        width: 184px; } }

.disclaimer {
    color: $pDarkGrey;
    margin-top: 24px;
    font-size: 16px; }

.disclaimerTop {
    color: $pDarkGrey;
    margin-top: 4px;
    font-weight: bold;
    font-size: 16px; }

.applicationContainer {
    margin-top: 32px; }

.inputContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px; }

.linkedAccounts {
    color: $pGrey;
    margin-top: 36px; }

.singleInput {
    width: 330px; }

.activeInputClass {
    pointer-events: none; }

.checkboxContainer,
.documentsContainer {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column; }

.label {
    margin-bottom: 8px;
    @include base2;
    color: $pGrey;
    @include dark {
        color: $n3; } }

.checkbox {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: max-content;
    pointer-events: none; }

.checkboxTitle {
    font-weight: 400;
    color: $pDarkGrey; }

.accounts {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 8px; }

.activeItem {
    background-color: $pOrange;
    color: white !important;
    border: unset !important; }

.item {
    margin-bottom: 4px;
    border: 1px solid grey;
    border-radius: 0 !important;
    padding: 12px !important;
    color: $pDarkGrey;
    &.activeItem0 {
        background: $pOrange;
        color: #FFFDF9 !important; }
    &.activeItem1 {
        background: $pLightGrey; }
    &.activeItem2 {
        background: $pApricot; }
    &.activeItem3 {
        background: $pPink; }
    &.activeItem4 {
        background: $pPink; }
    &.activeItem5 {
        background: $pPink; }
    &.activeItem6 {
        background: $pPink; }
    &.activeItem7 {
        background: $pPink; }
    &.activeItem8 {
        background: $pPink; } }

.spinner {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center; }

.warning {
    width: 272px;
    background-color: $pOrange;
    color: white;
    padding: 12px;
    margin-top: 4px;
    margin-bottom: 4px; }
