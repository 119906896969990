@import "../../../../styles/helpers";

.cardWrap {
    margin-bottom: 24px;
    padding: 0; }

.dropdown {
    margin-bottom: 5px; }

.card {
    padding: 0;
    margin-bottom: 24px; }

.tooltip {
    fill: $pDarkGrey; }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 10px;
    @include dark {
        color: $pDarkGrey; } }

.outer {
    h3 {
        @include title1-s;
        color: $n5;
        margin-bottom: 8px; }
    p {
        @include caption1;
        color: 4n4;
        margin-bottom: 24px; }
    .input {
        margin-bottom: 16px; }
    .button {
        width: 100%;
        margin: 8px 0 24px; } }


.wrapper {
    margin: 0;
    background: $pOffWhite;
    @include t {
        padding-bottom: 36px; }
    @include m {
        margin: 0; }
    @include dark {
        background: transparent; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 24px; }
    @include m {
        padding: 16px; }
    &:first-child {
        @include t {
            display: none; }
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include base1-s;
            color: $pGrey; } }
    &:nth-child(2n) {
        @include t {
            background: transparent; }
        .col {
            background: transparent;
            @include t {
                background: none; } } } }

.col {
    display: table-cell;
    padding: 10px 12px;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0; }
    &:first-child {
        padding-left: 0px;
        @include t {
            padding-left: 0; } }
    &:last-child {
        padding-right: 0px;
        text-align: end;
        @include t {
            padding-right: 0;
            padding-bottom: 24px;
            border-bottom: 1px solid #1113151f; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } }
    p {
        &:first-child {
            display: none;
            color: $pDarkGrey;
            @include t {
                display: flex; } } } }


.switch {
    margin-top: 4px;
    margin-right: 16px;
    cursor: default; }
