@import "../../../styles/helpers";

.control {
    display: flex;
    align-items: center; }

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $pOffWhite;
    svg {
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $pBurgundy; } }
    &:not(:last-child) {
        margin-right: 16px; } }


