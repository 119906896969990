@import "../../../styles/helpers";

.agreementWrap {
    max-width: 1240px;
    margin: 100px auto 48px;
    @include title1-s;
    @media screen and (max-width: 1288px) {
        margin: 100px 24px 48px; }

    .head {
        text-transform: uppercase;
        color: $n7;
        @include dark {
            color: $n3; } }
    .para {
        .title {
            margin-top: 16px;
            text-transform: uppercase;
            @include title1-s;
            color: $n7;
            @include dark {
                color: $n3; } } } }

.signedWrap {
    margin-top: 24px; }

.signedBtn {
    background: $pBurgundy !important;
    &:hover {
        background: $pBurgundy !important; } }
.signature {
    input {
        border: 1px solid lightgray;
        font-family: 'SignaturaMonoline';
        font-size: 24px; } }
.row {
    width: 100%; }
.signRow {
    max-width: 550px;
    margin-bottom: 25px; }

.signSignature {
    border-bottom: 1px dashed black;
    font-family: 'SignaturaMonoline';
    font-size: 48px;
    font-weight: 600; }

.singleData {
    margin-top: 12px;
    border-bottom: 1px solid $shades3;
    padding-bottom: 4px;
    .dataTitle {
        color: $pDarkGrey;
        @include base1-s; }
    p {
        color: $n7;
        @include base1-s;
        @include dark {
            color: $n3; } } }
