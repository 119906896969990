@import "../../../styles/helpers";

.card {
    position: fixed;
    left: 0px;
    width: 26%;
    height: 100vh;
    @include t {
        height: max-content;
        position: unset;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }

.detailContainer {
    margin-left: 41%;
    display: flex;
    overflow-y: auto;
    width: 64%;
    margin-bottom: 24px;
    @include t {
        margin-left: 0%;
        width: 100%; } }

.mainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden; }

.logo {
    display: inline-block;
    margin-top: 24px;
    z-index: 2;
    width: 224px;
    img {
        width: 100%; }
    @include t {
        width: 150px; } }

.text {
    width: 80%;
    margin-top: 84px;
    color: var(--primary-dark-grey, #464542);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 160% */;
    letter-spacing: -0.4px;
    a {
        text-decoration: underline;
        color: unset; }
    @include t {
        margin-top: 8px;
        text-align: center; } }

.rowButton {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 24px;
    @include t {
        margin-top: 8px; } }

.signupButton {
    margin-top: 24px;
    @include t {
        margin-bottom: 8px; } }

.detailCard {
    width: 80%;
    margin-top: 24px;
    @include t {
        width: 100%; } }

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px; }

.companyCard {
    margin-top: 24px; }

