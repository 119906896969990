@import "../../../../styles/helpers";

.col {
    position: relative;
    width: 100%;
    max-width: 340px;
    @include m {
        left: 50%;
        transform: translateX(-50%); } }

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

.pic {
    width: 228px;
    height: 228px;
    margin-top: -32px;
    margin-left: -16px; }

.txtWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 12px;
    gap: 12px;
    h3 {
        @include title1-s;
        color: $n5;
        @include dark {
            color: $pDarkGrey; } }
    p {
        @include caption2-m;
        color: $pDarkGrey; }
    .button {
        color: $n;
        margin-top: 8px; } }

.addIcon {
    margin-left: 0 !important; }
