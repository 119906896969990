// common styles
@import "./reset";
@import "./helpers";
@import "./common";

// global classes
@import "./blocks/container";
@import "./blocks/headers";
@import "./blocks/button";
@import "./blocks/status";
@import "./blocks/title";
@import "./blocks/panel";
@import "./blocks/datepicker";
@import "./blocks/slick";
@import "./blocks/editor";
@import "./blocks/reactTags";


.emailModal::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent; }

.emailModal::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0px; }

.emailModal::-webkit-scrollbar {
  width: 0em;
  border-radius: 0px; }

.lendersChat::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent; }

.lendersChat::-webkit-scrollbar-thumb {
  background-color: lightGrey;
  border-radius: 5px; }

.lendersChat::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px; }

.rendered-form input {
  background-color: #fff;
  border: 1px solid #ccc; }

.rendered-form button {
  background: $pBurgundy !important;
  color: $pOffWhite; }

.react-datepicker__month {
    height: max-content !important; }

.phoneCode {
    border: none;
    background-color: #F4F4F4;
    width: 50px;
    cursor: pointer; }

.css-1e1wqi0-placeholder,
.css-1uccc91-singleValue,
.css-1wa3eu0-placeholder {
    font-family: 'tomatoGrotesk', sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 1.6 !important;
    color: #464542 !important;
    transition: all .2s !important; }

.drop > div {
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  color: #1A1D1F;
  border: unset;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  transition: all .2s;
  background: rgb(244, 244, 244); }

.dark-mode .drop > div {
  background: $n6;
  color: $n !important; }


.react-datepicker__month {
    height: 240px !important; }

.notHover {
    position: absolute;
    zIndex: 99999; }

.explainerHover1:hover > div {
  display: block !important;
  margin-left: -440% !important; }

.explainerHover2:hover > div {
  display: block !important;
  margin-top: -40% !important;
  margin-left: -60% !important;
  width: 100% !important; }

.explainerHover3:hover > div {
  display: block !important;
  margin-top: 20% !important;
  margin-left: 42% !important; }

.explainerHover4:hover > div {
  display: block !important;
  margin-left: 42% !important;
  margin-top: 30% !important; }

.explainerHover5:hover > div {
  display: block !important;
  width: 120% !important;
  margin-top: 45% !important; }

.explainerHover6:hover > div {
  display: block !important;
  margin-left: 75% !important;
  margin-top: 5% !important; }

.explainerHover7:hover > div {
  display: block !important;
  margin-left: 70% !important;
  margin-top: 20% !important; }

.react-tel-input .flag-dropdown {}


.css-1okebmr-indicatorSeparator {
  visibility: hidden; }

.scroll::-webkit-scrollbar {
  width: 7px; }

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c3a164; }

.scroll::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #c3a164; }


.cr-1 > ul {
  transform: translate3d(0px, 0px, 0px) !important; }

.cr-2 > ul {
  transform: translate3d(0px, 0px, 0px) !important; }


.react-datepicker-popper {
  z-index: 15 !important; }

.input-wrap {
  color: $pDarkGrey; }

.html2canvas-container { width: 2000px !important;};

#react-select-3-input {
  line-height: 35px;
  font-weight: 600; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type=number] {
    -moz-appearance:textfield {} }

textarea {
  resize: vertical;
  padding-top: 8px !important; }

.react-kanban-board {
  padding: 0px; }

.react-kanban-column {
  background-color: #00000005 !important;
  min-height: 450px !important;
  min-width: 320px !important;
  margin: 0px;
  margin-right: 8px; }

.react-kanban-column > div:first-of-type {
  margin-bottom: 10px; }
