@import "../../styles/helpers";

.headerButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px; }

.line {
    margin-top: 4px;
    width: 100%;
    height: 2px;
    background-color: $pOrange; }

.notes {
    margin-top: 24px;
    width: 30%;
    @include m {
        width: 100%; } }

.description {
    width: 100%;
    padding: 0 10px;
    border: 2px solid $pGrey;
    background: #F4F4F4;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #1A1D1F;
    transition: all .2s; }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px;
        @include dark {
            background: $n6; } } }
