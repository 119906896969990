@import "../../../../styles/helpers";

.panel {
    padding: 24px;
    background: $n2;
    @include t {
        background: none;
        box-shadow: inset 0 -1px 0 0 $n3; }
    @include m {
        padding: 16px; }
    @include dark {
        background: $n6;
        @include t {
            background: none;
            box-shadow: inset 0 -1px 0 0 $n6; } } }

.line {
    display: flex;
    align-items: center; }

.titleWrap {
    margin-right: auto;
    @include title1-s; }
.renameWrap {
    margin-top: 56px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h3 {
        @include title1-s;
        color: $n5; }
    input {
        @include base1-s;
        background: transparent;
        border: .5px solid $n5;
        color: $n5;
        padding: 8px 12px; }

    button {
        position: relative;
        align-self: flex-end; } }

.close {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 12px;
    @include t {
        display: inline-block; }
    svg {
        fill: $pDarkGrey; }
    @include dark {
        @include t {
            background: $n6; } } }

.parameters {
    display: flex;
    flex-direction: column;
    gap: 12px; }

.parameter {
    @include caption1;
    font-weight: 500;
    color: $pDarkGrey;
    span {
        font-weight: 700;
        color: $n5;
        @include dark {
            color: $pOffWhite; } }
    &:not(:first-child) {
        position: relative;
        padding-left: 14px;
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 2px;
            height: 12px;

            background: rgba($pDarkGrey, .51); } } }

.actionsHead {
    background: $pOffWhite !important;
    @include dark {
        background: $shades4 !important;
        @include t {
            background: $n6 !important; } } }

.actionsBody:before {
    @include dark {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E");
        @include t {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E"); } } }

.selectedImageContainer {
    display: flex;
    align-items: center;
    gap: 16px; }

.selectedImageWrap {
    position: relative;
    width: 100px;
    height: 100px;
    @include m {
        height: 80px;
        width: 80px; }
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }
