@import "../../../../../styles/helpers";

.outer {
    max-width: 550px; }

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px 16px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 8px;
    @include dark {
        color: $pDarkGrey; } }
