@import "../../../../../styles/helpers";

.card {
    padding: 0 0 24px; }

.wrapper {
    margin: 0 -12px;
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.nodata {
    position: absolute;
    padding-top: 20px;
    text-align: center;
    width: 100%; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.loadMore {
    float: right;
    margin-top: 30px;
    width: 150px;
    height: 50px;
    border: 1px solid gray;

    @include dark {
        color: $shades1; } }

.editOverlayHead {
    background: red;
    position: absolute; }

.row {
    display: table-row;
    &.active {
        .col {
            &:not(:first-child):not(:nth-child(2)) {
                display: none; }
            &:nth-child(2) {
                &:after {
                    right: 12px; } } } } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include t {
            height: 0; }
        @include dark {
            background: $n6; } }
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; }

        @include t {
            padding-left: 26px;
            height: 40px; }

        @include m {
            padding-left: 18px;
            height: 48px; } }

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(2)) {
        @include t {
            display: none; } } }


.nav {
    display: flex;
    align-items: center;
    gap: 12px;
    grid-gap: 12px;
    margin-bottom: 24px; }

.typeNav {
    display: flex;
    align-items: center;
    width: max-content;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } }
    @include t {
        display: none; } }

.filterBtn,
.downloadBtn {
    margin-right: 8px;
    fill: $pDarkGrey;
    svg {
        fill: $pDarkGrey; } }

.foot {
    margin-top: 20px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }


.btnClass {
    color: $pOrange;
    display: flex;
    justify-content:flex-start {}
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: all .2s;
    &:hover {
        background: #f4f4f4;
        @include dark {
            background: $n6; } }


    .btnIcon {
        margin-right: 16px; } }

.triHead {
    margin-left: 2px;
    position: relative; }

.tri-state-checkbox:focus::before {
    box-shadow: none !important; }

.tri-state-checkbox[aria-checked='mixed']::before,
.tri-state-checkbox[aria-checked='false']::before {
    @include dark {
        background: $pDarkGrey !important; } }

.triCheckbox[type="checkbox"],
.triCheckboxImg[type="checkbox"] {
    margin-left: -5px; }

.triCheckbox[type="checkbox"]:before,
.triCheckboxImg[type="checkbox"]:before {
    position: absolute;
    background: $n;
    margin-top: -1px;
    margin-left: -1px;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:after,
.triCheckboxImg[type="checkbox"]:after {
    position: absolute;
    content: '';
    margin-top: -1px;
    margin-left: -1px;
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }


.triCheckbox[type="checkbox"]:checked:after,
.triCheckboxImg[type="checkbox"]:checked:after {
    position: absolute;
    padding: 4px 3px;
    background: $pBurgundy !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") !important; }

.triCheckboxImg {
    margin-top: 12px; }
