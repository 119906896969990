@import "../../../../styles/helpers";

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        display: block;
        margin: 24px 0 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 calc(100% - 16px);
        width: calc(100% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    span {
        @include dark {
            color: $n3; } }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px; } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.switchWrap {
    display: flex;
    gap: 24px; }

.col12 {
    margin-top: 24px !important;
    margin-bottom: 0px !important; }

.actionBtnWrap {
    display: flex;
    gap: 20px; }
