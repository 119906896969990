@import "../../../styles/helpers";

.readIcon {
    svg {
        fill: $pDarkGrey;
        @include dark {
            fill: $pGrey; } } }

.unReadIcon {
    svg {
        fill: $pDarkGrey;
        @include dark {
            fill: $pGrey; } } }
