@import "../../../../../styles/helpers";

.search {
    position: relative; }

.card {
    padding: 12px; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.backBtn {
    cursor: pointer; }
