@import "../../../../styles/helpers";

.documentWrapper {
    margin: 0 -20px;
    background: $pOffWhite;
    @include m {
        margin: 0 -16px; }
    @include dark {
        background: transparent; } }

.card {
    padding: 0; }

.wrapper {
    margin: 0;
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    &.active {
        .col {
            &:not(:first-child):not(:nth-child(2)) {
                display: none; }
            &:nth-child(2) {
                &:after {
                    right: 12px; } } } } }

.col {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 12px;
    @include base1-s;
    color: $pGrey;
    &.typeNumber {
        text-align: end; }
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; }

        @include t {
            padding-left: 26px;
            height: 40px; }

        @include m {
            padding-left: 18px;
            height: 48px; } }

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(2)) {
        @include t {
            display: none; } } }

.editOverlayHead {
    background: red;
    position: absolute; }


.foot {
    margin-top: 20px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }


.btnClass {
    color: $pOrange;
    display: flex;
    justify-content:flex-start {}
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: all .2s;
    &:hover {
        background: #f4f4f4;
        @include dark {
            background: $n6; } }


    .btnIcon {
        margin-right: 16px; } }

.triHead {
    margin-left: 2px;
    position: relative; }

.tri-state-checkbox:focus::before {
    box-shadow: none !important; }

.tri-state-checkbox[aria-checked='mixed']::before,
.tri-state-checkbox[aria-checked='false']::before {
    @include dark {
        background: $pDarkGrey !important; } }

.triCheckbox[type="checkbox"],
.triCheckboxImg[type="checkbox"] {
    margin-left: -2px;
    margin-top: -2px; }

.triCheckbox[type="checkbox"]:before,
.triCheckboxImg[type="checkbox"]:before {
    position: absolute;
    background: $n;
    margin-top: -3px;
    margin-left: -1px;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:after,
.triCheckboxImg[type="checkbox"]:after {
    position: absolute;
    content: '';
    margin-top: -3px;
    margin-left: -1px;
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }


.triCheckbox[type="checkbox"]:checked:after,
.triCheckboxImg[type="checkbox"]:checked:after {
    position: absolute;
    padding: 4px 3px;
    background: $pBurgundy !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") !important; }

.triCheckboxImg {
    margin-top: 12px; }
