@import "../../../styles/helpers";

.nav {
    display: flex;
    align-items: center;
    gap: 12px;
    grid-gap: 12px;
    margin-bottom: 24px;
    @include dark {
        fill: $pDarkGrey; }
    .active {
        fill: $pBurgundy; } }

.icon {
    cursor: pointer; }

.typeNav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 380px;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    @include s {
        width: max-content;
        padding: 4px; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        @include t {
            padding: 4px 8px; }
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.pageTitle {
    margin-right: 16px;
    color: $n6;
    @include dark {
        color: $pDarkGrey; } }

