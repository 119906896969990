@import "../../../../styles/helpers";

.contentContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    button {
        width: 184px; } }

.disclaimer {
    color: $pDarkGrey;
    margin-top: 24px;
    font-size: 16px; }
