@import "../../../styles/helpers";

.outerModal {
    min-width: 540px; }
.linkChat {
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    color: $pDarkGrey;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include d {
        width: 48px;
        font-size: 0; }
    @include m {
        width: 100%;
        font-size: 13px; }
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 12px; } }
    &:hover,
    &.active {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        @include dark {
            color: $pOffWhite;
            svg {
                fill: $pOffWhite; } } } }



.linkNotification {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 0 12px;
    gap: 11px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    color: $pDarkGrey;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include dark {
        color: $pOffWhite;
        svg {
            fill: $pOffWhite; } }
    @include m {
        width: 100%;
        font-size: 13px; }
    svg {
        margin-right: 2px;
        fill: $pDarkGrey;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 2px; } }
    &:hover,
    &.active {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        @include dark {
            color: $pOrange;
            svg {
                fill: $pOrange; } } } }

.messages {
    position: relative;
    @include m {
        position: static; }
    &.active {
        .head {
            svg {
                fill: $n7;
                @include dark {
                    fill: $pOffWhite; } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    position: relative;
    width: 48px;
    height: 48px;
    svg {
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n7;
            @include dark {
                fill: $pOffWhite; } } }
    &.active {
        &:before {
            content: "";
            position: absolute;
            top: 10px;
            right: 10px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid $n;
            background: $pOrange;
            @include dark {
                border-color: $n7; } } } }

.body {
    position: absolute;
    top: calc(100% + 18px);
    right: -12px;
    width: 392px;
    padding: 12px 24px 24px;

    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    border: 1px solid transparent;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include x {
        top: calc(100% + 15px); }
    @include m {
        top: 100%;
        right: 16px;
        left: 16px;
        width: auto;
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 26px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include m {
            right: 165px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.top {
    display: flex;
    align-items: center;
    margin: 0 -12px 12px 0;
    @include m {
        margin-right: -6px; } }

.title {
    margin-right: auto;
    @include title1-s; }

.actions {
    .actionsHead {
        background: $n3;
        svg {
            fill: $n7; }
        @include dark {
            background: $n6;
            svg {
                fill: $pOffWhite; } } } }

.list {
    margin: 0 -12px;
    @include m {
        margin: 0 -8px; } }

.button {
    width: 100%;
    margin-top: 24px; }

.removeBtn {
        fill: $pOrange;
        cursor: pointer;
        margin-top: 10px; }
