@import "../../styles/helpers";

.outer {
    max-width: 700px; }

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    @include dark {
        margin-bottom: 24px; } }

.imgWrap {
    width: 128px;
    height: 128px;
    background: $pBurgundy;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px; } }

.title {
    @include title1-s;
    color: $n5;
    @include dark {
        color: $pDarkGrey; } }

.subName {
    font-size: 32px;
    font-weight: 600;
    color: $n7;
    line-height: 48px;
    text-align: center;
    @include dark {
        color: $n3; } }

.subDate {
    color: $n7;
    text-align: center;
    @include dark {
        color: $n3; } }

.email {
    @include base1-s;
    color: $pDarkGrey;
    text-align: center;
    @include dark {
        color: $pOffWhite; }
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }

.emailText {
    @include base1-s;
    color: $pGrey;
    text-align: center;
    @include dark {
        color: $pLightGrey; }
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }
