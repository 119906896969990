@import "../../../styles/helpers";

.addNewBtn {
    position: absolute;
    top: 24px;
    right: 24px;
    @include m {
        top: 16px;
        right: 16px; } }

.foot {
    padding: 12px 0;
    text-align: center;
    float: right;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.embeddedFormWrap {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    @include x {
        flex-direction: column; }
    .left {
        flex: 0 0 100%;
        .top {
            display: flex;
            gap: 12px;
            .input {
                flex-grow: 1; } } }
    .right {
        flex: 0 0 calc(70% - 6px);
        margin-top: 16px;
        padding: 8px 12px;
        background: $n2;
        @include dark {
            background: transparent; } } }

.embeddedForm {
    li[type = "button"] {
        display: block;
        text-align: left;
        button {
            background: $pBurgundy;
            color: $pOffWhite; } } }

.embeddedFormsWrap {
    width: 100%;
    display: table;
    .embeddedFormHead {
        display: table-row;
        @include base1-s;
        color: $pGrey;
        p {
            display: table-cell;
            padding: 12px;
            &:last-child {
                width: 48px; } } }
    .embeddedFormBody {
        background: $n2;
        @include dark {
            background: $n5; } } }
