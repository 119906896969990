@import "../../styles/helpers";

.card {
    min-height: 400px; }
.head {
    @include a {
        flex-wrap: wrap; } }
.pageTitle {
    margin-bottom: 24px; }

.button {
    margin-left: 16px;
    .ac {}
    @include m {
        flex-grow: 1; } }

.typeNav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
    @include t {
        display: flex; }
    .item {
        position: relative;
        @include base1-s;
        color: $pGrey;
        cursor: pointer;
        text-align: center;
        &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            &::after {
                position: absolute;
                right: 0;
                height: 100%;
                width: 1px;
                background: #11131550;
                content: "";
                @include dark {
                    background: $pDarkGrey; } } }
        &.active {
            @include button1;
            color: $pOrange;
            @include dark {
                color: $pOffWhite;
                box-shadow: none; } } } }

.buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include a {
        gap: 8px;
        justify-content: center; } }

.nodata {
    position: absolute;
    padding-top: 20px;
    text-align: center;
    width: 100%; }

.transactionDropdown {
    display: none;
    @include t {
        display: block; } }

.Approved {}
.posted {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }

.Declined {}
.void {
    padding: 0 8px;
    @include base1-s;
    background: rgba(235,61,52,.28);
    color: $pOrange; }

.pending {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }

.open {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }

.draft {
    background: $n3;
    padding: 0 8px;
    @include base1-s;
    color: $pDarkGrey; }

.transactionToggle {
    display: flex;
    align-items: center;
    gap: 16px;
    fill: $pDarkGrey;
    .toggleButton {
        @include base1-s;
        color: $pDarkGrey;
        padding: 8px 5px;
        &.active,
        &:hover {
            background: $n3;
            color: $n7;
            @include dark {
                background-color: $n5;
                color: $shades1; } } }
    @include t {
        display: none; } }

.inputValue {
    display: none !important; }

.input {
    padding-left: 48px !important;
    background: transparent !important;
    cursor: pointer;
    color: $pDarkGrey;
    width: 270px;
    @include dark {
        color: $shades1; }
    @include t {
        width: 48px;
        padding-left: 34px !important; } }

.lastRefresh {
    @include caption1;
    color: $pDarkGrey;
    h5 {
        font-weight: 600;
        font-size: 16px;
        color: $n7;
        @include dark {
            color: $n; }
        @include s {
            font-size: 36px; } } }


.balanceWrapper {
    .left {
        display: flex;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        @include s {
            gap: 16px; }

        p {
            @include caption1;
            color: $pDarkGrey; }

        h2 {
            font-weight: 600;
            font-size: 48px;
            color: $n7;
            @include dark {
                color: $n; }
            @include s {
                font-size: 36px; } }

        .dropdown {
            color: $n3; } } }

.wrapper {
    margin: 0 -20px;
    background: $pOffWhite;
    @include m {
        margin: 0 -16px; }
    @include dark {
        background: transparent; } }

.table {
    display: table;
    width: 98%;
    margin-left: 1%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 24px; }
    @include m {
        padding: 16px; }
    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } }
    &:first-child {
        @include t {
            display: none; }
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include base1-s;
            color: $pGrey; } }
    &:nth-child(2n) {
        @include t {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .5); } }
        .col {
            @include t {
                background: none; }
            @include dark {
                @include t {
                    background: none; } } } } }

.rowWithoutHover {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 24px; }
    @include m {
        padding: 16px; }
    &:first-child {
        @include t {
            display: none; }
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include base1-s;
            color: $pGrey; } }
    &:nth-child(2n) {
        @include t {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .5); } }
        .col {
            @include t {
                background: none; }
            @include dark {
                @include t {
                    background: none; } } } } }

.col {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        padding: 0; }
    &:first-child {
        padding-left: 16px;
        @include t {
            padding-left: 0; } }
    &:nth-child(2) {
        @include t {
            align-items: flex-start;
            order: -1;
            padding-bottom: 12px;
            border-bottom: 1px solid $n3;
            @include dark {
                border-color: $n6; } } }
    &:nth-child(3) {
        @include t {
            display: none; } }
    &:last-child {
        padding-right: 24px;
        @include t {
            padding-right: 0; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } } }

.status {
    flex-shrink: 0;
    white-space: nowrap;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: $n7; }

.details {
    display: none;
    @include t {
        display: block;
        margin-right: auto;
        padding-right: 16px; } }

.product {
    color: $n5;
    @include dark {
        color: $n3; } }

.invoice {
    margin-top: 4px;
    @include caption2;
    font-weight: 500;
    color: $pDarkGrey; }

.negative {
    color: $pOrange; }

.foot {
    padding: 12px 0;
    text-align: end;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.label {
    display: none;
    margin-right: 12px;
    @include base2;
    color: $pGrey;
    @include t {
        display: block; } }

.doc {
    @include dark {
        fill: $n2; } }

.none {
    color: red; }

.calendar {
    margin-left: -12px; }

.noDataImg {
    img {
        width: 228px;
        height: 228px; } }

.actionDropdown {
    background: $pOrange;
    border-radius: 3px; }
.actionDropdownHead {
    color: $pOffWhite !important;
    box-shadow: none;
    &:hover {
        box-shadow: none; }
    &::before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z'  fill='%23FFFDF9'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / 24px auto; } }
