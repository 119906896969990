@import "../../../../../styles/helpers";

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;

    border: 2px solid transparent;
    background: $n2;
    @include tomatoGrotesk;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $pOffWhite; }
    @include placeholder {
        color: $pDarkGrey;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.card {
    padding: 0;
    background-color: unset !important;
    pointer-events: none; }

.fieldLabel {
        @include base1-s;
        color: $pGrey;
        margin-bottom: 16px; }

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba($n2,.8);
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 16px; }
  @include dark {
    background: rgba($n6, .9); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: 85%;
  margin: auto;
  background: $pOffWhite;

  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @include dark {
    background: $n7; }
  @include m {
    box-shadow: none; }
  & > div {
    padding: 26px 24px 24px; } }

.newEmailBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px; }

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $n3;
  font-size: 0;
  svg {
    fill: $n5;
    transition: transform .2s; }
  @include dark {
    background: $n6;
    svg {
      fill: $pOffWhite; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }
