@import "../../styles/helpers";

.btnWrap {
    display: flex;
    align-items: center;
    gap: 8px; }

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px; }

.portalWrapper {
    margin-top: 32px; }

.coverImage {
    width: 100%;
    height: 200px;
    object-fit: cover; }

.profileImage {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    position: absolute;
    left: 20px;
    top: 50px; }

.imageWrapper {
    position: relative; }

.lenderName {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 400; }

.website {
    margin-top: 24px;
    color: $pDarkGrey; }

.header {
    color: $pGrey;
    margin-top: 24px; }

.headerWithMargin {
    color: $pGrey;
    margin-left: 8px; }

.about {
    margin-top: 8px; }

.iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 42px;
    margin-top: 24px;
    @include t {
        margin-left: 0px; } }

.singleIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 150px; }

.icon {
    cursor: pointer;
    text-align: center;
    padding-top: 13px;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-color: #E9E7E0; }

.detailsContainer {
    margin-top: 24px; }

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px; }

.status {
    cursor: unset; }

.detail {
    width: 51%;
    text-align: right; }

.singleDetail {
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px; }

.spinner {
    display: flex;
    justify-content: center;
    width: 100%; }
