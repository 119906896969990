@import "../../../styles/helpers";

.tableHeaderContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
    background-color: $pOrange;
    padding: 12px;
    border-radius: 4px; }

.columnName {
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 9%; }

.plus {
    width: 4%; }
