@import "../../../styles/helpers";

.card {
    padding: 0px; }

.pageTitle {
    margin-bottom: 24px; }

.head {
    margin-bottom: 0px;
    @include m {
        flex-wrap: wrap;
        margin-bottom: 8px; }
    .form {
        margin-right: auto;
        @include t {
            width: 250px; }
        @include m {
            width: 100%;
            margin: 0 0 16px; } }
    &.hidden {
        @include t {
            display: none; } } }

.filterBtn,
.downloadBtn {
    margin-right: 8px;
    fill: $pDarkGrey;
    svg {
        fill: $pDarkGrey; }

    @include t {
        margin-top: 36px; } }

.addProductBtn {
    margin-left: auto;
    @include s {
        padding: 8px; }
    @include xs {
        padding: 4px;
        font-size: 14px; } }

.deleteMarkedBtn {
    margin-left: auto; }

.title {
    margin-right: 24px;
    @include m {
        margin: 0; } }


.nav {
    display: flex;
    @include m {
        width: 100%; } }

.link {
    padding: 8px 16px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    @include m {
        flex: 1; }
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $pOffWhite; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px;
        @include m {
            margin-right: 0; } } }

.filters {
    @include t {
        position: absolute !important;
        margin-top: 227px;
        margin-left: 50px;
        z-index: 1; }
    @include m {
        margin-top: 228px;
        margin-left: 42px; } }

.downloadBtn {
    @include t {
        position: absolute !important;
        margin-top: 265px;
        margin-left: 84px;
        z-index: 1; }
    @include m {
        margin-top: 265px;
        margin-left: 75px; } }

.details {
    display: none; }

.row {
    &.flex {
        display: flex;
        .table {
            flex-shrink: 0;
            width: 384px;
            @include w {
                width: 350px; }
            @include d {
                width: 320px; }
            @include t {
                display: none; } }
        .details {
            display: block; } } }


.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }
