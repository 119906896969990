@import "../../styles/helpers";

.card {
    min-height: 260px; }

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px; }
