@import "../../../styles/helpers";

.titleX {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #1A1D1F !important;
    @include s {
        width: 40% !important; } }

.icon {
    display: block;
    @include a {
        display: none; } }

.line {
    display: flex;
    padding-bottom: 16px;
    &:not(:last-child) {
        margin-bottom: 32px;
        border-bottom: 1px solid $n3;
        @include t {
            margin-bottom: 24px; }
        @include dark {
            border-color: $n6; } } }

.title {
    margin-right: 24px;
    margin-right: 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #6F767E;
    @include s {
        width: 45% !important; }
    @include base2;
    color: #6F767E;
    @include dark {
        color: #6F767E; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.switch {
    margin-left: auto; }
