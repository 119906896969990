@import "../../../../styles/helpers";

.modalOuter {
    max-width: 1020px;
    @include t {
        max-width: 408px; } }

.row {
    display: flex;
    width: 100%;
    @include t {
        flex-wrap: wrap; }
    .col {
        flex: 0 0 33.33%;
        @include t {
            flex: 0 0 100%; } } }
.disabledInput {
    pointer-events: none; }
.input,
.dropdown {
    margin-bottom: 16px; }
.dropdown {
    margin-top: 20px; }

.dropdownDisabled {
    pointer-events: none; }

.card {
    padding: 8px;
    @include t {
        margin-top: 16px; } }

.image {
    margin-bottom: 16px;
    width: 100%; }

.attachmentButtons {
    display: flex;
    flex-wrap: wrap;
    .button {
        margin-right: 8px;
        margin-bottom: 8px;
        padding-left: 0;
        padding-right: 8px;
        @include d {
            height: 40px;

            font-size: 13px; } } }
.label {
    color: $pGrey; }
.selectedImageWrap {
    img {
        max-width: 300px; } }
.metaWrapper {
    .singleMeta {
        border-bottom: 1px solid rgba(17, 19, 21, 0.5);
        padding-bottom: 8px;
        margin-bottom: 12px;
        .label {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 600;
            color: $pGrey; }
        @include dark {
            border-color: $pDarkGrey; } } }

.bottomRight {
    position: absolute;
    bottom: 20px;
    right: 20px; }

.bottomLeft {
    position: absolute;
    bottom: 20px;
    left: 20px; }

.dropZone {
    background: $n2;
    padding: 12px;
    p {
        color: $pDarkGrey;
        border: 1px dashed $pDarkGrey;
        padding: 8px;
        text-align: center;
        @include base1-s; }
    @include dark {
        background: $n6; } }

.action {
    display: flex;
    gap: 12px;
    background: $n;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    padding: 4px;

    button {
        fill: $pOrange;
        &:not(:last-child) {
            margin-right: 4px;
            fill: $pDarkGrey; } }
    @include dark {
        background: $n7; } }
