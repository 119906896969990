@import "../../../../../../styles/helpers";

.outer {
    max-width: 408px; }

.wrap {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px; }

.buttons {
    display: flex;
    gap: 12px;
    .btnIcon {
        margin-left: 0 !important; } }
