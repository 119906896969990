@import "../../../styles/helpers";

.messages {
    flex-grow: 1;
    border: 1px solid $n3;

    @include t {
        height: 100%;
        border: none; }
    @include dark {
        border-color: $n6; } }

.wrapper {
    position: relative;
    height: calc(100% - 116px);
    padding: 10px 24px 62px 24px;
    @include t {
        height: calc(100% - 100px); }
    @include m {
        height: calc(100% - 84px);
        padding: 64px 16px 72px; }
    &.withImageWrap {
        height: calc(100% - 216px);
        @include t {
            height: calc(100% - 200px); }
        @include m {
            height: calc(100% - 164px); } } }


.button {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    @include m {
        top: 12px; } }

.list {
    max-height: 100%;
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
