@import '../../../../styles/helpers';

.field {
    margin-bottom: 16px; }

.label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    color: $pGrey; }

.errorMessage {
    color:#AD141E {} }

.nameWrapper {
    position: relative; }

.hasError {
    margin-bottom: 12px;
    input {
        background: #FFBC9925 !important;
        color: $pOrange;
        padding-right: 36px;
        @include base1-s;
        &:focus {
            border-color: #FFBC9925; }
        @include dark {
            color: $pOrange;
            &:focus {
                border-color: $pOrange; } } } }

.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 8px;
    top: 35px;
    background: transparent;
    height: 48px; }



