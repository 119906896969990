@import "../../styles/helpers";

.card {
    min-height: 350px; }
.pageTitle {
    margin-bottom: 24px; }

.button {
    margin-left: 16px;
    .ac {}
    @include m {
        flex-grow: 1; } }
.nodata {
    position: absolute;
    padding-top: 20px;
    text-align: center;
    width: 100%; }

.transactionDropdown {
    display: none;
    @include t {
        display: block; } }

.Approved {}
.posted {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }


.Declined {}
.void {
    padding: 0 8px;
    @include base1-s;
    background: rgba(235,61,52,.28);
    color: $pOrange; }


.pending {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }


.open {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }


.draft {
    background: $n3;
    padding: 0 8px;
    @include base1-s;
    color: $pDarkGrey; }


.transactionToggle {
    display: flex;
    align-items: center;
    gap: 16px;
    fill: $pDarkGrey;
    .toggleButton {
        @include base1-s;
        color: $pDarkGrey;
        padding: 8px 5px;

        &.active,
        &:hover {
            background: $n3;
            color: $n7;
            @include dark {
                background-color: $n5;
                color: $shades1; } } }
    @include t {
        display: none; } }

.inputValue {
    display: none !important; }

.input {
    padding-left: 48px !important;
    background: transparent !important;
    cursor: pointer;
    color: $pDarkGrey;
    width: 278px;
    @include dark {
        color: $shades1; } }

.lastRefresh {
    @include caption1;
    color: $pDarkGrey;
    h5 {
        font-weight: 600;
        font-size: 16px;
        color: $n7;
        @include dark {
            color: $n; }
        @include s {
            font-size: 36px; } } }


.balanceWrapper {
    margin-bottom: 24px;
    .left {
        display: flex;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        @include s {
            gap: 16px; }

        p {
            @include caption1;
            color: $pDarkGrey; }

        h2 {
            font-weight: 600;
            font-size: 48px;
            color: $n7;
            @include dark {
                color: $n; }
            @include s {
                font-size: 36px; } }

        .dropdown {
            color: $n3; } } }

.wrapper {
    margin: 0 -20px;
    background: $pOffWhite;
    @include m {
        margin: 0 -16px; }
    @include dark {
        background: transparent; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 24px; }
    @include m {
        padding: 16px; }
    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } }
    &:first-child {
        @include t {
            display: none; }
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $pDarkGrey; } }
    &:nth-child(2n) {
        @include t {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .5); } }
        .col {
            @include t {
                background: none; }
            @include dark {
                @include t {
                    background: none; } } } } }

.col {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        padding: 0; }
    &:first-child {
        padding-left: 24px;
        @include t {
            padding-left: 0; } }
    &:nth-child(2) {
        @include t {
            align-items: flex-start;
            order: -1;
            padding-bottom: 12px;
            border-bottom: 1px solid $n3;
            @include dark {
                border-color: $n6; } } }
    &:nth-child(3) {
        @include t {
            display: none; } }
    &:last-child {
        padding-right: 24px;
        @include t {
            padding-right: 0; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } } }

.status {
    flex-shrink: 0;
    white-space: nowrap;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: $n7; }

.details {
    display: none;
    @include t {
        display: block;
        margin-right: auto;
        padding-right: 16px; } }

.product {
    color: $n5;
    @include dark {
        color: $n3; } }

.invoice {
    margin-top: 4px;
    @include caption2;
    font-weight: 500;
    color: $pDarkGrey; }

.negative {
    color: $pOrange; }

.foot {
    padding: 12px 0;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.label {
    display: none;
    margin-right: auto;
    @include base2;
    color: $pGrey;
    @include t {
        display: block; } }

.none {
    color: red; }

.noDataImg {
    img {
        width: 228px;
        height: 228px; } }
