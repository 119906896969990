@import "../../../../../styles/helpers";

.card {
    margin-bottom: 20px; }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 0;
    @include t {
        width: 100%;
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }


.colorWrap {
    .label {
        @include base2;
        color: $pGrey;
        @include dark {
            color: $n3; } }
    .singleColor {
        margin-top: 12px;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 16px; } } }

.gray {
    background: #272B30; }

.blue {
    background: #0063F3; }

.purple {
    background: #CABDFF; }

.green {
    background: #B5E4CA; }

.red {
    background: #FFBC99; }
