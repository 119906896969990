@import "../../styles/helpers";

.row {
    position: relative;
    background: $pOffWhite;
    @include dark {
        background: $n7; } }

.col {
    padding: 96px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include m {
        padding-bottom: 32px; } }

.title {
    font-size: 38px; }

.wrap {
    align-items: flex-start; }

.preview {
    max-width: 230px;
    margin: 0 auto 30px;
    img {
        width: 100%; } }

.subtitle {
    margin-bottom: 48px;
    text-align: center; }

.list {
    li {
        position: relative;
        padding-left: 36px;
        @include base2;
        color: $pDarkGrey;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 8-8 7.98 7.98 0 0 1 5.942 2.644L11 13.586l-2.793-2.793a1 1 0 0 0-1.414 1.414L9.586 15a2 2 0 0 0 2.828 0l6.687-6.687C19.676 9.416 20 10.67 20 12z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; }
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.logo {
    display: inline-block;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    width: 200px;
    padding-right: 48px;
    img {
        width: 100%; }
    @include t {
        width: 140px; } }

.info {
    position: absolute;
    top: 35px;
    right: 40px;
    @include caption1;
    color: $shades1;
    @include t {
        padding-left: 80px; }
    @include m {
        right: 24px; }
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $pOffWhite; }
        &:hover {
            color: $pBurgundy;
            @include dark {
                color: $pBurgundy; } } }
    @include xs {
        top: 25px;
        a {
            display: block;
            text-align: end; } } }

.wrap {
    max-width: 296px; }

.title {
    margin-bottom: 32px; }
