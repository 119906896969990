@import "../../../../styles/helpers";

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.spinner {
    position: absolute;
    top: 50%;
    left: calc(50% + 140px);
    @include d {
        left: calc(50% + 40px); }
    @include t {
        left: 50%; } }


.fullWidth {
    width: 100%; }

.row {
    width: 100%;
    display: flex;
    gap: 16px;
    @include t {
        flex-wrap: wrap;
        display: block;
        margin: 0; }
    .col {
        width: 350px;
        flex: none;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } }
    .col2 {
        flex: 0 0 calc(100% - 366px);
        width: calc(100% - 366px);
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } } }

.card {
    padding: 12px; }

.archivedText {
    @include base1-s;
    color: $pDarkGrey;
    padding: 0 16px;
    background: $n3;
    margin-left: 20px; }

.paidStatus {
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA;
    padding: 0 16px;
    margin-left: 20px; }

.openStatus {
    @include base1-s;
    color: #0096FF !important;
    padding: 0 16px;
    margin-left: 20px;
    background: #ADD8E6; }

.actionCard {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
    .sendBtn {
        @include s {
            width: 100%; } } }

.paid {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }


.void {
    padding: 0 8px;
    @include base1-s;
    background: rgba(235,61,52,.28);
    color: $pOrange; }


.open {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }


.draft {
    background: $n3;
    padding: 0 8px;
    @include base1-s;
    color: $pDarkGrey; }


.paidModalCard {
    padding: 0;
    .head {
        padding-bottom: 24px;
        border-bottom: .1px solid #efefef;
        margin-bottom: 24px;
        @include dark {
            border-color: $n5; } }
    p {
        @include base1-s;
        color: $pDarkGrey; }
    .cashBtn {
        width: 100%;
        justify-content: start;
        margin-top: 24px;
        &:hover {
            background: $n3;
            box-shadow: none;
            @include dark {
                background: $n6; } } }
    .chooseTranBtn {
        width: 100%;
        justify-content: start;
        margin-top: 8px;
        &:hover {
            background: $n3;
            box-shadow: none;
            @include dark {
                background: $n6; } } } }

.transactionsWrap {
    max-width: 100%;
    .transaction {
        padding: 0; } }

.dateInput {
    position: absolute;
    top: 0;
    .input {
        cursor: pointer;
        background: transparent;
        height: 0;
        width: 0; }
    .inputValue {
        opacity: 0; } }
