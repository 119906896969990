@import "../../../../styles/helpers";

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px 0;
    @include t {
        display: block;
        margin: 24px 0 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 calc(100% - 16px);
        width: calc(100% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; }
    span {
        @include dark {
            color: $n3; } }
    .important {
        color: red; }
    .optional {
        @include base1-s;
        color: $pGrey;
        padding: 0 8px;
        background: $n3;
        margin-left: 8px; } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.switchWrap {
    display: flex;
    gap: 24px; }


.actionBtnWrap {
    display: flex;
    gap: 20px; }

.addTaxRateText {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #FF4900;
    cursor: pointer;
    margin-left: 10px; }





.table1 {
    display: table;
    width: 100%;
    @include t {
        display: block; } }


.row1 {
    display: table-row;
    &.active {
        .col {
            &:not(:first-child):not(:nth-child(2)) {
                display: none; }
            &:nth-child(2) {
                &:after {
                    right: 12px; } } } } }

.col1 {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey;
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; }

        @include t {
            padding-left: 26px;
            height: 40px; }

        @include m {
            padding-left: 18px;
            height: 48px; } }

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(2)) {
        @include t {
            display: none; } } }

