@import "../../../styles/helpers";

.cardBilling {
    padding-left: 0px; }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    svg {
        margin-right: 4px; }
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.close {
    position: absolute;
    top: 52%;
    transition: all .2s;
    svg {
        transition: fill .2s;
        background: transparent !important;
        .close {} }
    right: 12px;

    svg {
        fill: $shades1;
        background: white; }
    &:hover {
        svg {
            fill: $pOrange; } } }


.synced {
    background: $pBurgundy !important;
    pointer-events: none; }


.marginCard {
    padding: 0;
    margin-top: -24px; }

.actionCard {
    position: absolute;
    right: 12px;
    top: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
    a {
        color: $pOffWhite; }
    @include m {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 10px; }
    @include s {
        .button {
            padding: 12px;
            font-size: 14px; } }
    @include xs {
        gap: 4px;
        .button {
            padding: 10px; } } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.unlink {
    color: red; }

.typeNav {
    display: flex;
    align-items: center;
    width: max-content;
    background: #F4F4F4;
    padding: 4px 8px;

    margin-bottom: 36px;
    @include t {
        display: none; }
    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

.navDropdown {
    display: none;
    @include t {
        display: block;
        margin-bottom: 24px; } }

.dotsBtn {
    position: relative;
    @include t {
        margin-top: 12px;
        button {
            background: transparent;
            padding: 0;
            &:focus {
                background: transparent; } } }
    @include m {
        position: static;
        margin-right: -24px; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; }
        @include t {
            button {
                background: transparent; } } }

    @include dark {
        fill: $n; } }

.dropdownAccountHolder {
    z-index: 0 !important; }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: 0px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 96px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }

.billingToggle {
    position: absolute;
    margin-left: 290px;
    margin-top: 6px;
    cursor: pointer;
    fill: $pDarkGrey;
    @include dark {
        fill: $n; } }

.singleCard {
    padding: 16px;
    padding-bottom: 0; }

.errorMessage {
    color:#AD141E {} }

.dropdownHeadAccount {
    position: relative;
    height: 48px !important;
    padding: 0 48px 0 12px !important;
    box-shadow: inset 0 0 0 2px #EFEFEF;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px !important;
    color: #1A1D1F;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    user-select: none;
    transition: all .2s; }



.outer {
    max-width: 520px; }

.outerClass {
    max-width: 520px; }

.errorContainer {
    margin-top: 10px; }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    svg {
        margin-right: 4px; }
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-left: -8px;
    margin-right: -8px;
    @include t {
        display: block;
        margin-left: 0;
        margin-right: 0; }
    .col {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }

.mobileField {
    display: flex;
    .inputNo {
        flex-grow: 1;
        margin: 0 0 0 8px; } }

.dropdown {
    width: 23ch; }

.dropdown1 {
    width: 28ch; }

.shippingWrap {
    margin: 24px 8px 0px;
    width: 100%;
    .fieldLabel {
        @include base2;
        color: $pGrey;
        margin-bottom: 5px;
        @include dark {
            color: $pDarkGrey; } }

    .checkStatus {
        display: flex;
        margin-bottom: 5px;
        span {
            @include base1-s;
            color: $pDarkGrey;
            margin-left: 8px; } }

    .checkbox {
        margin-left: -16px; } }

.mobileNav {
    display: none;
    margin-bottom: 20px;
    @include t {
        display: block; } }
