@import "../../styles/helpers";

.backButton {
    position: fixed;
    right: 48px;
    top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px;
    cursor: pointer;
    @include a {
        right: 24px; } }

.row {
    position: relative;
    display: flex;
    background: $pOffWhite;
    min-height: 100vh;
    @include dark {
        background: $n7; } }

.col {
    &:first-child {
        display: flex;
        flex-shrink: 0;
        width: 250px;
        @include t {
            display: none;
            width: 100%;
            background: transparent; } }
    &:nth-child(2) {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        @include t {
            align-items: flex-start;
            padding-bottom: 0;
            min-height: max-content !important; } } }

.wrap {
    height: 100%;
    position: relative;
    max-width: 296px;
    p {
        @include base1-s;
        color: $pDarkGrey;
        a {
            color: $n5; } } }

.previewLeft {
    position: absolute;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    max-width: 400px;
    @include t {
        display: none; }
    img {
        width: 100%; } }

.previewBottom {
    display: none;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    max-width: 400px;
    @include m {
        max-width: 100%; }
    img {
        width: 100%; } }

.subtitle {
    margin-bottom: 24px;
    text-align: center; }

.list {
    li {
        position: relative;
        @include title1-s;
        color: $pDarkGrey;
        fill: $pDarkGrey;
        svg {
            margin-right: 12px; }
        &.active {
            color: $pOrange;
            fill: $pOrange; }
        &.complete1 {
            color: $pBurgundy;
            fill: $pBurgundy; }
        &.complete2 {
            color: $pBurgundy;
            fill: $pBurgundy; }
        &.complete3 {
            color: $pBurgundy;
            fill: $pBurgundy; }
        &:not(:last-child) {
            margin-bottom: 34px; } } }

.subHeading {
    margin-top: 36px;
    color: var(--primary-grey, #C7C5BF);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px; }

.logo {
    display: inline-block;
    position: fixed;
    top: 24px;
    left: 24px;
    z-index: 2;
    width: 224px;
    @include t {
        left: 24px; }
    img {
        width: 100%; } }

.info {
    top: 40px;
    right: 40px;
    @include base1-r;
    margin-top: 16px;
    color: $pDarkGrey;
    a {
        color: $pDarkGrey;
        transition: color .2s;
        @include base1-s;
        margin-left: 4px;
        font-weight: 600;
        @include dark {
            color: $pOffWhite; }
        &:hover {
            color: $pBurgundy;
            @include dark {
                color: $pBurgundy; } } } }

.wrapper {
    max-width: 296px;
    @include m {
        max-width: 100%; } }

.infoBack {
    position: fixed;
    top: 20px;
    right: 30px; }

.title {
    margin-bottom: 8px;
    color: $pDarkGrey; }

.subtext {
    color: $pGrey !important; }

.stripeWrap {
    p {
        @include base1-s;
        color: $pDarkGrey;
        margin-bottom: 24px; }
    .radioInputGroup {
        border: 2px solid $shades3;
        padding: 8px 16px;

        margin-top: 12px;
        display: flex;
        align-items: center;
        color: $n7;
        input {
            height: 20px;
            width: 20px;
            margin-right: 12px; } } }

.dropdownOption {
    margin: 0 8px;
    &:hover {
        background: $n3; } }


.registerBtn {
    width: 100%;
    margin-top: 16px; }

.finishWrap {
    p {
        @include base1-s;
        color: $pDarkGrey;
        margin-bottom: 24px; }
    .radioInputGroup {
        border: 2px solid $shades3;
        padding: 8px 16px;

        margin-top: 12px;
        display: flex;
        align-items: center;
        color: $pDarkGrey;
        cursor: pointer !important;
        input {
            height: 20px;
            width: 20px;
            margin-right: 12px;
            flex: none;
            cursor: pointer; } } }

.themeWrap {
    position: fixed;
    bottom: 24px;
    left: 24px;
    @include t {
        display: none; }
    .link {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        width: 100%;
        height: 36px;
        font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
        color: $pDarkGrey;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        transition: background .2s, box-shadow .2s, color .2s;
        @include m {
            width: 100%;
            font-size: 13px; }
        svg {
            margin-right: 4px;
            fill: $pDarkGrey;
            transition: fill .2s; }
        &:hover,
        &.active {
            color: $pOrange;
            svg {
                fill: $pOrange; }
            @include dark {
                color: $pOffWhite;
                svg {
                    fill: $pOffWhite; } } } } }

.theme {
    span {
        @include d {
            font-size: 15px !important;
            border-radius: 0; } } }
