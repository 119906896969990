@import "../../../styles/helpers";


.body {
    .button {
        width: 100%;
        border: none; }
    .disable {
        width: 100%;
        border: none;
        pointer-events: none;
        background: $pLightGrey !important; } }

.dropdown {
    margin-bottom: 10px;
    div {
        color: $pDarkGrey  !important; } }

.emailWrapper {
    position: relative; }

.hasError {
    margin-bottom: 12px;
    input {
        background: #FFBC9925 !important;
        color: $pOrange;
        padding-right: 36px;
        @include base1-s;
        &:focus {
            border-color: #FFBC9925; }
        @include dark {
            color: $pOrange;
            &:focus {
                border-color: $pOrange; } } } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.field {
    margin-bottom: 12px;
    input {
        padding-right: 36px; } }


.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 8px;
    top: 0;
    background: transparent;
    height: 48px; }

.validIcon {
    position: absolute;
    fill: $pBurgundy;
    right: 8px;
    top: 0;
    background: transparent;
    height: 48px; }

.termText {
    color: $pGrey; }


.terms {
    margin-top: 32px;
    cursor: pointer;
    @include caption1-m;
    color: $pDarkGrey;
    font-weight: 400;
    display: flex;
    align-items: flex-center;
    .termsIcon {
        flex: none;
        fill: $pOrange; }
    .termsText {
        margin-left: 12px;
        @include caption1-m;
        color: $pDarkGrey;
        font-weight: 400;
        .termsLink {
            margin: 0 0 0 4px;
            color: $n7 !important;
            font-weight: 700;
            @include dark {
                color: $pDarkGrey !important; } } } }

.divider {
    text-align: center;
    text-transform: lowercase;
    margin: 24px auto;
    position: relative;
    overflow: hidden;
    &:before {
        left: 0;
        margin-left: -1em;
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        content: "";
        position: absolute;
        top: 50%;
        width: 50%; }
    &:after {
        right: 0;
        margin-right: -1em;
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        content: "";
        position: absolute;
        top: 50%;
        width: 50%; } }

.externalLogin {
    margin-top: 16px;
    .button {
        position: relative;
        background: #3367D6 !important; }
    .link {
        @include caption1;
        font-weight: 700;
        font-size: 15px;
        color: white; } }
.google {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 2px;
        background: white url('~/public/images/social-auth-icon-google.svg') no-repeat top left;
        background-position: -3px -3px;
        width: 44px;
        height: 44px; } }

.radioInputGroup {
    border: 2px solid $shades3;
    margin-top: 12px;
    width: 100%;

    display: flex;
    cursor: pointer !important;
    padding: 0 8px;
    button {
        padding: 0;
        background: transparent;
        justify-content: flex-start !important;
        color: $pDarkGrey;
        width: 100%;
        &:hover {
            background: transparent !important; } }
    input {
        height: 20px;
        width: 20px;
        margin-right: 12px;
        flex: none;
        cursor: pointer; }
    label {
        cursor: pointer; } }

.otherInput {
    margin: 12px 0; }

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px; }

.imgWrap {
    width: 128px;
    height: 128px;
    background: $pBurgundy;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px; } }

.title {
    @include title1-s;
    color: $n5;
    @include dark {
        color: $pDarkGrey; } }

.invName {
    font-size: 34px;
    font-weight: 600;
    color: $n7;
    line-height: 48px;
    @include dark {
        color: $n3; } }

.emailText {
    @include base1-s;
    text-align: center;
    color: $pDarkGrey;
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }

.loginText {
    @include base1-s;
    text-align: center;
    color: $pGrey;
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }
