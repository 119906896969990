.rdw-editor-wrapper {
    .rdw-option-wrapper {
        min-width: 20px;
        height: 20px;
        margin: 0 2px;
        border: 1px solid transparent;

        background: none;
        transition: border-color .2s;
        @include m {
            min-width: 16px;
            height: 16px; }
        @include dark-common {
            background: lighten($pDarkGrey, 30); }
        &:hover {
            box-shadow: none;
            border-color: $pDarkGrey; }
        &.rdw-option-active {
            box-shadow: none;
            border-color: $pBurgundy;
            @include dark-common {
                border-color: $pBurgundy;
                box-shadow: inset 0 0 0 1px $pBurgundy; } } }
    .rdw-history-wrapper {
        margin-left: auto; }
    .public-DraftStyleDefault-block {
        margin: 12px 16px; }
    .rdw-emoji-modal,
    .rdw-link-modal {
        box-shadow: 0 10px 25px rgba($n8, .1); } }
