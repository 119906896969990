@import "../../../../../styles/helpers";

.card {
    padding: 0;
    margin-top: 24px; }


.tooltip {
    position: relative;
    top: -1px;
    margin-left: 16px;
    svg {
        fill: $shades1; } }
.dropdown {
    width: max-content;
    pointer-events: none; }

.interval {
    font-size: 14px;
    font-weight: 600;
    color: #6F767E;
    margin-left: 10px; }
