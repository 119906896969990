@import "../../../styles/helpers";

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 12px; }

.spinner {
    display: flex;
    width: 100%;
    justify-content: center; }
