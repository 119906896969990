@import "../../../../styles/helpers";

.wrapper {
    margin: 0;
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    padding-top: 60px;
    &.tablePaddingBottom {
        padding-bottom: 48px; }
    @include t {
        display: block; } }

.editOverlayHead {
    background: red;
    position: absolute; }

.row {
    display: table-row; }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey;
    &:first-child {
        width: 0;
        padding: 0; }

    // &:after
    //    content: ""
    //    position: absolute
    //    left: 0
    //    bottom: 0
    //    right: 0
    //    height: 1px
    //    background: $n3
    //    +t
    //        height: 0
    //    +dark
    //        background: $n6
    //    &:after
    //        left: 12px
    //
    //    +t
    //        padding-left: 26px
    //        height: 40px
    //
    //    +m
    //        padding-left: 18px
    //        height: 48px

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(2)) {
        @include t {
            display: none; } } }


.btnIcon {
    margin-right: 16px; }

.nodata {
    position: relative;
    img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 12px;
        max-width: 100%; } }


.btnClass {
    color: $pOrange;
    display: flex;
    justify-content:flex-start {}
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: all .2s;
    &:hover {
        background: #f4f4f4;
        @include dark {
            background: $n6; } }


    .btnIcon {
        margin-right: 16px; } }


.triHead {
    margin-left: 2px;
    position: relative; }

.tri-state-checkbox:focus::before {
    box-shadow: none !important; }

.tri-state-checkbox[aria-checked='mixed']::before,
.tri-state-checkbox[aria-checked='false']::before {
    @include dark {
        background: $pDarkGrey !important; } }

.triCheckbox[type="checkbox"],
.triCheckboxImg[type="checkbox"] {
    margin-left: -5px; }

.triCheckbox[type="checkbox"]:before,
.triCheckboxImg[type="checkbox"]:before {
    position: absolute;
    background: $n;
    margin-top: -1px;
    margin-left: -1px;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:after,
.triCheckboxImg[type="checkbox"]:after {
    position: absolute;
    content: '';
    margin-top: -1px;
    margin-left: -1px;
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }


.triCheckbox[type="checkbox"]:checked:after,
.triCheckboxImg[type="checkbox"]:checked:after {
    position: absolute;
    padding: 4px 3px;
    background: $pBurgundy !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") !important; }

.triCheckboxImg {
    margin-top: 12px; }

.noDataImg {
    img {
        width: 228px;
        height: 228px; } }
