@import "../../../styles/helpers";

.createWrapper {
    position: relative;
    @include m {
        order: 1;
        .body {
            right: -32px;
            left: calc(88px - 100vw);
            top: calc(100% + 16px); } } }

.create {
    position: relative;
    .iconAddCircle {
        display: none; }
    @include t {
        button {
            background: transparent !important;
            padding: 0;
            &:focus {
                background: transparent; }
            @include t {
                &:hover {
                    background: transparent; } } }
        span {
            display: none; }
        .iconAddCircle {
            display: block;
            fill: $pBurgundy;
            @include t {
                fill: $pDarkGrey; } }
        .iconAdd {
            display: none; }
        svg {
            margin: 0 !important; } }
    @include m {
        position: static; }
    &.active {
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); }
        @include t {
            button {
                background: transparent; } } } }

.body {
    position: absolute;
    top: calc(100% + 18px);
    right: 0px;
    width: 280px;
    padding: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include x {
        top: calc(100% + 15px);
        right: 0; }
    @include m {
        top: 100%;
        right: 16px;
        left: 16px;
        width: auto;
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    //&:before
    //  content: ""
    //  position: absolute
    //  right: 33px
    //  bottom: 100%
    //  width: 20px
    //  height: 10px
    //  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    //  @include x
    //      right: 13px
    //  @include m
    //      right: 21px
    //  @include dark
 }    //      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")

.menu {
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        @include m {
            margin-bottom: 8px;
            padding-bottom: 8px; }
        @include dark {
            border-color: $n6; } } }

.item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    .comingSoon {
        font-size: 10px;
        color: $pBurgundy;
        margin-left: 8px; }
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $pDarkGrey;
        background: #6F767E20;
        svg {
            fill: $pDarkGrey; }
        @include dark {
            color: $pOffWhite;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }
