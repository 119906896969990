@import "../../../../../styles/helpers";

.card {
    padding: 0;
    @include t {
        margin-top: 20px; } }

.cardHead {
    @include t {
        margin-bottom: 0; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: none; } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include caption1;
    color: $pDarkGrey;
    &:nth-child(2) {
        padding-left: 0; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include t {
            height: 0; }
        @include dark {
            background: $n6; }

        @include t {
            padding-left: 26px;
            height: 40px; }

        @include m {
            padding-left: 18px;
            height: 48px; } } }

.tooltip {
    position: relative;
    top: -1px;
    margin-left: 16px;
    svg {
        fill: $shades1; } }
