@import "../../styles/helpers";

.deleteModal {
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.comingSoon {
    font-size: 12px !important;
    color: $pBurgundy !important;
    margin-left: 8px; }

.singleCard,
.singleOwner {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    border: 2px solid $n3;
    padding: 12px 20px;
    @include dark {
        border-color: $pDarkGrey; }
    span {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; } } }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 10px;
    @include dark {
        color: $pDarkGrey; } }

.card {
    margin-top: -24px;
    padding: unset !important;
    padding-top: 24px !important; }

.head {
    width: 100%;
    margin-bottom: 8px !important;
    background: inherit;
    @include s {
        padding: 12px;
        font-size: 14px; }
    @include xs {
        padding: 10px; } }

.textBody {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 13px;
    margin-top: 5px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #6F767E;
    font-weight: 600;
    line-height: 1.71429; }

.inputValue {
    display: none !important; }

.input {
    padding-left: 48px !important;
    background: transparent !important;
    cursor: pointer;
    color: $pDarkGrey;
    width: 278px;
    @include dark {
        color: $shades1; }
    @include t {
        width: 48px;
        padding-left: 34px !important; } }

.body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 132;
    width: 350px;
    padding: 24px;
    background: $pOffWhite;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transform: translate(0, 0);
        width: auto;
        overflow: auto;
        .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; } }
    @include dark {
        background: $n7; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: -8px;
    z-index: 1;
    position: relative;
    justify-content: flex-end; }

.close {
    flex-shrink: 0;
    position: fixed;
    width: 36px !important;
    top: 25px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $pOffWhite; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 131;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include t {
                margin-bottom: 24px; } } } }

.row {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }
