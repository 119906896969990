@import "../../../../styles/helpers";

.card {
    padding: 0; }

.input {
    width: 55%; }

.newFieldBtn {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px; }
