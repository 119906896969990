@import "../../../styles/helpers";

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.deleteModal {
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.head {
    width: 100%;
    background: inherit;
    @include s {
        padding: 12px;
        font-size: 14px; }
    @include xs {
        padding: 10px; } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px !important; }

.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 18px;
    top: 38px;
    background: transparent;
    height: 48px; }

.button {
    width: 100%; }

.body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 348px;
    align-items: center;
    padding: 24px;

    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $pOffWhite;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transform: translate(0, 0);
        width: auto;
        overflow: auto;
        .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; } }
    @include dark {
        background: $n7; } }

.top {
    position: absolute;
    right: 24px;
    top: 20px;
    z-index: 9; }

.close {
    flex-shrink: 0;
    width: 36px !important;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $pOffWhite; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 18;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.fieldset {
    margin-top: 24px;
    width: max-content;
    & > .field {
        &:not(:last-child) {
            margin-bottom: 16px; } } }

.row {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px; } }

.actionBtn {
    border-top: 1px solid $shades3;
    padding-top: 16px; }
