@import "../../../../../styles/helpers";

.search {
    position: relative; }

.card {
    padding: 12px; }

.backBtn {
    cursor: pointer; }
