@import "../../styles/helpers";

.deleteModal {
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.head {
    width: 100%;
    background: inherit;
    @include s {
        padding: 12px;
        font-size: 14px; }
    @include xs {
        padding: 10px; } }

.body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 408px;
    padding: 24px;
    background: $pOffWhite;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transform: translate(0, 0);
        width: auto;
        overflow: auto;
        .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; } }
    @include dark {
        background: $n7; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: flex-end; }

.close {
    flex-shrink: 0;
    width: 36px !important;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $pOffWhite; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 18;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.imgWrapper {
    width: 128px;
    height: 128px;
    background: #EB3D34;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px;
        padding: 24px; } }

.title {
    @include title1-s;
    color: $n5;
    margin-top: 20px;
    @include dark {
        color: $n4; } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.para {
    @include base1-s;
    color: $n4;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    color: $pGrey;
    width: 80%; }
