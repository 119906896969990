@import "../../styles/helpers";

.card {
    padding: 0;
    @include dark {
        background: #33383F !important; } }

.dotsBtnWrap {
    fill: $pDarkGrey; }

.deleteBtn {
    fill: $n5;
    margin-left: 8px;
    background: $n3;
    padding: 8px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    svg {
        transition: transform .2s; }
    &:hover {
        svg {
            transform: rotate(90deg); } }
    @include dark {
        background: $n6;
        fill: $n3; } }


.field {
    &.fieldIcon {
        .input {
            padding-left: 48px; } }
    &.fieldCurrency {
        .input {
            padding-left: 58px;
            background: none;
            border-color: $n3;
            &:focus {
                border-color: $shades1; }
            @include dark {
                border-color: $n6;
                &:focus {
                    border-color: $n5; } } } }
    &.fieldCopy {
        .input {
            padding-right: 48px; } }
    &.error {
        .icon {
            svg {
                fill: $pOrange; } }
        .wrap {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 48px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z' fill-rule='evenodd' fill='%23ff6a55'/%3E%3Cpath d='M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z' fill='%23ff6a55'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; } }
        .input {
            padding-right: 48px;
            background: rgba($s1, .25);
            border-color: transparent;
            color: $pOrange;
            @include dark {
                &:focus {
                    border-color: $pOrange;
                    color: $pOrange; } }
            &::placeholder {
                color: $pOrange; } } }
    &.success {
        .wrap {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 48px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; } }
        .input {
            padding-right: 48px;
            background: $n;
            border-color: $shades1;
            @include dark {
                background: $n8; } } } }



.label {
    margin-bottom: 5px;
    @include base2;
    color: $pGrey;
    @include dark {
        color: $n3; } }

.required {
    color: $pOrange; }

.react-datepicker__month {
    height: 270px !important; }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    position: relative;
    height: 48px; }

.inputValue {
    background: transparent;
    position: absolute;
    left: 46px;
    width: calc(100% - 46px);
    height: 100%;
    line-height: 48px;
    padding: 0 10px; }

.input1 {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    margin-left: 33px;
    border: 2px solid transparent;
    background: white;
    @include tomatoGrotesk;
    @include base1-s;
    color: transparent;
    transition: all .2s;
    &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 100%;
        height: 40px;
        left: 0;
        opacity: 0; }
    &::-webkit-datetime-edit {
        opacity: 0; }
    @include dark {
        border-color: $n6;
        background: $n6;
        color: transparent; }
    @include placeholder {
        color: transparent;
        @include dark {
            color: $shades1; } }
    &:focus {}
    &:active {
        border-color: $shades1;
        background: $n;
        color: transparent;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;

    border: 2px solid $pGrey;
    background: $pLightGrey;
    @include tomatoGrotesk;
    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    &:hover {
        background: $white; }
    &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 100%;
        height: 40px;
        left: 0;
        opacity: 0; }
    &::-webkit-datetime-edit {
        opacity: 0; }
    @include dark {
        border-color: $n6;
        background: $n6;
        color: transparent; }
    @include placeholder {
        color: transparent;
        @include dark {
            color: $shades1; } }
    &:focus {}
    &:active {
        border-color: $shades1;
        background: $n;
        color: transparent;
        @include dark {
            border-color: $n5;
            background: $n8; } } }
.icon,
.copy {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px; }

.icon {
    left: 0;
    pointer-events: none;
    svg {
        fill: $pDarkGrey; } }

.copy {
    right: 0;
    svg {
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $pBurgundy; } } }

.currency {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    padding-top: 1px;
    background: $n2;
    color: $n7;
    @include dark {
        background: $n6;
        color: $pDarkGrey;
        fill: $pDarkGrey; } }


.calendarClearBtn {
    padding: 24px 0 0;
    text-align: end;
    button {
        width: 100%;
        border-top: 1px solid $n3;
        margin: 8px 0;
        padding-top: 32px;
        text-align: end;
        @include dark {
            border-top: 1px solid $pDarkGrey; }
        span {
            border: 2px solid $n3;
            padding: 8px 16px;
            font-size: 13px;
            font-weight: 700;

            @include dark {
                border-color: $pDarkGrey;
                color: $n2; } } } }
