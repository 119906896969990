@import "../../styles/helpers";

.largeNav {
    display: flex;
    align-items: center;
    width: max-content;
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
    @include a {
        flex-wrap: wrap;
        width: unset; }
    &.onlyLarge {
        @include t {
            display: flex; } }
    .item {
        position: relative;
        @include base1-s;
        color: $pGrey;
        cursor: pointer;
        text-align: center;
        &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            &::after {
                position: absolute;
                right: 0;
                height: 100%;
                width: 1px;
                background: #11131550;
                content: "";
                @include dark {
                    background: $pDarkGrey; } } }
        &.active {
            @include button1;
            color: $pOrange;
            @include dark {
                color: $pOrange;
                box-shadow: none; } } } }

