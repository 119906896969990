@import "../../styles/helpers";

.outerModalPreview {
    max-width: 600px; }

.imagePreviewCard {
    height: 80vh;
    overflow-y: auto; }

.previewImage {
    width: 100%;
    object-fit: cover; }
