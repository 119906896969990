@import "../../../../../styles/helpers";

.markComplete {
    text-decoration: line-through; }

.wrap {
    display: flex;
    align-items: center;
    gap: 12px;
    &:not(:last-child) {
        margin-bottom: 12px; } }

.text {
    font-size: 18px; }
