@import "../../styles/helpers";

.card {
    min-height: 400px; }
.head {
    @include a {
        flex-wrap: wrap; } }
.pageTitle {
    margin-bottom: 24px; }

.listDropdown {
    padding: 0px 8px;
    background: #E9E7E0;
    color: #464542;
    width: max-content;
    max-width: 200px; }

.option {
    width: 100px;
    max-width: 200px; }


.expandableContainer {
    display: flex;
    background: #EFEFEF;
    position: relative;
    width: 100%;
    @include dark {
        background: $n6; }
    flex-direction: row; }

.fieldContainer {
    display: flex;
    flex-direction: column; }

.button {
    margin-left: 16px;
    .ac {}
    @include m {
        flex-grow: 1; } }

.buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include a {
        gap: 8px;
        justify-content: center; } }

.nodata {
    position: absolute;
    padding-top: 20px;
    text-align: center;
    width: 100%; }

.transactionDropdown {
    display: none;
    @include t {
        display: block; } }

.Approved {}
.posted {}
.booked {}
.Booked {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA; }


.Declined {}
.void {
    padding: 0 8px;
    @include base1-s;
    background: rgba(235,61,52,.28);
    color: $pOrange; }


.pending {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }


.open {
    background: $s3;
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy; }


.draft {
    background: $n3;
    padding: 2px 8px;
    @include base1-s;
    color: $pDarkGrey;

    @include dark {
        background: $n6; } }

.transactionToggle {
    display: flex;
    align-items: center;
    gap: 16px;
    fill: $pDarkGrey;
    .toggleButton {
        @include base1-s;
        color: $pDarkGrey;
        padding: 8px 5px;

        &.active,
        &:hover {
            background: $n3;
            color: $n7;
            @include dark {
                background-color: $n5;
                color: $shades1; } } }
    @include t {
        display: none; } }

.inputValue {
    display: none !important; }

.input {
    padding-left: 48px !important;
    background: transparent !important;
    cursor: pointer;
    color: $pDarkGrey;
    width: 278px;
    @include dark {
        color: $shades1; }
    @include t {
        width: 48px;
        padding-left: 34px !important; } }

.lastRefresh {
    @include caption1;
    color: $pDarkGrey;
    h5 {
        font-weight: 600;
        font-size: 16px;
        color: $n7;
        @include dark {
            color: $n; }
        @include s {
            font-size: 36px; } } }


.balanceWrapper {
    margin-bottom: 24px;
    .left {
        display: flex;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        @include s {
            gap: 16px; }

        p {
            @include caption1;
            color: $pDarkGrey; }

        h2 {
            font-weight: 600;
            font-size: 48px;
            color: $n7;
            @include dark {
                color: $n; }
            @include s {
                font-size: 36px; } }

        .dropdown {
            color: $n3; } } }

.wrapper {
    margin: 0 -20px;
    background: $pOffWhite;
    @include m {
        margin: 0 -16px; }
    @include dark {
        background: transparent; } }

.inputWrap {
    position: relative;
    display: flex;
    margin-top: 16px;
    gap: 12px; }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;

        object-fit: cover;
        object-position: center;
        .__react_modal_image__modal_content {
            background: red; }
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.inputLabel {
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;

    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include dark {
        fill: $pDarkGrey; }
    @include m {
        height: 80px;
        width: 80px; } }

.fieldLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 10px;
    @include dark {
        color: $pDarkGrey; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 24px; }
    @include m {
        padding: 16px; }
    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } }
    &:first-child {
        &:hover {
            background: transparent;
            @include dark {
                background: transparent; } }
        @include t {
            display: none; }
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include base1-s;
            color: $pGrey; } }
    &:nth-child(2n) {
        @include t {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .5); } }
        .col {
            @include t {
                background: none; }
            @include dark {
                @include t {
                    background: none; } } } } }

.col {
    display: table-cell;
    padding: 10px 16px;
    font-size: 14px !important;
    @include base1-s;
    @include t {
        display: flex;
        align-items: center;
        padding: 0; }
    &:first-child {
        padding-left: 0px;
        @include t {
            padding-left: 0; } }
    &:nth-child(2) {
        @include t {
            align-items: flex-start;
            order: -1;
            padding-bottom: 12px;
            border-bottom: 1px solid $n3;
            @include dark {
                border-color: $n6; } } }
    &:nth-child(3) {
        @include t {
            display: none; } }
    &:last-child {
        padding-right: 24px;
        @include t {
            padding-right: 0; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } } }

.status {
    flex-shrink: 0;
    white-space: nowrap;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: $n7; }

.details {
    display: none;
    @include t {
        display: block;
        margin-right: auto;
        padding-right: 16px; } }

.product {
    color: $n5;
    @include dark {
        color: $n3; } }

.invoice {
    margin-top: 4px;
    @include caption2;
    font-weight: 500;
    color: $pDarkGrey; }

.negative {
    color: $pOrange; }

.foot {
    padding: 12px 0;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.label {
    display: none;
    margin-right: 16px;
    @include base2;
    color: $pGrey;
    @include t {
        display: block; } }

.none {
    fill: red; }

.noDataImg {
    img {
        width: 288px;
        height: 288px; } }

.attachment {
    fill: $n6;
    @include dark {
        fill: $n3; } }
