@import "../../../../../styles/helpers";

.btnWraper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px; }

.inputWrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 12px;
    margin-bottom: 21px; }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }


.successMessage {
    color:#008000 {} }

.selectedImageWrap {
    position: relative;
    .selectedImage {
        width: 100px;
        height: 100px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        @include m {
            height: 80px;
            width: 80px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.imgInput {
    .input {
        display: none; } }

.btnContainer {
    height: 80px; }

.inputLabel {
    border: .5px dashed $pDarkGrey;
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include dark {
        fill: $shades1; } }

.inputLabelImg {
    display: none; }

.description {
    width: 100%;
    padding: 0 10px;
    border: 2px solid transparent;
    background: #F4F4F4;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #1A1D1F;
    transition: all .2s; }

.label {
    font-size: 16px !important;
    font-weight: 400 !important;
    @include base2;
    color: $pGrey;
    @include dark {
        color: $n3; } }

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }

    .field {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }

.accessWrapper {
    margin-top: 21px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 8px; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429; }

.switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center; }

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; }

.switchContainer {
    flex: 1 1 calc(33.3333% - 16px);
    box-sizing: border-box;
    display: flex;
    align-items: center; }

.avatarWrap {
    margin-top: 8px;
    margin-bottom: 24px;
    position: relative;
    width: max-content;
    button {
        height: 24px;
        width: 24px;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        fill: $pDarkGrey;
        right: 0; }
    .avatar {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center; } }
