@import "../../../../styles/helpers";

.outerWrap {
    max-width: 512px; }

.input {
    max-width: 310px; }

.card {
    padding: 0; }

.newQuestionnaireBtn {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px; }

.questionnaireForm {
    margin-top: 16px;
    overflow: auto;
    height: 50vh;
    li[data-type = "autocomplete"],
    li[data-type = "date"],
    li[data-type = "file"],
    li[data-type = "hidden"] {
        display: none; }
    li[type = "button"] {
        display: block;
        text-align: left;
        button {
            background: $pBurgundy;
            color: $pOffWhite; } } }


.detailsWrap {
    margin-top: 16px;
    .title {
        @include base1-s;
        color: $pDarkGrey; }
    .tokenMblWrap {
        display: none;
        margin-top: 8px;
        margin-bottom: 16px;
        @include t {
            display: block; } }
    .tokenWrap {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .token {
            background-color: $pDarkGrey;
            padding: 4px;

            width: max-content;
            color: $pOffWhite;
            cursor: pointer; }
        @include t {
            display: none; } }
    trix-editor {
        height: 200px !important;
        overflow-y: scroll !important;
        padding: 8px !important;
        ul {
            list-style: disc;
            padding-left: 40px; }
        ol {
            list-style: decimal;
            padding-left: 40px; }
        blockquote {
            border: solid #ccc;
            border-width: 0 0 0 0.3em;
            margin-left: 0.3em;
            padding-left: 0.6em; } } }
