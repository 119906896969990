@import "../../../styles/helpers";

.user {
    position: relative;
    &.active {
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding-right: 36px;
    @include dark {
        svg {
            fill: $pGrey; } }
    img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 50%;
        &.businessLogo {
            position: absolute;
            left: 36px;
            @include m {
                left: 60px; } } }

    @include t {
        background: #F4F4F4;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.02), inset 0px -1px 1px rgba(0, 0, 0, 0.04);
        height: 72px;
        padding: 12px;
        @include dark {
            background: $n8; } } }

.body {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    border: 1px solid $pOrange;
    @include x {
        top: calc(100% + 15px);
        right: 0; }
    @include t {
        position: absolute;
        top: -250px;
        right: 0px;
        left: 0px;
        width: auto;
        padding: 12px 16px 20px; }

    @include dark {
        background: $n7;
        box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 1), 0px 32px 48px -8px rgba(0, 0, 0, 0.5); } }
.menu {
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $n3;
        @include m {
            margin-bottom: 8px;
            padding-bottom: 8px; }
        @include dark {
            border-color: $n6; } }
    &:first-child {
        .item {
            display: flex; } } }

.item {
    display: flex;
    align-items: center;
    font-weight: bold;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    .item2 {
        display: block;
        color: #C7C5BF; }

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    @include dark {
        color: $pLightGrey;
        svg {
            fill: $pLightGrey; } }
    &:hover,
    &.active {
        color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOrange;
            svg {
                fill: $pOrange; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }

.itemP {
    align-items: start;
    flex-direction: column;
    font-weight: bold;
    @include dark {
        color: $pOffWhite;
        &:hover {
            color: $pOffWhite; } } }

.item1 {
    height: unset; }

.chooseCompanyWrap {
    .brandLogo {
        width: 200px;
        margin-top: 8px;
        margin-bottom: 40px;
        img {
            width: 100%;
            object-fit: contain; } }
    h3 {
        font-size: 20px;
        font-weight: 500; }
    .companyWrap {
        .label {
            font-size: 20px;
            font-weight: 500;
            color: $pGrey;
            margin-top: 16px; }
        .company {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 24px;
            img {
                height: 50px;
                width: 50px;
                object-fit: contain;
                object-position: center; }
            .name {
                font-size: 17px;
                font-weight: 500;
                color: #344054;
                @include dark {
                    color: $n3; } }
            .position {
                font-size: 14px;
                font-weight: 500;
                color: 667085;
                @include dark {
                    color: $n3; } } }
        .addNewBtn {
            margin-top: 24px;
            color: $pDarkGrey;
            fill: $pDarkGrey;
            font-size: 17px;
            display: flex;
            align-items: center;
            gap: 12px; } } }


