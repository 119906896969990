@import "../../../../styles/helpers";


.row {
    position: relative;
    display: table-row;
    vertical-align: middle;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 8px; }
    @include m {
        padding: 16px 8px; }
    &:first-child {
        .col {
            @include caption2;
            font-weight: 500;
            color: $pDarkGrey; } }

    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } } }

.editOverlay {
    position: absolute;
    height: 60px;
    width: calc(100% - 84px);
    background: transparent;
    right: 16px;
    margin-top: 4px;
    @include t {
        width: calc(100% - 32px);
        height: 155px;
        margin-top: -6px; } }

.checkboxCol {
    position: relative;
    @include t {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        padding-left: 0px !important; } }

.checkbox {
    position: relative;
    left: 12px;
    top: 5px;
    @include t {
        top: 15px; } }


.col {
    display: table-cell;
    padding: 10px 13px;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        padding: 0;
        padding-left: 100px; }
    &:first-child {
        @include t {
            padding-left: 0; } }
    &:nth-child(3) {
        @include t {
            align-items: flex-start;
            @include dark {
                border-color: $n6; } } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 0px; } } }


.showUserImg {
    display: block; }

.hideUserImg {
    display: none; }

.mblCreate {
    padding: 1px 4px;
    display: none;
    margin-right: 12px;
    color: $pDarkGrey;
    @include t {
        display: block; } }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    &:hover {
        color: $pBurgundy; }
    .email {
        display: none;
        @include t {
            display: block;
            font-size: 13px;
            line-height: 1;
            color: $shades1; } } }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.user {
    margin-bottom: 4px;
    display: flex;
    align-items: center; }

.login {
    @include caption1;
    color: $pDarkGrey;
    @include t {
        display: none; }
    @include dark {
        color: $shades1; } }

.email {
    @include base2;
    color: $pDarkGrey; }

.col > .email {
    @include t {
        display: none; } }

.lifetime {
    display: inline-flex;
    align-items: center;
    @include m {
        flex-direction: column;
        align-items: flex-end; } }

.price {
    display: inline-block;
    padding: 0 8px;

    background: $n3;
    transition: backround .2s;
    @include dark {
        background: $n6; } }

.balance {
    margin-left: 8px;
    @include m {
        margin: 4px 0 0; } }

.dotsBtn {
    position: relative;
    @include t {
        button {
            background: transparent;
            padding: 0;
            &:focus {
                background: transparent; } } }
    @include m {
        position: static; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; }
        @include t {
            button {
                background: transparent; } } }

    @include dark {
        fill: $n; } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 48px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.menu {
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        @include m {
            margin-bottom: 8px;
            padding-bottom: 8px; }
        @include dark {
            border-color: $n6; } } }

.head {
    display: flex;
    justify-content: flex-end;
    .btn {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #EFEFEF;
        margin-left: 24px;
        @include dark {
            background: $n6; } } }

.link {
    margin-right: 4px;
    font-size: 16px; }

.addPipeline {
    padding: 8px;
    color: #FFF;
    background-color: $pOrange;
    width: max-content;
    text-align: center;
    border-radius: 8px;
    cursor: pointer; }

