@import "../../styles/helpers";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 32px 24px 24px;
    background: $pOffWhite;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    @include x {
        width: 220px;
        padding: 16px; }
    @include t {
        width: 100%;
        align-items: stretch;
        .logo {
            width: 200px;
            img {
                margin-top: 12px; } } }
    @include dark {
        background: $n7; }
    &.active {
        @include t {
            width: 100%; } } }

.logo {
    display: block;
    width: 200px;
    margin-bottom: 66px;
    @include x {
        margin-bottom: 36px; }
    img {
        width: 100%; } }

.outerClass {
    max-width: 1010px; }

.editMenuItem {
    margin-top: 12px;
    width: 97%;
    padding: 12px;
    border: 3px solid var(--primary-grey, #C7C5BF);
    height: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include a {
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        height: max-content; } }

.iconContainer {
    width: 126.5px;
    height: 126.5px;
    border: 4px solid $pGrey;
    border-radius: 50%;
    background-color: $pOrange;
    padding-left: 10px; }

.itemHeader {
    color: var(--primary-dark-grey, #464542);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px;
    margin-left: 12px; }

.itemSubHeading {
    width: 70%;
    margin-left: 24px;
    margin-rigth: 24px;
    color: var(--primary-dark-grey, #464542);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 160% */;
    letter-spacing: -0.15px;
    @include a {
        margin-left: 0px;
        text-align: center; } }


.menuButtonDisable {
    width: 120.7px; }

.menuButton {
    width: 99.5px; }

.itemsContainer {
    height: 55vh;
    overflow-y: auto; }

.menu {
    display: flex;
    flex-direction: column;
    &.premiumMenu {
        margin-bottom: auto;
        p {
            @include base1-s;
            font-size: 12px;
            margin: 8px 0 16px;
            color: $pDarkGrey; } } }

.item,
.dropdown {
    margin-bottom: 8px; }

.modalCardHead {
    margin-top: -24px; }

.item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 0 12px;
    @include title1-s;
    white-space: nowrap;
    color: $pDarkGrey;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include dark {
        color: $pOffWhite; }
    @include t {
        width: 100%;
        font-size: 15px; }
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s;
        @include t {
            margin-right: 12px; }
        @include dark {
            fill: $pOffWhite; } }
    &:hover,
    &.active {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        @include dark {
            color: $pOrange;
            svg {
                fill: $pOrange; } } } }

.messages {
    padding-left: 12px; }

.link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 0 12px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    color: $pDarkGrey;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include dark {
        color: $pOffWhite; }
    @include t {
        width: 100%;
        font-size: 13px; }
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s;
        @include dark {
            fill: $pOffWhite; }
        @include t {
            margin-right: 12px; } }
    &:hover,
    &.active {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        @include dark {
            color: $pOrange;
            svg {
                fill: $pOrange; } } } }

.toggle {
    display: none;
    width: 48px;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include t {
        display: none; }
    svg {
        fill: $pDarkGrey;
        &:nth-child(2) {
            display: none; } } }

.close {
    display: none;
    @include t {
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 48px;
        height: 48px;
        svg {
            fill: $n7;
            @include dark {
                fill: $pOffWhite; } } } }

.foot {
    margin-top: 24px;
    padding-top: 18px;
    border-top: 2px solid $n2;
    @include dark {
        border-color: $n6; } }

.theme {
    margin-top: 16px; }

.counter {
    flex-shrink: 0;
    min-width: 24px;
    margin-left: auto;

    background: $s2;
    text-align: center;
    line-height: 24px;
    color: $n7;
    @include t {
        display: block; } }

.overlay {
    @include t {
        display: none; } }

.user {
    display: none;
    @include t {
        display: block; } }

.footerUserWrapper {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    h5 {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; } }
    p {
        @include caption2-m;
        color: $pDarkGrey; }
    svg {
        @include dark {
            fill: $pDarkGrey; } }
    @include t {
        margin-left: 44px; } }

.dropdownOptions {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 18px;
    font-weight: 300;
    justify-content: center;
    color: $pDarkGrey;
    margin-bottom: 12px;
    margin-left: 46px;
    cursor: pointer; }

.dropdownItem {
    @include dark {
        color: $pOffWhite;
        svg {
            fill: $pOffWhite; } }
    &:hover,
    &.active {
        color: $pOrange;
        svg {
            fill: $pOrange; }
        @include dark {
            color: $pOrange;
            svg {
                fill: $pOrange; } } } }
