@import "../../styles/helpers";

.product {
    &:hover {
        .preview:after,
        .control,
        .checkbox {
            visibility: visible;
            opacity: 1; } }
    &.active {
        .checkbox {
            visibility: visible;
            opacity: 1; }
        .preview {
            &:before {
                border-color: $pBurgundy; } } } }

.preview:after,
.control,
.checkbox {
    opacity: 0;
    visibility: hidden;
    transition: all .2s; }

.preview {
    position: relative;
    height: 200px;
    margin-bottom: 16px;

    overflow: hidden;
    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .2s; }
    &:after {
        background: rgba($n8, .8); }
    &:before {
        z-index: 2;
        border: 2px solid transparent;

        pointer-events: none; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .checkbox {
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 3;
        @include t {
            display: none; }
        .checkboxTick {
            border-color: $pBurgundy; } } }

.control {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%,-50%); }

.line {
    display: flex;
    align-items: flex-start; }

.title {
    margin-right: auto; }

.price,
.empty {
    flex-shrink: 0;
    display: inline-block;
    margin-left: 32px;
    padding: 0 8px;

    line-height: 32px;
    font-weight: 700; }

.price {
    background: $pBurgundy;
    @include dark {
        color: $n7; } }

.empty {
    background: $n3;
    @include dark {
        background: $n6;
        color: $pOffWhite; } }

.date {
    display: flex;
    align-items: center;
    margin-top: 8px;
    @include base2;
    color: $pDarkGrey;
    @include m {
        margin-top: 2px; }
    svg {
        margin-right: 8px;
        fill: $pDarkGrey; } }

.rating {
    display: flex;
    align-items: center;
    margin-top: 8px;
    @include base2;
    @include m {
        margin-top: 4px; }
    svg {
        margin-right: 8px;
        fill: #FFC554; }
    span {
        margin-left: 4px;
        color: $pDarkGrey; } }

.ratingEmpty {
    color: $pDarkGrey;
    svg {
        fill: $pDarkGrey; } }
