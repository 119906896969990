@import "../../../styles/helpers";

.card {
    margin-bottom: 16px; }

.activeInputClass {
    background-color: #FFF; }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.accBtn {
    margin-left: 12px; }

.row {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .col {
        flex: 0 0 calc(33.33% - 16px);
        @include t {
            flex: 0 0 calc(50% - 24px); }
        @include a {
            flex: 0 0 100%; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }
