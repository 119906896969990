@import "../../../styles/helpers";

.card {
    margin-top: -48px; }

.cardX {
    @include m {
        padding: 12px; }
    &:not(:last-child) {
        margin-bottom: 8px; } }

.cardHead {
    margin-bottom: 16px; }

.incomeExpenseContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
    @include t {
        gap: unset; } }

.incomeContainer,
.expenseContainer {
    width: 49%;
    @include t {
        width: 100%; } }

.filters {
    width: unset;
    flex-wrap: wrap; }

.nav {
    display: block;
    gap: 12px;
    @include title1-s;
    &.chartShow {
        margin-bottom: 32px; }
    @include m {
        margin-bottom: 24px;
        gap: 8px; } }

.carouselContainer {
    ul {
        gap: 8px;
        @include t {
            width: unset; } } }

.switchStyle {
    height: 26px; }

.ItemContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap; }

.item {
    width: 310px;
    margin-bottom: 4px;
    border-radius: 0 !important;
    padding: 12px !important;
    border: 2px solid #6F767E;
    min-height: 150px;
    &.activeItem0 {
        background: $pGrey; }
    &.activeItem1 {
        background: $pOrange; }
    &.activeItem2 {
        background: #FFA96B; }
    &.activeItem3 {
        background: #34A853; } }


.transactionDropdown {
    display: block;
    @include t {
        display: block; } }

.transactionToggle {
    display: flex;
    align-items: center;
    gap: 6px;
    fill: $pDarkGrey;
    .toggleButton {
        @include base1-s;
        color: $pDarkGrey;
        padding: 8px 5px;

        &.active,
        &:hover {
            background: $n3;
            color: $n7;
            @include dark {
                background-color: $n5;
                color: $shades1; } }
        @include t {
            display: none; } } }

.calendarControl {
    background: $n3;
    padding: 8px 5px;
    color: $n7;
    cursor: pointer;

    @include t {
        margin-left: 12px; }
    @include dark {
        background: $n6; } }

.calendarWrap {
    position: absolute;
    right: 24px;
    top: 74px;
    @include m {
        top: 62px; } }

.input {
    font-weight: 600 !important; }

.input {
    width: 100% !important;
    text-align: center;
    cursor: pointer;
    color: $pDarkGrey !important; }


.container {}

.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #C7C5BF;
    cursor: pointer;
    text-align: center; }

.cicularCharts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 48px;
    gap: 64px; }

.incomeCard {
    margin-top: 24px; }

.noContent {
    width: 100% !important;
    display: flex !important;
    justify-content: center; }

.listContainer {
    display: flex;
    flex-direction: column;
    width: 360px; }

.listItem {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    border: 1px solid $pDarkGrey;
    border-radius: 14px;
    margin-top: 12px; }

.arrowImg {
    width: 12px;
    height: 12px; }


.typeNav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
    @include t {
        display: flex; }
    .item {
        position: relative;
        @include base1-s;
        color: $pGrey;
        cursor: pointer;
        text-align: center;
        &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            &::after {
                position: absolute;
                right: 0;
                height: 100%;
                width: 1px;
                background: #11131550;
                content: "";
                @include dark {
                    background: $pDarkGrey; } } }
        &.active {
            @include button1;
            color: $pOrange;
            @include dark {
                color: $pOffWhite;
                box-shadow: none; } } } }


.col12 {
    flex: 0 0 100%;
    margin-bottom: 24px;
    width: 100%; }
