@import '../../../styles/helpers.sass';

.homeHeader {
    position: relative;
    display: flex;
    gap: 12px;
    .singleCard {
        background: $n;
        padding: 8px 16px;
        justify-content: space-between;
        gap: 32px;
        color: $pDarkGrey;
        fill: $pDarkGrey;
        .flex {
            display: flex;
            justify-content: space-between;
            button {
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%; } }
        p {
            @include caption1;
            margin-top: 8px; } } }

.xInput {
    width: 80%;
    height: 48px;
    background: $n2;
    border: 2px solid transparent;
    white-space: nowrap;
    display:table-row {}

    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        background: $n6;
        color: $pOffWhite; }
    &::placeholder {
        color: $shades1; }
    &:focus {
        border-color: $pBurgundy;
        background: $n;
        @include dark {
            background: $n8; } } }

.outer {
  max-width: 1124px;
  width: 100%;
  margin: auto; }

.title {
    word-wrap: break-word;
    color: #464542;
    font-size: 15px;
    font-weight: 600 !important;
    width: 120px;
    flex: none;
    @include dark {
        color: $pOffWhite; } }

.desc {
    word-wrap: break-word;
    color: #464542;
    font-size: 15px;
    font-weight: 300 !important;
    flex-grow: 1;
    display: none;
    @include t {
        display: block; }
    @include dark {
        color: $pLightGrey; } }

.description {
    word-wrap: break-word;
    color: #464542;
    font-size: 15px;
    font-weight: 300 !important;
    flex-grow: 1;
    display: block;
    @include t {
        display: none; }
    @include dark {
        color: $pLightGrey; } }

.buttonWrapper {
    transform: unset;
    display: flex;
    justify-content: flex-end;
    width: max-content;
    flex: none; }

