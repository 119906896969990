@import "../../styles/helpers";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.imgWrapper {
    width: 128px;
    height: 128px;
    background: $pOrange;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px; } }

.title {
    @include title1-s;
    color: $n5;
    margin-top: 20px;
    @include dark {
        color: $pDarkGrey; } }

.para {
    @include base1-s;
    color: $pDarkGrey;
    margin-top: 16px;
    margin-bottom: 16px; }
