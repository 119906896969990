@import "../../../../../styles/helpers";

.row {
    cursor: pointer;
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px; }
    @include m {
        padding: 16px; }
    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } }
    &:first-child {
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $pDarkGrey; } } }

.editOverlay {
    position: absolute;
    cursor: pointer;
    height: 48px;
    width: calc(100% - 32px);
    right: 16px;
    margin-top: 8px;
    z-index: -99;
    @include t {
        height: 166px;
        margin-top: -6px; } }

.col {
    display: table-cell;
    padding: 12px;
    @include base1-s;
    color: $n6;
    &.typeNumber {
        text-align: end; }
    @include t {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        background: transparent;
        margin-left: 0;
        border: 0; }
    &:first-child {
        padding-left: 10px;
        @include t {
            padding-left: 0; } }
    &:nth-child(1) {}

    &:nth-child(2) {
        @include t {
            @include dark {
                border-color: $n6; } } }
    &:nth-child(3) {
        @include t {
            margin-top: 0px; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } }
    @include dark {
        color: $pDarkGrey;
        &:nth-child(3) {
            background: transparent; }
        &:last-child {
            background: transparent; } } }

.action {
    display: flex;
    align-items: center;
    gap: 12px;
    .editBtn {
        fill: $pDarkGrey;
        cursor: pointer; }
    .removeBtn {
        fill: $pOrange;
        cursor: pointer; } }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    &:hover {
        color: $pBurgundy; }
    .email {
        display: none;
        @include t {
            display: block;
            font-size: 13px;
            line-height: 1;
            color: $shades1; } } }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }


.image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;

    flex: none; }


.login {
    @include caption1;
    color: $pDarkGrey;
    @include t {
        display: none; }
    @include dark {
        color: $shades1; } }

.email {
    @include base2;
    color: $pDarkGrey; }

.col > .email {
    @include t {
        display: none; } }

.lifetime {
    display: inline-flex;
    align-items: center;
    @include m {
        flex-direction: column;
        align-items: flex-end; } }

.title {
    display: none;
    font-size: 15px;
    color: $pDarkGrey;
    margin-right: 16px;
    @include t {
        display: block; } }

.balance {
    margin-left: 8px;
    @include m {
        margin: 4px 0 0; } }

.dotsBtn {
    position: relative;
    @include t {
        margin-top: 12px;
        button {
            background: transparent;
            padding: 0;
            &:focus {
                background: transparent; } } }
    @include m {
        position: static;
        margin-right: -24px; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; }
        @include t {
            button {
                background: transparent; } } }

    @include dark {
        fill: $n; } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 96px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.menu {
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        @include m {
            margin-bottom: 8px;
            padding-bottom: 8px; }
        @include dark {
            border-color: $n6; } } }

.head {
    display: flex;
    justify-content: flex-end;
    .btn {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #EFEFEF;
        margin-left: 24px;
        @include dark {
            background: $n6; } } }

.deleteBtn {
    position: absolute; }


.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }
