[class^="status"] {
    display: inline-block;
    padding: 0 8px;
    // background: rgba($dark3, .1)

    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
 }    // color: $dark2

.status-green {
    background: #EAFAE5;
    color: $p2;
    @include dark-common {
        background: rgba(#83BF6E, .15); } }

.status-green-dark {
    background: $pBurgundy;
    color: $n7; }

.status-red {
    background: #FFE7E4;
    color: $pOrange;
    @include dark-common {
        background: rgba($pOrange, .15); } }

.status-red-dark {
    background: $s1;
    color: $pOffWhite; }

.status-purple {
    background: #CABDFF;
    color: $p4; }

.status-blue {
    background: $s3;
    color: $pBurgundy; }

.status-yellow {
    background: $s5;
    color: $n7; }
