@import "../../../styles/helpers";

.txt {
    @include base1-s;
    color: $pDarkGrey; }

.list {
    margin-top: 16px;
    color: $pDarkGrey;
    @include base1-s;
    li {
        margin-bottom: 8px; }
    .serviceIcon {
        fill: $pBurgundy; } }
