@import "../../../../styles/helpers";

.card {
    padding: 0;
    margin-top: 64px; }

.modal {
    max-width: max-content; }

.row {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .col {
        flex: 0 0 calc(33.33% - 16px);
        @include t {
            flex: 0 0 calc(50% - 24px); }
        @include a {
            flex: 0 0 100%; } } }
