@import "../../../../styles/helpers";

.container {
    display: flex;
    flex-wrap: wrap; }

.itemContainer {
    display: flex;
    flex-direction: column; }

.title {
    font-weight: 300;
    font-size: 14px;
    color: $pDarkGrey;
    margin-bottom: 3px; }

.checkboxWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    pointer-events: none; }

.line {
    background-color: $pGrey;
    width: 148px;
    height: 1px; }

.selectedLine {
    background-color: $pOrange;
    width: 148px;
    height: 1px; }

.classCheckboxTick {
    border: 2px solid $pOrange; }
