@import "../../../styles/helpers";

.spinner {
    margin: auto;
    width: max-content; }

.foot {
    margin-top: 36px;
    padding: 12px 0;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    h5 {
        margin-top: 2px;
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    margin-right: 12px;
    svg {
        margin-right: 4px; }
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.fieldLabel {
    margin-top: 16px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pGrey;
    margin-bottom: 10px;
    @include dark {
        color: #EFEFEF; } }

.detail {
    color: #464542;
    font-size: 14px;
    font-weight: 300; }

.options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    padding-right: 0px !important;
    cursor: pointer !important; }

.pointer {
    cursor: pointer; }

.promptInput {
    width: 100%;
    padding: 0 10px;
    border: 2px solid #C7C5BF;
    background: #F4F4F4;
    font-family: 'tomatoGrotesk', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #464542;
    transition: all .2s;
    @include dark {
        color: #EFEFEF;
        border: 2px solid $pDarkGrey;
        background-color: #272B30;
        &:focus {
            background-color: #1A1D1F; } } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }


.row {
    display: table-row;
    &.active {
        .col {
            &:not(:first-child):not(:nth-child(2)) {
                display: none; }
            &:nth-child(2) {
                &:after {
                    right: 12px; } } } } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px 8px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey;
    //&:after
    //  //content: ""
    //  //position: absolute
    //  //left: 0
    //  //bottom: 0
    //  //right: 0
    //  //height: 1px
    //  //background: $n3
    //  //+t
    //  //    height: 0
    //  //+dark
    //  //    background: $n6
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; }

        @include t {
            height: 40px; }

        @include m {
            height: 48px; } }

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(1)) {
        @include t {
            display: none; } } }

.triHead {
    margin-left: 2px;
    position: relative; }

.tri-state-checkbox:focus::before {
    box-shadow: none !important; }

.tri-state-checkbox[aria-checked='mixed']::before,
.tri-state-checkbox[aria-checked='false']::before {
    @include dark {
        background: $pDarkGrey !important; } }

.triCheckbox[type="checkbox"] {
    margin-left: -5px; }

.triCheckbox[type="checkbox"]:before {
    position: absolute;
    background: $n;
    margin-top: -1px;
    margin-left: -1px;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:after {
    position: absolute;
    content: '';
    margin-top: -1px;
    margin-left: -1px;
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:checked:after {
    position: absolute;
    padding: 4px 3px;
    background: $pBurgundy !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") !important; }
