@import "../../styles/helpers";

.tooltip {
    display: inline-block;
    margin-left: 4px;
    cursor: pointer;
    @include s {
        display: none; }
    .tooltipWpapper {
        padding: 3px 12px;

        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;
        font-size: 12px;
        font-weight: 600; } }

.mainToolTip {
    width: 300px;
    @include s {
        width: 200px !important; } }
