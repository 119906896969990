@import "../../../../styles/helpers";

.details {
    padding: 24px;
    flex-grow: 1;
    @include m {
        padding: 16px;
        overflow: hidden; } }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @include m {
        flex-wrap: wrap;
        margin-bottom: 32px; } }

.nav {
    display: flex;
    margin-right: auto;
    @include m {
        width: 100%;
        margin: 0 0 16px; } }

.link {
    padding: 8px 16px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    @include m {
        flex: 1; }
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $pOffWhite; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px;
        @include m {
            margin-right: 0; } } }

.btns {
    display: flex;
    align-items: center; }

.favorite {
    margin-right: 16px;
    svg {
        fill: $pOrange; }
    &:hover {
        box-shadow: inset 0 0 0 1px $pOrange;
        background: $pOrange;
        color: $pOffWhite;
        @include dark {
            box-shadow: inset 0 0 0 1px $pOrange; }
        svg {
            fill: $pOffWhite; } } }

.buy {
    @include t {
        padding: 0 16px; }
    @include m {
        flex-grow: 1; }
    .inner {
        span {
            @include t {
                display: none; } } } }

.price {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #4493FC;
    box-shadow: 1px 0 0 0 #186FE3;
    line-height: 48px;
    @include t {
        margin-right: 16px;
        padding-right: 16px; }
    @include m {
        margin-right: 12px;
        padding-right: 12px; } }
