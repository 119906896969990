@import "../../../styles/helpers";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 48px;
    @include d {
        margin-left: 48px; }
    @include t {
        margin-left: 0px; }
    @include m {
        padding-left: 24px;
        padding-right: 24px; } }

.heading {
    width: 640px;
    word-wrap: break-word;
    color: var(--primary-dark-grey, #464542);
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px; /* 100% */;
    letter-spacing: -1.44px;
    @include t {
        width: auto; } }

.subHeading {
    color: var(--primary-grey, #C7C5BF);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px;
    margin-top: 16px;
    width: max-content; }

