@import "../../../../styles/helpers";

.card {
    padding: 0;
    margin-top: 64px; }

.modal {
    max-width: 900px; }

.searchModal {
    max-width: max-content; }

.visibleSearchModal {
    max-width: max-content;
    height: 90vh; }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $pDarkGrey; } }

.row {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    .col {
        flex: 0 0 calc(33.33% - 16px);
        @include t {
            flex: 0 0 calc(50% - 24px); }
        @include a {
            flex: 0 0 100%; } } }


.spinner {
   display: flex;
   width: 100%;
   justify-content: center; }
