@import "../../../../styles/helpers";

.messagesWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 48px;
    @include a {
        flex-direction: column; } }

.leftWrapper {
    width: 40%;
    @include a {
        width: 100%; } }

.noChannel {
    color: var(--primary-dark-grey, #464542);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.4px;
    display: flex;
    justify-content: center; }

.pic {
    margin-right: 8px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center; }

.header {
    color: var(--primary-grey, #C7C5BF);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; }

.allMessages {
    display: flex;
    flex-direction: column;
    gap: 0px; }

.singleChannel {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px; }

.activeChannel {
    background: var(--primary-light-grey, #E9E7E0); }

.singleChannelInfo {
    display: flex;
    flex-direction: column; }

.lenderName {
    color: var(--primary-dark-grey, #464542);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.4px; }

.type {
    color: var(--primary-dark-grey, #464542);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; }

.profileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        .profileImageWrapper {
            display: none; }
        .link {
            display: block; } } }

.link {
    text-align: center;
    cursor: pointer;
    padding-top: 11px;
    width: 63.91px;
    height: 43.91px;
    display: none; }


.date {
    margin-top: 4px;
    color: var(--primary-grey, #C7C5BF);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; }

.rightWrapper {
    position: relative;
    width: calc(60% - 48px);
    height: 80vh;
    border: 1px solid #C7C5BF;
    @include a {
        width: 100%; } }

.spinner {
    display: flex;
    width: 100%;
    justify-content: center; }

.companyImage {
    margin-right: 20px;
    border-radius: 5px;
    width: 43.918px;
    height: 43.918px; }

.profileImage {
    width: 47.918px;
    height: 47.918px;
    border-radius: 50%;
    margin-right: 14px; }

.dotsBtn {
    position: relative;
    @include t {
        button {
            background: transparent;
            padding: 0;
            &:focus {
                background: transparent; } } }
    @include m {
        position: static; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; }
        @include t {
            button {
                background: transparent; } } }

    @include dark {
        fill: $n; } }

.head {
    display: flex;
    justify-content: flex-end;
    .btn {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #EFEFEF;
        margin-left: 24px;
        @include dark {
            background: $n6; } } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 48px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.option {
    cursor: pointer;
    padding: 12px;
    color: var(--primary-dark-grey, #464542);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &:hover {
        background-color: #E9E7E0; } }

