@import "../../../styles/helpers";

.cardWrap {
    margin-bottom: 24px;
    padding: 0; }

.card {
    margin-bottom: 24px; }

.tooltip {
    fill: $pDarkGrey; }

.headBtns {
    display: flex;
    gap: 8px;
    @include t {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        button {
            width: max-content; } } }

.outer {
    h3 {
        @include title1-s;
        color: $n5;
        margin-bottom: 8px; }
    p {
        @include caption1;
        color: 4n4;
        margin-bottom: 24px; }
    .input {
        margin-bottom: 16px; }
    .button {
        width: 100%;
        margin: 8px 0 24px; } }


.wrapper {
    margin: 0 -20px;
    background: $pOffWhite;
    @include t {
        padding-bottom: 36px; }
    @include m {
        margin: 0 -16px; }
    @include dark {
        background: transparent; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 24px; }
    @include m {
        padding: 16px; }
    &:first-child {
        @include t {
            display: none; }
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include base1-s;
            color: $pGrey; } }
    &:nth-child(2n) {
        @include t {
            background: transparent;
            @include dark {
                background: rgba($n6, .5); } }
        .col {
            background: transparent;
            @include t {
                background: none; } } } }

.col {
    display: table-cell;
    padding: 10px 12px;
    vertical-align: middle;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0; }
    &:first-child {
        padding-left: 0px;
        @include t {
            padding-left: 0; } }
    &:last-child {
        padding-right: 0px;
        text-align: end;
        width: 132px;
        @include t {
            padding-right: 0;
            padding-bottom: 24px;
            border-bottom: 1px solid #1113151f; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } }
    p {
        &:first-child {
            display: none;
            color: $pDarkGrey;
            @include t {
                display: flex; } } } }


.flag {
    width: 25px; }

.fundWrap {
    .flex {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;
        @include t {
            flex-direction: column;
            align-items: flex-start; }
        p {
            color: $n6;
            @include base1-s;
            width: 32ch; }
        .input {
            input {
                color: $pDarkGrey;
                width: max-content; } } } }

.scheduleWrap {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap; }

.methodWrap {
    .methodHead {
        display: flex;
        gap: 12px;
        border-bottom: 1px solid #11131550;
        padding-bottom: 16px;
        h3 {
            @include base1-s;
            color: $pDarkGrey;
            &:first-child {
                flex: 0 0 calc(25% - 6px); }
            &:last-child {
                flex: 0 0 calc(75% - 6px); } } }
    .flex {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 16px;
        h4 {
            @include base1-b;
            color: $n7;
            flex: 0 0 calc(25% - 6px); }
        p {
            @include base1-s;
            color: $n6;
            flex: 0 0 calc(75% - 6px); }
        @include t {
            align-items: start; } } }
