@import "../../styles/helpers";

.transparentColor {
    color: transparent !important; }

.field {
    &.fieldIcon {
        .input {
            padding-left: 48px; } }
    &.fieldCurrency {
        .input {
            padding-left: 58px;
            &:focus {
                border-color: $shades1; }
            @include dark {
                border-color: $n6;
                &:focus {
                    border-color: $n5; } } } }
    &.fieldCopy {
        .input {
            padding-right: 48px; } }
    &.error {
        .icon {
            svg {
                fill: $pOrange; } }
        .wrap {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 48px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z' fill-rule='evenodd' fill='%23ff6a55'/%3E%3Cpath d='M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z' fill='%23ff6a55'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; } }
        .input {
            padding-right: 48px;
            background: rgba($s1, .25);
            border-color: transparent;
            color: $pOrange;
            @include dark {
                &:focus {
                    border-color: $pOrange;
                    color: $pOrange; } }
            &::placeholder {
                color: $pOrange; } } }
    &.success {
        .wrap {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 48px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; } }
        .input {
            padding-right: 48px;
            background: $n;
            border-color: $shades1;
            @include dark {
                background: $n8; } } } }

.formattedNumber {
    position: absolute;
    left: 12px;
    top: 11px;
    font-family: 'tomatoGrotesk', sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 1.6 !important;
    color: #464542 !important;
    transition: all .2s !important;
    @include dark {
        color: $pOffWhite; } }

.label {
    font-family: 'TomatoGrotesk-Light', sans-serif;
    margin-bottom: 5px;
    @include base2;
    color: $pGrey;
    @include dark {
        color: $n3; } }

.required {
    color: $pOrange; }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    position: relative; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    border: 2px solid $pGrey;
    background: $pLightGrey;
    @include tomatoGrotesk;
    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    &:hover {
        background: $white; }
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $pOffWhite; }
    @include placeholder {
        color: #0c0c0b5c;
        font-weight: 500;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.icon,
.percentage,
.copy {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px; }

.text {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px; }

.priorText {
    position: absolute;
    top: 2px;
    left: 1.5px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 45px;
    background-color: #E9E7E0;
    @include a {
        width: 48px; } }

.crossOnLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.spinner {
    position: absolute;
    top: 7px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    pointer-events: none;
    svg {
        fill: $pDarkGrey; } }

.icon {
    left: 0;
    pointer-events: none;
    svg {
        fill: $pDarkGrey; } }

.percentage {
    right: 0;
    pointer-events: none;
    svg {
        fill: $pDarkGrey; } }

.copy {
    right: 0;
    svg {
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $pBurgundy; } } }

.text {
    right: 10px;
    color: #6F767E;
    svg {
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $pBurgundy; } } }

.currency {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    padding-top: 1px;
    background: $n2;
    color: $n7;
    @include dark {
        background: $n6;
        color: $pDarkGrey;
        fill: $pDarkGrey; } }
