@import "../../styles/helpers";

.cardHead {
    margin-bottom: 8px; }

.row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @include t {
        flex-wrap: wrap;
        gap: 8px; }
    @include a {
        display: block; } }

.spinner {
    position: absolute;
    top: 50%;
    left: calc(50% + 140px);
    @include d {
        left: calc(50% + 40px); }
    @include t {
        left: calc(50% - 15px); } }

.homeHeader {
    @include t {
        display: none; } }

.balanceCard {
    margin-bottom: 20px;
    width: 101.8%; }

.balanceContainer {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    @include xs {
        margin-right: 0px; } }

.mainTitle {
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }

.title {
    font-weight: 600;
    font-size: 13px;
    margin-top: -3px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #6F767E; }

.value {
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #1A1D1F;
    @include dark {
        color: #FFFFFF; } }

.icons {
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #1A1D1F;
    margin-right: 20px;
    margin-top: 17px;
    @include xs {
        margin-right: 0px; } }


.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.col12 {
    flex: 0 0 100%;
    margin-bottom: 24px;
    width: 100%; }

.col {
    &:first-child {
        flex: 0 0 calc(50% - 172px);
        width: calc(50% - 172px);
        padding-right: 8px;
        @include t {
            flex: 0 0 calc(50% - 4px);
            width: calc(50% - 4px);
            margin-bottom: 8px;
            padding-right: 0; }
        @include a {
            width: 100%; } }
    &:nth-child(2) {
        flex: 0 0 calc(50% - 172px);
        width: calc(50% - 172px);
        padding-right: 8px;
        @include t {
            flex: 0 0 calc(50% - 4px);
            width: calc(50% - 4px);
            margin-bottom: 8px;
            padding-right: 0;
            @include a;
            width: 100%; } }
    &:nth-child(3) {
        flex-shrink: 0;
        width: 340px;
        @include x {
            width: 324px; }
        @include d {
            width: 312px; }
        @include t {
            width: 100%; } } }

.col6 {
    width: 49%;
    @include t {
        margin-top: 12px;
        width: 100%; } }

.card {
    @include m {
        padding: 12px; }
    &:not(:last-child) {
        margin-bottom: 8px; } }

.row {
    .transactionWrapper {
        width: 100%;
        margin-top: 24px; } }
