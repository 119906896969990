@import "../../styles/helpers";

.row {
    position: relative;
    background: $pOffWhite;
    @include dark {
        background: $n7; } }

.col {
    padding: 96px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include m {
        padding-bottom: 32px; } }


.wrap {
    max-width: 270px; }

.logo {
    display: inline-block;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    width: 200px;
    padding-right: 48px;
    img {
        width: 100%; }
    @include t {
        width: 140px; } }

.info {
    position: absolute;
    top: 35px;
    right: 40px;
    @include caption1;
    color: $shades1;
    @include t {
        padding-left: 80px; }
    @include m {
        right: 24px; }
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $pOffWhite; }
        &:hover {
            color: $pBurgundy;
            @include dark {
                color: $pBurgundy; } } } }

.wrap {
    max-width: 296px; }

.title {
    margin-bottom: 32px; }

.txt {
    @include base1-s;
    color: $pDarkGrey;
    margin-bottom: 16px; }

.button {
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
    .link {
        color: $n; } }

.skipLink {
    @include base1-s;
    color: $pDarkGrey;
    text-align: center;
    display: block; }
