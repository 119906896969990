@import "../../../../styles/helpers";


.wrapper {
    width: calc(100%);
    @include t {
        width: calc(100% + 16px); } }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.actionCard {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
    .saveBtn {
        @include s {
            width: 100%; } } }
