@import "../../../styles/helpers";

.tableHeaderContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
    background-color: $pDarkGrey;
    padding: 12px;
    border-radius: 4px; }

.columnName {
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 9%; }

.plus {
    width: 4%; }

.mainTotalBarContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 4px;
    .childWithPadding {
        padding: 12px; } }

.lineTop {
    height: 1px;
    background-color: $pGrey;
    width: 100%;
    margin-top: 24px; }

.line {
    height: 1px;
    background-color: $pGrey;
    width: 100%; }

.mainBarColumnContainer {
    display: flex;
    flex-direction: column; }

.mainBarHeading {
    color: $pDarkGrey;
    font-size: 16px; }

.mainBarValue {
    font-weight: 400;
    margin-top: 4px;
    color: $pDarkGrey; }

.totalBox {
    background-color: $pDarkGrey;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: center; }

.totalBoxText {
    color: white; }


