@import "../../styles/helpers";


.xInput {
    width: 50%;
    height: 48px;
    background: $n2;
    border: 2px solid transparent;
    white-space: nowrap;
    display:table-row {}

    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        background: $n6;
        color: $pOffWhite; }
    &::placeholder {
        color: $shades1; }
    &:focus {
        border-color: $pBurgundy;
        background: $n;
        @include dark {
            background: $n8; } } }

.outer {
  max-width: 1124px;
  min-width: 200px;
  width: 50%;
  margin: auto; }
