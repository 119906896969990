@import "../../styles/helpers";

.options {
    display: flex;
    gap: 8px;
    margin-bottom: 48px;
    flex-wrap: wrap;
    position: absolute;
    bottom: 24px; }

.optionsContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
    padding-right: 8px;
    width: 114px;
    height: 48px;
    background-color: #F4F4F4;
    cursor: pointer;
    @include dark {
        background-color: #272B30; } }

.optionLabel {
    font-weight: 400;
    color: $pDarkGrey;
    font-size: 14px;
    @include dark {
        color: $pGrey; } }

.emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }

.emptyMessage {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6;
    color: #6F767E; }

.emptyLogo {
    height: 48px;
    width: 48px;
    margin-bottom: 24px; }

.messages {
    flex-grow: 1;
    border: 1px solid $n3;
    height: calc(100vh - 272px);
    @include x {
        height: calc(100vh - 248px);
        border: none; }
    @include m {
        height: calc(100vh - 208px); }
    @include s {
        height: calc(100vh - 176px); }
    @include dark {
        border-color: $n6; } }

.wrapper {
    position: relative;
    height: 100%;
    padding: 24px 24px 132px; }


.list {
    max-height: 100%;
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }



.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 20px;
    @include t {
        display: block;
        margin-left: 0;
        margin-right: 0; }
    .col {
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px;
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.sendWrap {
    bottom: 12px; }
