@import "../../styles/helpers";

.theme {
    display: block;
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 0; }

.input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked + .inner {
        background: $n8;
        @include dark {
            background: $pOffWhite; }
        .box {
            &:first-child {
                background: none;
                box-shadow: none;
                color: $pDarkGrey;
                svg {
                    fill: $pDarkGrey; }
                &:hover {
                    color: $pOffWhite;
                    svg {
                        fill: $pOffWhite; } } }
            &:nth-child(2) {
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06);
                background: $n6;
                color: $pOffWhite;
                svg {
                    fill: $pOffWhite; } } } } }


.inner {
    position: relative;
    display: flex;
    padding: 2px;
    width: max-content;
    background: $pDarkGrey;
    transition: background .2s;
    border-radius: 3px;
    @include dark {
        background: $pLightGrey; } }


.box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 max-content;
    height: 32px;
    margin: 2px;
    padding: 0 8px;
    font-size: 15px;
    border-radius: 3px;
    transition: background .2s, box-shadow .2s, color .2s;
    svg {
        margin-right: 8px;
        transition: fill .2s; }
    &:first-child {
        background: $pOffWhite;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
        @include dark {
            color: $n6 !important;
            svg {
                fill: $n6 !important; } } }
    &:nth-child(2) {
        color: $n;
        svg {
            fill: $n; } } }



