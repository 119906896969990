@import "../../../../../styles/helpers";


.row {
    display: table-row;
    position: relative;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 0px; }
    @include m {
        padding: 16px 0; }
    &:first-child {
        .col {
            padding-top: 8px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $pDarkGrey; } }

    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } } }

.name {
    position: relative;
    margin-left: 8px;
    width: max-content;
    @include t {
        left: -15px !important;
        top: -10px; }
    @include dark {
        color: $shades1; } }

.email {
    @include t {
        position: absolute;
        top: 46px;
        left: 82px; } }

.amount {
    color: $n5;
    @include dark {
        color: $shades1; } }

.dropdown {
    width: 13ch;
    @include t {
        position: absolute;
        right: 0px;
        bottom: 38px; } }

.resendButton {
    width: 160px;
    background: #FFFFFF !important;
    color: black !important;
    border: 1px solid black;
    cursor: pointer; }

.date {
    background: none !important;
    color: black !important;
    padding: 0 !important;
    @include dark {
        color: $shades1 !important; } }

.archivedText {
    @include base1-s;
    color: $pDarkGrey;
    padding: 0 8px;
    background: $n3;
    @include dark {
        background: $n6; } }

.product {
    position: relative;
    margin-left: 10%;
    min-width: 150px;
    padding: 5%;
    @include t {
        margin-left: 1%; }
    @include s {
        width: 70px; }
    .productDetailsImg {
        position: absolute;
        left: 82px;
        top: 50%;
        transform: translateY(-50%);
        width: max-content; }
    .productDetails {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include t {
            top: 0;
            left: -10px;
            transform: translateY(0); } } }

.image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    flex: none; }

.editOverlay {
    position: absolute;
    height: 0px;
    width: calc(100% - 32px);
    background: transparent;
    right: 16px;
    margin-top: 4px;
    @include t {
        height: 155px;
        margin-top: -6px; } }


.col {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    @include t {
        display: flex;
        align-items: center;
        padding: 0; }
    &:first-child {
        padding-left: 10px;
        @include t {
            padding-left: 0; } }
    &:nth-child(3) {
        @include dark {
            border-color: $n6; } }
    &:nth-child(4) {}
    &:last-child {
        padding-right: 24px;
        @include t {
            padding-right: 0;
            margin-top: -6px;
            position: absolute;
            right: 48px; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 12px; } } }

.dateCol {
    @include t {
        position: relative; } }

.mblCreate {
    background: $n3;
    padding: 1px 4px;
    display: none;
    margin-right: 12px;
    @include t {
        display: block; }
    @include dark {
        background: $n6; } }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    &:hover {
        color: $pBurgundy; }
    .email {
        display: none;
        @include t {
            display: block;
            font-size: 13px;
            line-height: 1;
            color: $shades1; } } }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.user {
    max-width: 160px;
    margin-bottom: 4px; }

.login {
    @include caption1;
    color: $pDarkGrey;
    @include t {
        display: none; }
    @include dark {
        color: $shades1; } }

.email {
    @include base2;
    color: $pDarkGrey; }

.col > .email {
    @include t {
        display: none; } }

.lifetime {
    display: inline-flex;
    align-items: center;
    @include m {
        flex-direction: column;
        align-items: flex-end; } }

.price {
    display: inline-block;
    padding: 0 8px;

    background: $n3;
    transition: backround .2s;
    @include dark {
        background: $n6; } }

.balance {
    margin-left: 8px;
    @include m {
        margin: 4px 0 0; } }

.dotsBtn {
    position: relative;
    @include t {
        button {
            background: transparent;
            padding: 0;
            &:focus {
                background: transparent; } } }
    @include m {
        position: static; }
    &.active {
        .actionBody {
            visibility: visible;
            opacity: 1; }
        @include t {
            button {
                background: transparent; } } }

    @include dark {
        fill: $n; } }

.actionBody {
    position: absolute;
    top: calc(100% + 18px);
    right: -20px;
    width: 280px;
    padding: 16px;

    border: 1px solid transparent;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 9;
    @include x {
        top: calc(100% + 15px);
        right: 0; }

    @include s {
        top: 100%;
        right: -32px;
        width: calc(100vw - 48px);
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    &:before {
        content: "";
        position: absolute;
        right: 33px;
        bottom: 100%;
        width: 20px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include x {
            right: 13px; }
        @include m {
            right: 21px; }
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.menu {
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        @include m {
            margin-bottom: 8px;
            padding-bottom: 8px; }
        @include dark {
            border-color: $n6; } } }

.head {
    display: flex;
    justify-content: flex-end;
    .btn {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #EFEFEF;
        margin-left: 24px;
        @include dark {
            background: $n6; } } }

.pencil,
.deleteIcon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #EFEFEF;
    flex-shrink: 0; }

.deleteIcon {
    margin-left: 12px; }
