@import "../../../../../styles/helpers";

.card {
    padding: 0; }

.features {
    .featuresTitle {
        @include base1-s;
        color: $pDarkGrey;
        margin-bottom: 12px; } }

.featureWrap {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 12px; }

.addonFeature {
    border: 3px solid $n3;

    padding: 12px;
    height: 120px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    gap: 12px;
    .title {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $shades1; } }
    .activeStatus {
        color: $pBurgundy;
        fill: $pBurgundy;
        span {
            margin-left: 12px;
            font-weight: 700; } }
    .deActivateBtn {
        svg {
            transform: rotate(180deg); } }
    &.activeFeature {
        border-color: $pBurgundy; } }

.itemCard {
    border: 3px solid var(--primary-grey, #C7C5BF);
    width: 150px;
    height: 300px;
    padding: 10px; }

.activeCard {
    border: 3px solid #FF4900; }

.activeTitle {
    color: #FF4900; }


.itemCardHead {
    margin-bottom: 12px; }

.itemCardTitle {
    color: var(--primary-dark-grey, #464542);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */;
    letter-spacing: -0.15px; }

.subHeading {
    color: var(--primary-dark-grey, #464542);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */;
    letter-spacing: -0.12px;
    height: 166px; }

.activateButton {
    position: absolute;
    left: 24px;
    margin-top: 0px;
    width: 99.5px;
    bottom: 12px; }

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px; }

.deActivateButton {
    position: absolute;
    left: 11px;
    margin-top: 0px;
    width: 120.7px;
    bottom: 12px; }

.freePlanContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
