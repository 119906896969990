@import '../../styles/helpers.sass';

.card {
    padding: 0; }

.cardTitle {
    color: $pDarkGrey;
    @include base1-s;
    margin: 16px 0; }

.cards {
    position: relative;
    .card {
        margin-bottom: 24px;
        p {
            @include title1-s;
            color: $n5;
            margin-bottom: 12px; } } }

.defaultCard {
    background: $n7; }

.container {
    ul {
        gap: 8px; } }

.typeNav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: max-content;
    background: $n3;
    margin-bottom: 10px;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: #F4F4F4; } } } }




.balanceItem {
    margin-bottom: 4px;
    border: 2px solid #6F767E;
    border-radius: 0 !important;
    padding: 12px !important;
    &.active {
        // background: $pOffWhite
        // box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        //+dark
        //    background: $n7
 }        //    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
    &.activeItem0 {
        background: $pOrange;
        color: #FFFDF9 !important; }
    &.activeItem1 {
        background: $pLightGrey; }
    &.activeItem2 {
        background: $pApricot; }
    &.activeItem3 {
        background: $pPink; }
    &.activeItem4 {
        background: $pPink; }
    &.activeItem5 {
        background: $pPink; }
    &.activeItem6 {
        background: $pPink; }
    &.activeItem7 {
        background: $pPink; }
    &.activeItem8 {
        background: $pPink; } }
