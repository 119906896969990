@import "../../../styles/helpers";

.terms {
    margin-top: 32px;
    cursor: pointer;
    @include caption1-m;
    color: $pDarkGrey;
    font-weight: 400;
    display: flex;
    align-items: flex-center;
    .termsIcon {
        flex: none;
        fill: $pOrange; }
    .termsText {
        @include caption1-m;
        color: $pDarkGrey;
        font-weight: 400;
        .termsLink {
            margin: 0 0 0 4px;
            color: $n7 !important;
            font-weight: 700;
            @include dark {
                color: $pDarkGrey !important; } } } }

.body {
    .button {
        width: 100%;
        border: none;
        cursor: pointer;
        .link {
            color: $n; } }
    .disable {
        width: 100%;
        border: none;
        background: $pLightGrey !important;
        .link {
            color: $n; } } }

.emailWrapper {
    position: relative; }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    text-align: center;
    margin-top: 10px; }

.hasError {
    margin-bottom: 12px;
    input {
        background: #FFBC9925 !important;
        color: $pOrange;
        padding-right: 36px;
        @include base1-s;
        &:focus {
            border-color: #FFBC9925; }
        @include dark {
            color: $pOrange;
            &:focus {
                border-color: $pOrange; } } } }

.field {
    margin-bottom: 12px;
    input {
        padding-right: 36px; } }


.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 8px;
    top: 0;
    background: transparent;
    height: 48px; }

.validIcon {
    position: absolute;
    fill: $pBurgundy;
    right: 8px;
    top: 0;
    background: transparent;
    height: 48px; }

.forgot {
    margin-top: 16px;
    .link {
        @include caption1;
        color: $n7 !important; } }

.divider {
    text-align: center;
    text-transform: lowercase;
    margin: 24px auto;
    position: relative;
    overflow: hidden;
    &:before {
        left: 0;
        margin-left: -1em;
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        content: "";
        position: absolute;
        top: 50%;
        width: 50%; }
    &:after {
        right: 0;
        margin-right: -1em;
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        content: "";
        position: absolute;
        top: 50%;
        width: 50%; } }

.externalLogin {
    margin-top: 16px;
    .button {
        position: relative;
        background: #3367D6 !important; }
    .link {
        @include caption1;
        font-weight: 700;
        font-size: 15px; } }
.google {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 2px;
        background: white url('~/public/images/social-auth-icon-google.svg') no-repeat top left;
        background-position: -3px -3px;
        width: 44px;
        height: 44px; } }

