@import "../../../../styles/helpers";

.dataContainer {
    width: 33%;
    @include a {
        width: 100%; } }

.physicalDataContainer {
    margin-left: 100px;
    margin-top: 10px;
    @include a {
        margin-left: 5px; } }

.dataMainContainer {
    display: flex;
    flex-direction: row;
    @include a {
        flex-direction: column; } }

.mobileRes {
    @include t {
        margin-left: unset !important;
        margin-top: 10px; } }

.errorMessage {
    padding: 10px 2px 0px;
    color: #AD141E; }



.physicalCardTxt {
    color: $pDarkGrey;
    @include caption1-m;
    @include dark {
        color: $shades1; } }

.comingSoon {
    font-size: 12px !important;
    color: $pBurgundy !important;
    margin-left: 8px; }


.title {
    @include base1-s;
    color: $pDarkGrey; }

.deleteBtn {
    fill: $n5;
    margin-left: 8px;
    background: $n3;
    padding: 8px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    svg {
        transition: transform .2s; }
    &:hover {
        svg {
            transform: rotate(90deg); } }
    @include dark {
        background: $n6;
        fill: $n3; } }

.title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    h5 {
        @include body1-s;
        color: $n6; }

    p {
        @include body1-s;
        color: $pDarkGrey; } }

.backBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
        margin-left: 4px;
        font-size: 15px;
        font-weight: 600; }
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.txt {
    color: $pDarkGrey;
    @include caption1-m;
    @include dark {
        color: $shades1; } }

.cardDetails {
    color: $pDarkGrey;
    font-size: 14px; }

.singleCard,
.singleOwner {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    border: 2px solid $n3;
    padding: 12px 20px;

    @include dark {
        border-color: $pDarkGrey; }
    span {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; } } }

.nextBtn {
    padding-left: 8px !important;
    margin-top: 20px; }


.close {
    position: absolute;
    top: 59%;
    transition: all .2s;
    svg {
        transition: fill .2s;
        background: transparent !important; } }

.close {
    left: 267px;

    svg {
        fill: $shades1; }
    &:hover {
        svg {
            fill: $pOrange; } } }
