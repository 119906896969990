@import "../../styles/helpers";

.portal {
    padding: 24px; }

.card {
    padding: 0 !important;
    margin-left: 12%;
    margin-right: 12%; }

.portalWrap {
    padding: 24px;
    @include m {
        padding: 16px; }
    @include s {
        padding: 8px; } }

.productWrap {
    padding-bottom: 12px;
    .head,
    .row {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        gap: 24px;
        .name {
            @include base1-s;
            color: $n7;
            @include dark {
                color: $n2; } } }
    .row {
        .left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1; }
        .title {
            @include base1-s;
            color: $pDarkGrey;
            margin-right: 8px; } }
    @include dark {
        border-color: $pDarkGrey; } }

.wrapper {
    display: flex;
    gap: 16px;
    position: relative;
    width: 100%;
    .card {
        background: #fffdf9;
        &:first-child {
            flex: 0 0 calc(33.33% - 8px);
            width: calc(33.33% - 8px); }
        &:last-child {
            flex: 0 0 calc(66.67% - 8px);
            width: calc(66.67% - 8px); } }
    @include t {
        flex-direction: column;
        .card {
            &:first-child {
                flex: 0 0 100%;
                width: 100%;
                max-width: max-content; }
            &:last-child {
                flex: 100%;
                width: 100%; } } } }


.dropzoneWrap {
    padding: 8px;
    background-color: $n2;
    margin-bottom: 20px;

    @include dark {
        background-color: $n5; } }
.dropzone {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 2px dashed $pDarkGrey;
  color: #bdbdbd;
  cursor: pointer;
  min-height: 100px;
  text-align: center;
  fill: $pDarkGrey;
  @include dark {
    border-color: $pDarkGrey; }
  span {
    @include base1-s;
    margin-left: 8px;
    color: $pDarkGrey; } }


.dataGroup {
    margin-bottom: 15px;
    .label {
        margin-bottom: 8px;
        @include base2;
        color: $pGrey;
        @include dark {
            color: $n3; } }
    .logoImg {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        object-fit: cover; }
    .colorGroup {
        display: flex;
        align-items: center;
        button {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            &:not(:last-child) {
                margin-right: 8px; }
            &.activeBackgroundColor,
            &.activeTextColor {
                border: 2px solid $s7; } }
        .colorOne {
            background-color: $pBurgundy; }
        .colorTwo {
            background-color: $pOrange; }
        .colorThree {
            background-color: $s8; }
        .colorFour {
            background-color: $s7; } }
    .signatureDetails {
        display: flex;
        flex-direction: column;
        .schedule {
            margin-top: 12px; }
        p,
        a,
        span {
            color: $pBurgundy; }
        p {
            @include base1-s; } }
    .address {
        span {
            display: block; } } }

.input {
    margin-bottom: 8px; }

.portalPreview {
    .cover {
        height: 200px;
        width: calc(100% + 40px);
        background: $pGrey;
        position: relative;
        left: -20px;
        top: -20px;
        object-fit: cover;
        object-position: center;
        @include m {
            width: calc(100% + 32px);
            left: -16px;
            top: -16px; }
        @include s {
            width: calc(100% + 16px);
            left: -8px;
            top: -8px; } }
    .logoWrap {
        position: absolute;
        top: 50px;
        display: flex;
        gap: 8px;
        align-items: flex-end;
        .logoImg {
            height: 100px;
            width: 100px;
            border-radius: 12px;
            border: 1px solid #DADADA;
            object-fit: cover; } }

    .welcomeText {
        @include base1-s;
        color: $n7;
        border-bottom: 1px solid $shades1;
        padding-bottom: 12px;
        margin-top: 16px;
        @include dark {
            color: $pGrey; } }

    .dataGroup {
        margin-top: 25px;
        .label {
            @include base1-s;
            color: $pGrey; }
        .portalCardWrap {
            display: flex;
            align-items: center;
            gap: 12px;
            flex-wrap: wrap;
            .portalCard {
                border: 2px solid $shades1;
                padding: 12px;
                width: 208px;
                display: flex;
                max-height: 75px;
                svg {
                    fill: $pDarkGrey;
                    @include dark {
                        fill: $pGrey; } }
                .userImg {
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center; }
                .right {
                    margin-left: 8px;
                    .cardTitle {
                        color: $n7;
                        @include base1-s;
                        @include dark {
                            color: $pGrey; } }
                    .action {
                        color: $s7;
                        @include caption1;
                        span {
                            margin-right: 4px; }
                        svg {
                            fill: $s7; } }
                    .date {
                        color: $pBurgundy;
                        @include caption1; } } } }

        .featuredCard {
            border: 2px solid $shades1;
            padding: 12px;
            width: 208px;
            height: 128px;
            display: flex;
            .productImg {
                width: 40px;
                height: 40px;
                object-fit: cover;
                object-position: center;
                border-radius: 8px;
                flex: none; }
            .right {
                margin-left: 8px;
                .productName {
                    color: $n7;
                    @include base1-s;
                    @include dark {
                        color: $pGrey; } }
                .productDescription {
                    color: $pDarkGrey;
                    @include caption1;
                    margin-bottom: 4px;
                    word-break: break-all;
                    max-height: 4ch;
                    overflow-y: scroll;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                        display: none; }
                    @include dark {
                        color: $pDarkGrey; } }

                .price {
                    color: $pBurgundy;
                    @include caption1;
                    @include dark {
                        color: $pGrey; } } } } }

    .signatureWrap {
        padding-top: 12px;
        .schedule {
            margin-top: 12px;
            text-decoration: underline; } }

    .title {
        margin-bottom: 10px; }

    .back {
        margin-left: 5px; }

    .status {
        color: $pBurgundy;
        margin-left: 5px; }

    .action {
        color: $s7;
        @include caption1;
        span {
            margin-right: 4px; }
        svg {
            fill: $s7; } }

    .blackClr {
        color: #000;
        @include caption1;
        span {
            margin-right: 5px; } } }
