@import "../../../styles/helpers";

.card {
    width: 50vw; }

.customerHtmlQuestionerWrap {
    margin: 36px auto 48px;
    @media screen and (max-width: 501px) {
        margin: 100px 24px 48px; }
    ul {
        width: 100% !important; }
    [type = "button"] {
        display: block;
        text-align: left;
        background: $pBurgundy;
        color: $pOffWhite; } }
