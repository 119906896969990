@import "../../../../styles/helpers";

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.modalOuter {
    min-width: 540px; }

.cardHeader {
    padding-top: 24px; }

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 260px; }

.spinnerOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7; }

.spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; }

.foot {
    padding: 12px 0;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.dropdown {
    width: 120px; }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    max-width: 340px;
    margin-bottom: 24px;
    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }


.row {
    display: table-row;
    &.active {
        .col {
            &:not(:first-child):not(:nth-child(2)) {
                display: none; }
            &:nth-child(2) {
                &:after {
                    right: 12px; } } } } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px 8px;
    vertical-align: middle;
    @include base1-s;
    color: $pGrey;
    //&:after
    //  //content: ""
    //  //position: absolute
    //  //left: 0
    //  //bottom: 0
    //  //right: 0
    //  //height: 1px
    //  //background: $n3
    //  //+t
    //  //    height: 0
    //  //+dark
    //  //    background: $n6
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; }

        @include t {
            height: 40px; }

        @include m {
            height: 48px; } }

    &:last-child {
        &:after {
            right: 12px; } }

    &:not(:nth-child(1)) {
        @include t {
            display: none; } } }

.checkbox {
    left: 10px;
    @include t {
        left: 12px; } }

.foot {
    margin-top: 20px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }


.btnClass {
    color: $pOrange;
    display: flex;
    justify-content:flex-start {}
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: all .2s;
    &:hover {
        background: #f4f4f4;
        @include dark {
            background: $n6; } }


    .btnIcon {
        margin-right: 16px; } }

.triHead {
    margin-left: 2px;
    position: relative; }

.tri-state-checkbox:focus::before {
    box-shadow: none !important; }

.tri-state-checkbox[aria-checked='mixed']::before,
.tri-state-checkbox[aria-checked='false']::before {
    @include dark {
        background: $pDarkGrey !important; } }

.triCheckbox[type="checkbox"] {
    margin-left: -5px; }

.triCheckbox[type="checkbox"]:before {
    position: absolute;
    background: $n;
    margin-top: -1px;
    margin-left: -1px;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.triCheckbox[type="checkbox"]:after {
    position: absolute;
    content: '';
    margin-top: -1px;
    margin-left: -1px;
    height: 24px;
    width: 24px;
    border: 1px solid $pBurgundy;

    @include dark {
        background: $n6;
        border-color: $shades3 !important; } }

.btnClass {
    color: $pOrange;
    display: flex;
    justify-content:flex-start {}
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: all .2s;
    &:hover {
        background: #f4f4f4;
        @include dark {
            background: $n6; } } }

.triCheckbox[type="checkbox"]:checked:after {
    position: absolute;
    padding: 4px 3px;
    background: $pBurgundy !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") !important; }

.nodata {
    position: absolute;
    padding-top: 20px;
    text-align: center;
    width: 100%; }

.loadMore {
    float: right;
    margin-top: 30px;
    width: 150px;
    height: 50px;
    border: 1px solid gray; }


.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.actionItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;

    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    svg {
        margin-right: 12px;
        fill: $pDarkGrey;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n7;
        background: $n2;
        svg {
            fill: $n7; }
        @include dark {
            color: $pOffWhite;
            background: $n6;
            svg {
                fill: $pOffWhite; } } }
    &.active {
        background: $n2;
        @include dark {
            background: transparent; } }
    &.color {
        color: $pBurgundy;
        svg {
            fill: $pBurgundy; }
        @include dark {
            color: $pBurgundy;
            svg {
                fill: $pBurgundy; } }
        &:hover {
            opacity: .8; } } }

.typesHead {
    cursor: pointer; }

.typesBody {
    position: absolute;
    background: $n;
    z-index: 9;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border: 1px solid;
    span {
        cursor: pointer;
        &:hover {
            color: $pBurgundy; } } }
