@import "../../../styles/helpers";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 18;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.search {
    position: relative;
    flex-shrink: 0;
    width: 310px;
    @include t {
        position: absolute;
        left: 0;
        right: 0;
        top: 80px;
        width: 100%;
        padding: 12px 16px;
        background: $n;
        box-shadow: 0 8px 12px rgba($n8, .05);
        @include dark {
            background: $n8; } }
    &.active {
        .start,
        .result, {
            visibility: hidden;
            opacity: 0; }
        .direction,
        .close {
            visibility: visible;
            opacity: 1; }
        .body {
            visibility: visible;
            opacity: 1; } } }

.head {
    position: relative;
    z-index: 2; }

.start,
.direction,
.result,
.close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    svg {
        transition: fill .2s; } }

.start {
    left: 12px;
    svg {
        fill: $pDarkGrey;
        @include dark {
            fill: $pGrey; } }
    &:hover {
        svg {
            fill: $n7; } } }

.result {
    right: 8px;
    width: 56px;
    height: 32px;
    background: $n;

    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    font-size: 16px;
    font-weight: 600;
    color: $n7;
    @include dark {
        background: $n7;
        box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
        color: $pOffWhite; }
    @include t {
        display: none; }
    &:hover {
        color: $pBurgundy; } }

.direction {
    left: 10px;
    svg {
        fill: $pDarkGrey;
        @include dark {
            fill: $pGrey; } }
    &:hover {
        transform: translate(-2px,-50%); } }

.close {
    right: 10px;
    width: 32px;
    height: 32px;
    svg {
        fill: $pDarkGrey; }
    &:hover {
        svg {
            fill: $pOrange; } } }

.direction,
.close {
    visibility: hidden;
    opacity: 0; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 68px 0 42px;
    border: 2px solid $pGrey;
    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    background: $pLightGrey;
    @include dark {
        background: transparent;
        color: $pOffWhite; }
    &::placeholder {
        color: $pDarkGrey; }
    &:focus {
        background: $white;
        @include dark {
            background: $n8;
            border-color: $pGrey; } } }

.body {
    position: absolute;
    top: -12px;
    left: 0px;
    right: 0px;
    padding: 24px 12px 12px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    scrollbar-width: thin;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    height: max-content;
    max-height: 350px;
    margin-top: 60px;
    overflow-y: auto;
    border: 1px solid $pOrange;
    @include t {
        top: 100%;
        left: 16px;
        right: 16px;
        margin-top: 0px;
        border-radius: 0;
        box-shadow: 0 12px 32px rgba($n8, .05);
        overflow: auto; }
    @include dark {
        background: $n7;
        box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 1), 0px 32px 48px -8px rgba(0, 0, 0, 0.5);
        scrollbar-color: $n7 $n6; } }


.box {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n3;
        @include dark {
            border-color: $pGrey; }
        @include t {
            margin-bottom: 16px;
            padding-bottom: 16px; } } }

.category {
    margin-bottom: 12px;
    padding-left: 12px;
    @include caption1;
    color: $pDarkGrey;
    @include dark {
        color: $pGrey; } }
