@import "../../../../styles/helpers";

.lenderWrapper {
    display: flex;
    flex-direction: column; }

.btnWrap {
    display: flex;
    gap: 8px; }

.header {
    font-size: 32px;
    line-height: 40px;
    color: #464542;
    font-weight: 300;
    margin-bottom: 16px; }

.count {
    color: $pOrange; }

.subTitle {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 45%;
    @include t {
        width: 100%; } }

.spinner {
    display: flex;
    width: 100%;
    justify-content: center; }

.row {
    &.flex {
        display: flex;
        .table {
            flex-shrink: 0;
            width: 384px;
            @include w {
                width: 350px; }
            @include d {
                width: 320px; }
            @include t {
                display: none; } }
        .details {
            display: block; } } }
