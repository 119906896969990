@import "../../../../../styles/helpers";

.closeTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $n5;
    @include dark {
        color: $n3; } }

.closeSubtitle {
    color: #6F767E;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px; }

.deleteBtn {
    width: 139px;
    height: 48px;
    background: $pOffWhite;
    border: 2px solid $pOrange;
    color: $pOrange;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    fill: $pOrange; }

.container {
    display: flex;
    flex-direction: column;
    @include t {
        flex-wrap: wrap; } }
