@import "../../../../styles/helpers";

.card {
    padding: 0; }

.outer {
    max-width: 600px; }

.generateCodeBtn {
    position: absolute;
    top: -63px;
    cursor: pointer; }

.wrap {
    .code {
        background: $n3;
        padding: 8px;

        @include dark {
            background: $n6; }
        p {
            word-break: break-all; } }
    .copyBtnWrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 8px;
        .copyBtn {
            position: relative;
            cursor: pointer;
            fill: $n5;
            &.tooltip {
                &::before {
                    content: "Copied";
                    position: absolute;
                    top: -30px;
                    left: -19px;
                    background: $pBurgundy;
                    color: $n;
                    padding: 2px 4px;

                    transition: all 1s linear; } }
            @include dark {
                fill: $n3; } } }

    .helpWrap {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        background: #B1E5FC50;

        padding: 8px;
        @include dark {
            background: #B1E5FC15; }
        .left {
            color: #3CBBF1;
            fill: #3CBBF1;
            display: flex;
            gap: 8px;
            p {
                flex-shrink: 1;
                @include caption1-m; } }
        .right {
            flex-grow: 1;
            .link {
                color: #3CBBF1;
                fill: #3CBBF1;
                @include base1-s; } } } }

