@import "../../../../../styles/helpers";

.cardOwnerWrap {
    margin-top: 20px; }

.physicalCardTxt {
    color: $pDarkGrey;
    @include caption1-m;
    @include dark {
        color: $shades1; } }

.title {
    @include base1-s;
    color: $pDarkGrey;
    margin-top: 26px; }

.singleOwner {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    border: 2px solid $n3;
    padding: 12px 20px;

    @include dark {
        border-color: $pDarkGrey; }
    span {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; } } }
