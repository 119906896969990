@import "../../../../styles/helpers";

.propertyLink {
    color: var(--primary-orange, #FF4900);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    cursor: pointer; }

.hiddenCard {
    display: none; }

.btnWrap {
    display: flex;
    align-items: center;
    gap: 8px; }

.linkLoadingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px; }

.card {
    width: 350px;
    margin-right: 24px;
    height: max-content;
    @include t {
        margin-right: 0px;
        width: 100%; } }

.cardHead {
    margin-bottom: 12px; }

.companyCard {
    margin-top: 24px; }

.input {
    pointer-events: none; }

.label {
    color: #C7C5BF;
    margin-top: 24px;
    margin-bottom: 8px; }

.highlightedText {
    color: $pOrange;
    margin-top: 24px;
    cursor: pointer; }

.singleDocument {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px; }

.documentContainer {
    margin-top: 24px;
    margin-bottom: 24px; }

.documentName {
    cursor: pointer; }

.buttonContainer {
    display: flex;
    flex-direction: row; }

.checkboxContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    color: #464542;
    margin-top: 12px; }

.buttonContainer {
    margin-top: 24px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap; }

.mainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden; }

.detailContainer {
    width: calc(100% - 374px);
    @include t {
        margin-top: 24px;
        width: 100%; } }

.modalContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center; }

.iconContainer {
    width: 126.5px;
    height: 126.5px;
    border: 4px solid $pGrey;
    border-radius: 50%;
    background-color: $pOrange;
    padding-left: 10px; }

.title {
    color: var(--primary-dark-grey, #464542);
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 125% */;
    letter-spacing: -0.96px;
    margin-top: 16px; }

.modalOuter {
    max-width: 480px; }

.downloadButtonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    gap: 8px; }

.downloadContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center; }

.downloadLink {
    color: var(--primary-dark-grey, #464542);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 160% */;
    letter-spacing: -0.4px; }

.copy {
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer; }

.downloadIcon {
    cursor: pointer; }


