@import "../../styles/helpers";


.infoWrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
    background: #F4F4F4;

    width: 100%;
    @include dark {
        background: $n6; }
    .left {
        position: relative;
        margin-right: 16px;
        .pic {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center; }
        .deleteBtn {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            left: 0;
            top: 0;
            background: $shades2;
            display: none; } }
    &:hover {
        .left {
            .deleteBtn {
                display: block; } } }
    .infoWrap {
        .info {
            display: table-row;
            align-items: center;
            @include caption1-m;
            .label {
                color: $pGrey;
                @include caption1-m;
                padding-right: 12px;
                display: table-cell;
                @include dark {
                    color: $shades1; } }
            .txt {
                display: table-cell;
                color: $n7;
                word-break: break-all;
                @include dark {
                    color: $pDarkGrey; } } } } }
