@import '../../../../styles/helpers';

.nav {
    display: block;
    gap: 12px;

    &.chartShow {
        margin-bottom: 16px; }
    @include m {
        margin-bottom: 12px;
        gap: 8px; } }

.item {
    margin-bottom: 4px;
    border: 2px solid #6F767E;
    border-radius: 0 !important;
    padding: 12px !important;
    &.activeItem0 {
        background: $pLightGrey; }
    &.activeItem1 {
        background: $pApricot; }
    &.activeItem2 {
        background: $pPink; }
    &.activeItem3 {
        background: $pPink; }
    &.activeItem4 {
        background: $pPink; }
    &.activeItem5 {
        background: $pPink; }
    &.activeItem6 {
        background: $pPink; }
    &.activeItem7 {
        background: $pPink; } }


.accountsWrap {
    .txt {
        @include caption1-m;
        color: $n6;
        margin-bottom: 16px;
        @include t {
            margin-top: 24px; } }
    .singleAccount {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 24px;
        .img {
            height: 80px;
            width: 80px;
            object-fit: contain; }
        .right {
            h3 {
                @include title1-s;
                color: $n7; }
            p {
                @include caption1;
                color: $pDarkGrey;
                margin-top: 6px; }
            .action {
                display: flex;
                align-items: center;
                @include base1-s;
                color: $pDarkGrey;
                fill: $pDarkGrey;
                margin-top: 6px;
                gap: 8px;
                .deleteBtn {
                    fill: $pOrange; } } } } }

.addNewBtn {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    @include m {
        top: 16px;
        right: 16px; } }

.linkedDisconnectWrap {
    position: absolute;
    bottom: -174px;
    width: 100%;
    left: 0;
    @media screen and (max-width: 1880px) {
        bottom: -190px; }
    @media screen and (max-width: 915px) {
        bottom: -206px; }
    @media screen and (max-width: 767px) {
        bottom: -190px; }
    @media screen and (max-width: 553px) {
        bottom: -206px; }
    @media screen and (max-width: 474px) {
        bottom: -190px; }
    @media screen and (max-width: 405px) {
        bottom: -206px; }
    @media screen and (max-width: 341px) {
        bottom: -222px; } }
