@import "../../styles/helpers";

.cardHead {
    display: flex;
    justify-content: space-between;
    .pic {
        max-width: 150px;
        object-fit: contain; }
    .headRight {
        svg {
            fill: $pOffWhite;
            margin-left: 8px; } } }

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    pointer-events: none; }

.cardNumber {
    margin-top: 16px;
    span {
        color: $pOffWhite;
        font-size: 24px;
        font-weight: 600; } }

.cardName {
    @include base2;
    color: $pOffWhite; }

.cardFooter {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
    .startDate,
    .endDate {
        @include title1-m;
        color: $pOffWhite; }
    .cardLogo {
        height: 37px;
        width: 73px; } }
