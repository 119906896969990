@import "../../../../styles/helpers";

.profile {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    @include t {
        margin-bottom: 24px; }
    & > .button {
        @include m {
            display: none; } } }

.coverImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center; }

.close {
    position: absolute;
    top: 40px;
    right: 8px;
    svg {
        fill: $shades1;
        background: #F4F4F4; }
    &:hover {
        svg {
            fill: $pOrange; } } }

.title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    color: $pGrey; }

.mainContainer {
    display: flex;
    flexDirection: row;
    @include t {
        display: block; } }

.profileContainer {
    width: 100%;
    @include t {
        width: 100%; } }

.errorMessage {
    color:#AD141E {} }

.errorContainer {
    margin-top: 10px;
    margin-bottom: 10px; }

.spinner {
    display: flex;
    align-items: center !important;
    justify-content: center !important; }

.successMessage {
    color:#008000 {} }

.verified {
    width: 72px;
    height: 24px;
    background: #EAFAE4;
    text-align: center;
    align-items: center;
    color: $pBurgundy; }

.notVerified {
    width: 92px;
    height: 24px;
    background: #FFBC9925;
    text-align: center;
    align-items: center;
    color: red; }

.coverClose {
    position: absolute;
    top: 10px;
    right: 10px;
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }

.selectedImageWrapProfile {
    margin-top: 8px;
    position: relative;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    @include m {
        width: 100px;
        height: 100px; }

    .selectedImage {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
        @include m {
            width: 100px;
            height: 100px; } }
    .action {
        position: absolute;
        top: 0;
        right: 0;
        background: $pOffWhite;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.selectedImageWrap {
    margin-top: 8px;
    position: relative;
    margin-bottom: 20px;
    width: 164px;
    height: 164px;
    @include m {
        width: 164px;
        height: 164px; }

    .selectedImage {
        width: 164px;
        height: 164px;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
        @include m {
            width: 164px;
            height: 164px; } }
    .action {
        position: absolute;
        bottom: 16px;
        padding: 8px;
        background: $n;
        left: 50%;
        transform: translateX(-50%);

        fill: $pDarkGrey;
        @include dark {
            background: $n5; }
        button {
            &:not(:last-child) {
                margin-right: 8px; } } } }

.inputLabel {
    border-radius: 8px;
    border: .5px solid $pDarkGrey;
    width: 100px;
    height: 100px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center; }

.inputLabelCover {
    border: .5px solid $pDarkGrey;
    width: 100%;
    height: 200px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center; }

.inputLabelImg {
    display: none; }

.imgInput {
    margin-top: 8px;
    .input {
        display: none; } }

.imgInputCover {
    .input {
        display: none; } }


.btnContainer {
    height: 80px; }

.remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    font-size: 0;
    @include m {
        display: inline-block; } }

.logoTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #6F767E; }

.actionCard {
    margin-top: 24px;
    display: flex;
    padding: 0px !important;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0px;
    right: 20px;
    @include t {
        position: relative;
        right: unset;
        top: unset; }
    @include s {
        .button {
            padding: 12px;
            font-size: 14px; } }
    @include xs {
        gap: 4px;
        .button {
            padding: 10px; } } }


.notification {
    margin-left: 100px;
    @include t {
        margin-top: 50px;
        margin-left: 0px; } }

.update {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    gap: 4px;
    width: 69px;
    height: 24px;
    background: #6F767E;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $pOffWhite;
    margin-left: 14px;
    cursor: pointer; }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 82px;
    height: 82px;
    margin-right: 32px;
    @include m {
        width: 80px;
        height: 80px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5%; } }

.remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    font-size: 0;
    @include m {
        display: inline-block; } }

.file {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    overflow: hidden;
    @include m {
        margin-right: 0; }
    input {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 160px;
        opacity: 0; }
    .button {
        @include m {
            padding: 0 10px; } } }

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.verifiedBox {
    display: flex;
    margin-top: 14px;
    flex-direction: row;
    @include t {
        flex-wrap: wrap; }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.card {
    padding: 0; }

.cardHead {
    position: relative; }

.billingToggle {
    position: absolute;
    margin-left: 220px;
    margin-top: 2px;
    cursor: pointer;
    @include dark {
        fill: $n; } }

.buttonWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
    margin-bottom: 36px; }

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @include t {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px 16px;
        @include t {
            width: 100%;
            margin: 0;
            margin-bottom: 24px; } } }

.fieldLabel {
    @include base2;
    color: $pGrey;
    margin-bottom: 5px;
    @include dark {
        color: $n3; } }

.checkStatus {
    display: flex;
    margin-bottom: 5px;
    span {
        @include base1-s;
        color: $pDarkGrey;
        margin-left: 8px; } }

.checkbox {
    margin-left: -16px; }

.mobileField {
    display: flex;
    .inputNo {
        flex-grow: 1;
        margin: 0 0 0 8px; } }

.hasError {
    input {
        background: #FFBC9925 !important;
        color: $pOrange;
        @include base1-s;
        &:focus {
            border-color: #FFBC9925; }
        @include dark {
            color: $pOrange;
            &:focus {
                border-color: $pOrange; } } } }

.dropdown {
    z-index: 9;
    background-color: #F4F4F4;
    border: 2px solid #C7C5BF;
    height: 48px; }

.dropdownHead {
    box-shadow: unset;
    &:hover {
        box-shadow: unset; } }

.dropzoneWrap {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    padding: 8px;
    background-color: $n2;
    margin-bottom: 20px;
    width: 164px;
    height: 165px;
    @include t {
        width: 100%; }
    @include dark {
        background-color: $n5; } }

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 2px dashed $pDarkGrey;
    color: #bdbdbd;
    cursor: pointer;
    fill: $pDarkGrey;
    width: 100%;
    @include dark {
        border-color: $pDarkGrey; }
    span {
        @include base1-s;
        margin-left: 8px;
        color: $pDarkGrey; } }



.dateInput {
    input {
        border: 2px solid #C7C5BF;
        cursor: pointer;
        color: $n5; } }

.inputDate {
    width: calc(100% - 10px) !important;
    cursor: pointer;
    color: $pDarkGrey !important; }

.dropdownHead {
    box-shadow: unset;
    &:hover {
        box-shadow: unset; } }

.classOptionText {
    color: $n7;
    @include dark {
        color: $n3; } }
