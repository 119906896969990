@import "../../../styles/helpers";

.card {
    padding: 12px; }

.icon {
    content: "";
    width: 48px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto;
    transition: transform .2s;
    cursor: pointer; }

.revertIcon {
    transform: rotate(180deg); }

.balanceWrap {
    margin-bottom: 20px;
    p {
        @include base1-s;
        color: $pDarkGrey; }
    .amount {
        font-size: 40px;
        font-weight: 600;
        color: $n6;
        @include dark {
            color: $pDarkGrey; } } }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 8px;
            fill: $pDarkGrey; }
        @include dark {
            svg {
                fill: $pDarkGrey; } }
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            svg {
                fill: $n7; }
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $shades1;
                svg {
                    fill: $shades1; } } } } }

.singleInfo {
    margin-top: 20px;
    border-bottom: 1px solid #11131535;
    padding-bottom: 8px;
    @include dark {
        border-bottom: 1px solid $n6; }
    .title {
        @include base1-s;
        color: $pDarkGrey; }
    .text {
        @include base1-s;
        color: $n6;
        @include dark {
            color: $shades1; } } }

.singleInfoBalance {
    margin-top: 20px;
    padding-bottom: 8px;
    @include dark {
        border-bottom: 1px solid $n6; }
    .title {
        @include base1-s;
        color: $pDarkGrey; }
    .text {
        @include base1-s;
        font-size: 22px;
        color: $n6;
        @include dark {
            color: $shades1; } } }
