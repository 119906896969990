@import "../../styles/helpers";

.card {
    width: 343px;
    height: 280px; }

.cardFirst {
    width: 343px;
    height: 280px;
    padding-left: 16px !important;
    padding-right: 16px !important; }

.cardWithoutHeader {
    width: 348px;
    height: 462.5px;
    position: absolute !important;
    top: calc(50% - 231px); }

.headerText {
    font-style: normal;
    font-weight: 300;
    color: $pDarkGrey;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em; }

.themeWrap {
    position: absolute;
    bottom: 24px;
    left: 24px;
    @include t {
        display: none; }
    .link {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        width: 100%;
        height: 36px;
        font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
        color: $pDarkGrey;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        transition: background .2s, box-shadow .2s, color .2s;
        @include m {
            width: 100%;
            font-size: 13px; }
        svg {
            margin-right: 4px;
            fill: $pDarkGrey;
            transition: fill .2s; }
        &:hover,
        &.active {
            color: $pOrange;
            svg {
                fill: $pOrange; }
            @include dark {
                color: $pOffWhite;
                svg {
                    fill: $pOffWhite; } } } } }

.theme {
    span {} }

.logo {
    display: inline-block;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    width: 224px;
    img {
        width: 100%; }
    @include t {
        width: 150px; } }

.cardContainer {
    display: flex;
    flex-direct-row {}
    gap: 24px;
    margin-bottom: 36px; }

.verifyPage {
    padding: 96px 0 0 240px;
    @include x {
        padding: 80px 0 0 220px; }
    @include t {
        overflow: hidden; }
    @include t {
        padding-left: 0; } }

.unVerifyPage {
    padding: 176px 0 0 340px;
    @include x {
        padding: 160px 0 0 300px; }
    @include t {
        overflow: hidden; }
    @include m {
        padding-left: 0; } }

.sidebar {
    @include t {
        transform: translateX(-100%);
        transition: transform .3s;
        z-index: 9999;
        &.visible {
            transform: translateX(0); } } }

.inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    padding: 32px 32px 32px;
    @include x {
        padding: 24px 24px 32px; }
    @include m {
        padding: 16px; }
    @include s {
        padding: 8px; } }

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    &.wide {
        max-width: 100%; } }

.title {
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px; }

.imgWrap {
    width: 128px;
    height: 128px;
    background: $pBurgundy;
    padding: 14px;
    border-radius: 50%;
    .pic {
        width: 100px;
        height: 100px; } }

.title {
    @include title1-s;
    color: $n5;
    @include dark {
        color: $pDarkGrey; } }

.invName {
    font-size: 34px;
    font-weight: 600;
    color: $n7;
    line-height: 48px;
    @include dark {
        color: $n3; } }

.email {
    @include base1-s;
    font-weight: 600;
    text-align: center;
    color: $pGrey;
    span {
        color: $n5;
        @include dark {
            color: $n3; } } }
