@import "../../../styles/helpers";

.carouselWrapper {
    //padding: 8px
    padding-right: 8px;
    margin-bottom: 32px;
    //background-color: $n2
    //+dark
 }    //    background-color: $n6

.nav {
    //display: block
    display: flex;
    //justify-content: center
    gap: 12px;
    padding: 8px;
    background: $n2;

    &.chartShow {
        margin-bottom: 32px; }
    @include s {
        flex-direction: column; }
    @include m {
        margin-bottom: 24px;
        gap: 8px; }
    @include dark {
        background: $shades4; } }

.item {
    margin-bottom: 4px;
    border: 2px solid #6F767E;
    border-radius: 0 !important;
    padding: 12px !important;
    &.active {
        // background: $pOffWhite
        // box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        //+dark
        //    background: $n7
 }        //    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
    &.activeItem0 {
        background: $pOrange;
        color: #FFFDF9 !important; }
    &.activeItem1 {
        background: $pLightGrey; }
    &.activeItem2 {
        background: $pApricot; }
    &.activeItem3 {
        background: $pPink; }
    &.activeItem4 {
        background: $pPink; }
    &.activeItem5 {
        background: $pPink; }
    &.activeItem6 {
        background: $pPink; }
    &.activeItem7 {
        background: $pPink; }
    &.activeItem8 {
        background: $pPink; } }

.transactionDropdown {
    display: block;
    @include t {
        display: block; } }

.transactionToggle {
    display: flex;
    align-items: center;
    gap: 6px;
    fill: $pDarkGrey;
    .toggleButton {
        @include base1-s;
        color: $pDarkGrey;
        padding: 8px 5px;

        &.active,
        &:hover {
            background: $n3;
            color: $n7;
            @include dark {
                background-color: $n5;
                color: $shades1; } }
        @include t {
            display: none; } } }

.calendarControl {
    background: $n3;
    padding: 8px 5px;
    color: $n7;
    cursor: pointer;

    @include t {
        margin-left: 12px; }
    @include dark {
        background: $n6; } }

.calendarWrap {
    position: absolute;
    right: 24px;
    top: 74px;
    @include m {
        top: 62px; } }

.input {
    width: 100% !important;
    text-align: center;
    cursor: pointer;
    color: $pDarkGrey !important; }

.mobileNav {
    display: none;
    width: 40%;
    @include t {
        display: block; } }


.typeNav {
    display: flex;
    align-items: center;
    width: max-content;
    margin-left: 0px;
    margin-bottom: 24px;
    @include dark {
        background: $n6; }
    .dateItem {
        position: relative;
        @include base1-s;
        color: $pDarkGrey;
        cursor: pointer;
        width: 100%;
        text-align: center;
        &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            &::after {
                position: absolute;
                right: 0;
                height: 100%;
                width: 1px;
                background: #11131550;
                content: ""; } }
        &.active {
            @include button1;
            color: $pBurgundy;
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }

