@import "../../../../styles/helpers";

.card {
    padding: 12px; }


.row {
    width: 100%;
    display: flex;
    gap: 12px;
    @include t {
        display: block;
        margin: 0; }
    .col {
        flex: 0 0 calc(33.33% - 8px); }

    .col8 {
        flex: 0 0 calc(67.67% - 8px);
        .row {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            @include t {
                display: block; }
            .col6 {
                flex: 0 0 calc(50% - 8px);
                @include t {
                    margin: 16px 0; } }
            .col12 {
                flex: 0 0 100%; } } }

    .col12 {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 8px; }
    &:not(:last-child) {
        margin-bottom: 16px;
        @include t {
            margin-bottom: 24px; } } }

.input {
    margin-bottom: 16px; }

.defaultCard {
    background-color: $n6;
    padding: 16px;

    max-width: 320px; }

.appleWallet {
    .pic {
        max-width: 100px; } }

.field {
    &:not(:first-child) {
        margin-top: 16px; } }

.cardOwner {
    .label {
        @include base2;
        color: $pGrey;
        @include dark {
            color: $n3; } }
    .ownerName {
        @include base1-s;
        background: $n2;

        line-height: 48px;
        margin-top: 12px;
        padding: 0 16px;
        color: $n6;
        @include dark {
            background: $n6;
            color: $shades1; } } }

.colorWrap {
    .label {
        @include base2;
        color: $pGrey;
        @include dark {
            color: $n3; } }
    .singleColor {
        margin-top: 12px;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 16px; } } }

.actionBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 -8px; }

.gray {
    background: #272B30; }

.blue {
    background: #0063F3; }

.purple {
    background: #CABDFF; }

.green {
    background: #B5E4CA; }

.red {
    background: #FFBC99; }
