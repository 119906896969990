@import "../../../styles/helpers";

.info {
    margin-bottom: 20px;
    @include body2-s; }

.fieldset {
    display: flex;
    margin: 0 -6px;
    div {
        flex: 0 0 calc(25% - 12px);
        width: calc(25% - 12px);
        margin: 0 6px;
        &.error {
            .input {
                background: rgba($s1, .25);
                border-color: transparent;
                color: $pOrange; } } } }

.field {
    flex: 0 0 calc(25% - 12px);
    width: calc(25% - 12px);
    margin: 0 6px;
    &.error {
        .input {
            background: rgba($s1, .25);
            border-color: transparent;
            color: $pOrange; } } }

.input {
    width: 100%;
    height: 72px;
    padding: 0 6px;

    border: 2px solid transparent;
    background: $n3;
    text-align: center;
    @include tomatoGrotesk;
    font-size: 48px;
    font-weight: 600;
    color: $n7;
    transition: all .2s;
    &:focus {
        border-color: $shades1;
        background: $n; }
    @include dark {
        background: $n6;
        color: $pOffWhite;
        &:focus {
            background: transparent; } } }

.errorNote {
    display: none;
    margin-top: 12px;
    @include caption2;
    font-weight: 500;
    color: $pOrange; }

.button {
    width: 100%;
    margin-top: 12px;
    .loader {
        display: none;
        margin-right: 16px; } }

.note {
    margin-top: 32px;
    @include body2-s;
    color: $shades1; }

.resendWrap {
    display: flex;
    margin-top: 12px;
    @include base1-s;
    p {
        margin-right: 12px; }
    button {
        @include base1-s;
        color: $pOrange; } }
