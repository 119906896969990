@import "../../styles/helpers";

.dropdown {
    margin-bottom: 16px;
    min-width: 200px; }

.noOverflow {
    overflow: hidden; }

.cardTitle {
    padding-top: 4px; }

.singlePageShow {
    border: none !important;
    box-shadow: none !important;
    background: transparent;
    padding: 0; }

.card {
    margin-bottom: 24px; }

.mobileNav {
    display: none;
    width: 40%;
    margin-bottom: 24px;
    @include t {
        display: block; } }

.backBtn {
    font-size: 15px;
    cursor: pointer;
    svg {
        margin-right: 4px; }
    @include dark {
        color: $pDarkGrey;
        fill: $pDarkGrey; } }

.hideBg {
    background: transparent !important; }

.typeNav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
    &.onlyLarge {
        @include t {
            display: flex; } }
    .item {
        position: relative;
        @include base1-s;
        color: $pGrey;
        cursor: pointer;
        text-align: center;
        &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            &::after {
                position: absolute;
                right: 0;
                height: 100%;
                width: 1px;
                background: #11131550;
                content: "";
                @include dark {
                    background: $pDarkGrey; } } }
        &.active {
            @include button1;
            color: $pOrange;
            @include dark {
                color: $pOffWhite;
                box-shadow: none; } } } }
