@import "../../../../../../styles/helpers";


.row {
    display: table-row;
    @include t {
        display: flex;
        flex-direction: column;
        padding: 16px 0; }
    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } } }

.col {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    &:nth-child(2) {
        padding-left: 0; }
    &:last-child {
        padding-right: 0;
        .head {
            text-align: end; }
        @include t {
            position: absolute;
            top: 8px;
            right: 0; } }
    &:not(:last-child) {
        margin-bottom: 4px; }
    @include t {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: space-between; }
    span {
        &:first-child {
            display: none;
            @include t {
                display: block; } } } }

.editOverlay {
    //background: #ff00003d
    position: absolute;
    width: 100%;
    height: 44px;
    @include t {
        height: 136px; } }

.country {
    .pic {
        width: 25px; } }

.editBtn {
    @include dark {
        fill: $n; } }

.verified {
    padding: 0 8px;
    @include base1-s;
    color: $pBurgundy;
    background: #B5E4CA;

    margin-left: 5px; }

.draft {}
.new {
    background: $n3;
    padding: 0 8px;
    @include base1-s;
    color: $pDarkGrey;

    margin-left: 5px; }
