@import '../../../styles/helpers.sass';

.emptyCard {
    min-height: 200px; }

.moneyIn {
    position: relative;
    .totalAmount {
        @include base1-b;
        color: $n7;
        font-size: 24px;
        border-bottom: 1px solid #111315;
        padding-bottom: 16px; }

    .invWrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        li {
            display: flex;
            justify-content: space-between;
            .left {
                h3 {
                    @include title1-m; }
                p {
                    @include caption1;
                    font-weight: 300;
                    color: $pDarkGrey;
                    @include dark {
                        color: $pGrey; } } }
            .right {
                text-align: right;
                h3 {
                    @include title1-m; }
                p {
                    @include caption1;
                    font-weight: 300;
                    color: $pDarkGrey;
                    @include dark {
                        color: $pGrey; } } } } } }

.cardHead {
    a {
        @include dark {
            fill: $n; } } }
