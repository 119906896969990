.h1,
.h2,
.h3,
.h4 {
    @include tomatoGrotesk;
    font-weight: 300; }

.h1 {
    font-size: 64px;
    line-height: 1;
    letter-spacing: -.03em; }

.h2 {
    font-size: 48px;
    line-height: 1;
    letter-spacing: -.03em; }

.h3 {
    font-size: 40px;
    line-height: (48/40);
    letter-spacing: -.02em;
    @include m {
        font-size: 32px;
        line-height: (40/32);
        letter-spacing: -.03em; } }

.h4 {
    font-size: 32px;
    line-height: (40/32);
    letter-spacing: -.03em; }

