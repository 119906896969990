@import "../../styles/helpers";

.detailsTxt {
    @include base1-s;
    color: $pDarkGrey;
    margin-bottom: 12px; }

.search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    max-width: 310px;
    &.active {
        .start,
        .result, {
            visibility: hidden;
            opacity: 0; }
        .direction,
        .close {
            visibility: visible;
            opacity: 1; }
        .body {
            visibility: visible;
            opacity: 1; } }
    @include t {
        max-width: unset; } }

.head {
    position: relative;
    z-index: 2; }

.start,
.direction,
.result,
.close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    svg {
        transition: fill .2s; } }

.start {
    left: 12px;
    svg {
        fill: $pDarkGrey; }
    &:hover {
        svg {
            fill: $n7; } } }

.result {
    right: 8px;
    width: 56px;
    height: 32px;
    background: $n;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    font-size: 16px;
    font-weight: 600;
    color: $n7;
    @include dark {
        background: $n7;
        box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
        color: $pOffWhite; }
    @include m {
        display: none; }
    &:hover {
        color: $pBurgundy; } }

.direction {
    left: 10px;
    svg {
        fill: $pDarkGrey; }
    &:hover {
        transform: translate(-2px,-50%); } }

.close {
    right: 10px;
    width: 32px;
    height: 32px;
    svg {
        fill: $pDarkGrey; }
    &:hover {
        svg {
            fill: $pOrange; } } }

.email {
    @include caption1-m;
    color: $pDarkGrey;
    margin-top: 8px; }

.direction, {
    visibility: hidden;
    opacity: 0; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 68px 0 42px;
    border: 2px solid $pGrey;
    @include base1-s;
    color: $pDarkGrey;
    transition: all .2s;
    background: $pLightGrey;
    @include dark {
        background: $n6;
        color: $pOffWhite; }
    &::placeholder {
        color: $pDarkGrey; }
    &:focus {
        background: $white;
        @include dark {
            background: $n8; } } }

.body {
    position: absolute;
    z-index: 9;
    top: 110%;
    left: -12px;
    right: -12px;
    padding: 12px;
    border: 1px solid $pOrange;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    scrollbar-width: thin;
    @include m {
        top: calc(100% + 12px);
        max-height: calc(100vh - 152px);
        padding: 16px 8px;
        box-shadow: 0 12px 32px rgba($n8, .05);
        overflow: auto; }
    @include dark {
        background: $n7;
        border-color: $n6; } }

.addCustomerBtn {
    width: 100%;
    height: 48px;
    padding: 0 8px;
    background: $n2;
    border: 2px solid transparent;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    @include dark {
        background: $n6;
        color: $pOffWhite;
        fill: $pOffWhite; }
    &::placeholder {
        color: $shades1; } }

.customerBtn {
    @include base1-s;
    color: $n6;
    width: 100%;
    height: 48px;
    padding: 0 8px;
    text-align: start;
    scrollbar-width: thin;
    &:hover {
        background: $n2; }
    @include dark {
        color: $pDarkGrey;
        &:hover {
            background: $n6; } } }
