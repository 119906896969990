@import "../../../styles/helpers";

.tableInputRowContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding: 12px;
    padding-left: 0px; }

.inputContainer {
    width: 9%; }

.plusIcon {
    width: 4%;
    cursor: pointer; }
