@import '../../styles/helpers.sass';

.card {
    border-top: 0 !important;
    border-radius: 0 0 20px 20px !important;
    padding-top: 0 !important;
    margin-top: -24px !important; }

.accCard {
    border-bottom: 0 !important;
    border-radius: 20px 20px 0 0 !important;
    padding-bottom: 0 !important; }

.accounting {
    position: relative; }

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 24px; }


.dropdown {
    margin-bottom: 16px;
    min-width: 200px; }

.outer {
    max-width: 845px;
    height: 100%;
    overflow-y: scroll; }

.mobileNav {
    display: none;
    width: 150px;
    @include t {
        display: block; }
    &.subNav {
        display: block; } }

.actionDropdown {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
    color: #FFFDF9;
    transition: all .2s;
    .actionDropdownHead {
        font-weight: unset;
        box-shadow: unset !important;
        background-color: $pOrange !important;
        border: none;
        color: #FFF;
        border-radius: 3px;
        &:before {
            background: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z%27 fill='white'/%3E%3C/svg%3E") no-repeat 50% 50%/24px auto; } } }

.typeNav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
    @include t {
        display: flex; }
    .item {
        position: relative;
        @include base1-s;
        color: $pGrey;
        cursor: pointer;
        text-align: center;
        &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            &::after {
                position: absolute;
                right: 0;
                height: 100%;
                width: 1px;
                background: #11131550;
                content: "";
                @include dark {
                    background: $pDarkGrey; } } }
        &.active {
            @include button1;
            color: $pOrange;
            @include dark {
                color: $pOffWhite;
                box-shadow: none; } } } }
