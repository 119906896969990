@import "../../../styles/helpers";

.tableRowContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding: 12px; }

.columnName {
    width: 9%;
    overflow: hidden;
    text-overflow: ellipsis; }

.crossIcon {
    width: 4%;
    cursor: pointer; }

.line {
    height: 1px;
    background-color: $pGrey;
    width: 100%;
    margin-top: 16px; }
