@import "../../../../styles/helpers";

.headingTitle {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 40px;
    color: #464542;
    font-weight: 300; }

.color {
    color: $pOrange; }

.title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    color: $pGrey; }

.switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center; }

.iconContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap; }

.singleIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 140px; }

.icon {
    cursor: pointer;
    text-align: center;
    padding-top: 13px;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-color: #E9E7E0; }

.inputContainer {
    margin-top: 12px; }

.dateInput {
    input {
        border: 2px solid #C7C5BF;
        cursor: pointer;
        color: $n5; } }

.inputDate {
    width: calc(100% - 10px) !important;
    cursor: pointer;
    color: $pDarkGrey !important; }

.firstRow {
    display: flex;
    flex-direction: row;
    gap: 12px; }

.input {
    width: 33.33%; }

.inputWithBottom {
    width: 33.33%;
    input {
        border: unset;
        background: unset;
        border-bottom: 2px solid #C7C5BF; } }

.inputDynamic {
    width: 32.55%; }

.mapContainer {
    display: flex;
    flex-direction: row;
    gap: 17px; }

.mapInputFields {
    display: flex;
    flex-direction: row; }

.inputMap {
    width: calc(33.33% - 10px);
    margin-top: 24px; }

.map {
    width: 100%;
    margin-top: 24px; }

.emptyMapText {
    color: $pGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 413px; }

.emptyDetailsMapText {
    color: $pGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; }

.disable {
    pointer-events: none; }

.close {
    position: absolute;
    top: 40px;
    right: 8px;
    svg {
        fill: $shades1;
        background: #E9E7E0; }
    &:hover {
        svg {
            fill: $pOrange; } } }

.classCardHead {
    margin-top: -24px; }

.dynamicFields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 12px; }

.propertyAddButton {
    margin-top: 24px; }

.xMap {
    margin-top: 14px;
    height: 413px;
    border: 2px solid gray;
    border-radius: 4px; }

.xDetailsMap {
    margin-top: 14px;
    height: 200px;
    border: 2px solid gray;
    border-radius: 4px; }

.imageWrapper {
    margin-top: 24px; }

.addFieldButton {
    margin-top: 24px; }

.imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
    align-items: center; }

.img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer; }

.previewImage {
    width: 100%;
    object-fit: cover; }

.outerModalPreview {
    max-width: 600px; }

.inputLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    border: .5px solid $pDarkGrey;
    width: 200px;
    height: 200px;
    background: #E9E7E0;
    cursor: pointer; }

.inputImage {
    display: none; }

.dealDescription {
    margin-top: 12px; }

.seperator {
    height: 2px;
    background-color: $pGrey;
    margin-top: 32px;
    margin-bottom: 32px; }

.header {
    font-size: 32px;
    line-height: 40px;
    color: #464542;
    font-weight: 300;
    margin-bottom: 16px; }

.loanInputContainer {
    margin-bottom: 32px; }

.margin {
    margin-top: 16px; }

.marginIconContainer {
    margin-top: 36px; }

.roiContainer {
    margin-top: 32px; }

.dropzoneWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    padding: 8px;
    background-color: $n2;
    margin-bottom: 20px;
    width: 40%;
    @include t {
        width: 100%; }
    @include dark {
        background-color: $n5; } }

.action {
    position: absolute;
    top: 0;
    right: 0;
    background: $pOffWhite;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }

.btnWrap {
    display: flex;
    gap: 8px; }

.dropzone {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 2px dashed $pDarkGrey;
  color: #bdbdbd;
  cursor: pointer;
  fill: $pDarkGrey;
  @include dark {
    border-color: $pDarkGrey; }
  span {
    @include base1-s;
    margin-left: 8px;
    color: $pDarkGrey; } }

.dropImgDelete {
    fill: $pOrange; }

.dropdown {
    width: 100%;
    background-color: #F4F4F4;
    height: 48px; }

.dropdownHead {
    box-shadow: unset;
    &:hover {
        box-shadow: unset; } }

.crossIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    text-align: center; }

.customerLabel {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: $pGrey; }

.documentNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px; }

.documentOuterWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px; }


.customerWrap {
    width: 340px; }

