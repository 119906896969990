@import "../../../styles/helpers";


.body {
    .button {
        width: 100%;
        border: none; }
    .disable {
        width: 100%;
        border: none;
        background: #4464E280 !important; } }


.dropdown {
    margin-bottom: 10px; }

.emailWrapper {
    position: relative; }

.hasError {
    margin-bottom: 12px;
    input {
        background: #FFBC9925 !important;
        color: $pOrange;
        padding-right: 36px;
        @include base1-s;
        &:focus {
            border-color: #FFBC9925; }
        @include dark {
            color: $pOrange;
            &:focus {
                border-color: $pOrange; } } } }

.field {
    margin-bottom: 12px;
    input {
        padding-right: 36px; } }


.warningIcon {
    position: absolute;
    fill: $pOrange;
    right: 8px;
    top: 0;
    background: transparent;
    height: 48px; }

.validIcon {
    position: absolute;
    fill: $pBurgundy;
    right: 8px;
    top: 0;
    background: transparent;
    height: 48px; }

.terms {
    margin-top: 32px;
    @include body2-s;
    color: $shades1;
    display: flex;
    align-items: flex-start;
    .termsIcon {
        flex: none;
        fill: $pOrange; }
    .termsText {
        margin-left: 4px;
        @include caption1;
        .termsLink {
            margin: 0 0 0 4px;
            color: $n7 !important;
            @include dark {
                color: $pDarkGrey !important; } } } }
