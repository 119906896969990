@import '../../../../styles/helpers.sass';

.item {
    display: flex;
    padding: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: all .2s;
    @include w {
        padding: 20px 16px; }
    @include m {
        display: block;
        padding: 16px 10px; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
    @include m {
        display: none; } }

.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include title1-s;
    color: #464542; }

.subtitleX {
    margin-bottom: 4px;
    font-size: 16px !important;
    @include title1-s;
    color: #464542; }

.inactiveCard {
    .details {
        .subtitle,
        .subtitleX,
        .counter {
            @include dark {
                color: $pOffWhite; } } } }

.tooltip {
    svg {
        fill: $shades1; } }

.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.counter {
    font-size: 32px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.03em;
    color: #464542;
    @include w {
        font-size: 28px;
        line-height: 48px; } }

.balance {
    margin: -12px -12px 0 auto;
    @include m {
        margin: 4px 0 0; }
    @include dark {
        background: $n6 !important; } }
