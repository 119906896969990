@import "../../../styles/helpers";

.card {
    padding: 0; }

.synced {
    background: $pBurgundy !important;
    pointer-events: none; }

.unlink {
    color: red !important;
    box-shadow: none !important;
    padding: 12px 0 12px 16px; }

.dropdown {
    width: 150px; }

.addNewBtn {
    position: absolute;
    display: flex;
    right: 24px;
    top: 24px;
    button {
        &:first-child {
            margin-right: 8px; } }
    @include m {
        top: 16px;
        right: 16px; } }

.foot {
    padding: 12px 0;
    text-align: center;
    float: right;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.emailWrap {
    .wrap {
        display: table;
        width: 100%;
        .emailHead {
            display: table-row;
            width: 100%;
            padding: 12px;
            @include base1-s;
            color: $pGrey;
            gap: 8px;
            p {
                display: table-cell;
                padding: 12px;
                &:last-child {
                    width: 48px; } } } } }

.typeNav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #F4F4F4;
    padding: 4px 8px;

    margin-left: 0px;
    max-width: max-content;
    margin-bottom: 24px;
    @include dark {
        background: $n6; }
    .item {
        @include base1-s;
        color: $pDarkGrey;
        padding: 4px 16px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        min-width: max-content;
        &.active {
            @include button1;
            background: $pOffWhite;

            color: $n7;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            @include dark {
                background: $n5;
                box-shadow: none;
                color: $pDarkGrey; } } } }


.spinner {
    display: flex;
    justify-content: center;
    align-items: flex-end; }

.infoWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #F4F4F4;
    width: max-content;
    @include dark {
        background: $n6; }
    .pic {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-right: 16px; }

    .infoWrap {
        .info {
            display: table-row;
            align-items: center;
            @include base1-s;
            .label {
                color: $pGrey;
                padding-right: 12px;
                display: table-cell;
                @include dark {
                    color: $shades1; } }
            .txt {
                display: table-cell;
                color: $n7;
                @include dark {
                    color: $pDarkGrey; } } } } }

.foot {
    padding: 12px 0;
    text-align: center;
    float: right;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.table {
    display: table;
    width: 100%;
    margin-top: 24px;
    @include t {
        display: block; }
    .head,
    .row {
        position: relative;
        display: table-row;
        cursor: pointer;
        height: 48px;
        @include t {
            display: block;
            margin-bottom: 16px; }
        .col {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            padding: 8px 12px;
            @include t {
                display: table-row;
                padding: 4px 8px; }
            .mblLabel {
                display: none;
                @include t {
                    display: table-cell;
                    color: $pDarkGrey;
                    padding: 0 8px; } }
            span {
                @include t {
                    display: table-cell;
                    padding: 0 8px; } } } }



    .head {
        color: $pDarkGrey;
        font-size: 14px;
        @include t {
            display: none; } }
    .row {
        @include base1-s;
        color: $n7;
        @include dark {
            color: $pDarkGrey; }
        &:hover {
            background: $n3;
            cursor: pointer;
            @include dark {
                background: $n6; } } } }
